import React, { useState, useEffect } from "react";
import { Navigate , NavLink,Link,useNavigate} from 'react-router-dom'
import { AuthContext } from '../../context';




function Logout() {
const  { signOut } = React.useContext(AuthContext);
const navigate = useNavigate(); 


useEffect(() => {

signOut()
navigate('/')
});

      



  return (
    <div> </div>
  )
}

export default Logout