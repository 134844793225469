import React, { useState, useEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom'
import '../Dashboard.css'; 
import '../../../App.css'; 
import { Modal}            from 'react-bootstrap';
import { FaRegCreditCard, FaRegEyeSlash,FaRegEye } from "react-icons/fa"; 
import { FaRegCopy } from "react-icons/fa";
import { RxSwitch } from "react-icons/rx";
import { RiMessage3Fill } from "react-icons/ri"; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { AiOutlineReload } from "react-icons/ai";
import { MdTipsAndUpdates } from "react-icons/md";
import { AbuseEmail,Naira,BasePageAction,MainColor,BaseAction } from '../../../BaseUrl';
import Loading1          from "../../Loading";
import Loading2          from "../../Loading2";
import md5               from 'md5-hash';
import {FloatingLabel,Card, Table, Form,Button} from 'react-bootstrap';
import { AuthContext } from '../../context';
 
 

const Transfer = (props) => {  
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false);
const[loginData, setLoginData] = useState([]); 
const[fundTransfer, setFundTransfer] =   useState(false);
const[transactionMessageSwitch, setTransactionMessageSwitch] = useState(false);
const[switchBalance, setSwitchBalance] = useState(true); 
const[messageSwitch, setMessageSwitch] = useState(false);
const[message, setMessage] = useState(''); 

  const navigate = useNavigate();  
  const [authUser, setAuthUser] = useState('');   
  const { reloadData ,reloadUserData} = React.useContext(AuthContext);
  const [cashoutAmt, setcashoutAmt] = useState('');
  const [requestLoan, setRequestLoan] = useState(false); 
  const [virtualCardPin, setVirtualCardPin] = useState(''); 
  const [otp, setOtp] = useState('');
  const [show, setShow] = useState(false);
  const [receiverCardNo, setReceiverCardNo] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const[data, setData] = useState({
    newCardPin: '',
    confirmCardPin: '',
    otpCode: '',
    adminFee: '',
    cashoutAmt: '',  
    receiverCardNo: '',  
    virtualCardPin: '',  
    coin: '',  
 });

 
  const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
 
  }
  
  useEffect(() => { 
  setTimeout(async() => { 
        LoginOptionUpdate();   
  }, 5000);
  
  
                    
  });
  
  useEffect(() => { 
    LoginOptionUpdate(); 
  },[]); 
 
  const QuickLoad = () =>{

    LoginOptionUpdate();                                             
     
    
}



  const cashoutCalculation = (val) =>{


   // var valModify =  parseFloat(val.replace( /[^\d\.]*/g, ''));  
    var valModify =  parseFloat(val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ""));

    if(valModify  == '' || isNaN(valModify)){
      setData({ 
        ...data, 
        cashoutAmt:0

        })
    }else{
       
      setData({ 
        ...data, 
        cashoutAmt:valModify

        })
      
    }
     
    
}
 


const NumberFomat = (val) =>{


  
  if( val > 0){
    var value =  val* 1; 
    var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return  Naira+result;  
}else{

    var val = 0
    return  Naira+val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  
       
     
    
}



   

 

 

const showHandler =()=>{

setShow(false);
reloadUserData() ;
}

const resetForm =()=>{

setcashoutAmt(''); 
setVirtualCardPin('');
setOtp(''); 
reloadData()

}  

const searchRecieverData = (val,val2) => {
 

   
  if(val.length == 16){
          setActivateLoader(true);  
          setIsLoading2(true); 
    
            setReceiverCardNo(val);
            setData({
              ...data,
              receiverCardNo:val
            })

      if( val === loginData.card_digit){
          setActivateLoader(false);  
          setIsLoading2(false); 

        setMessage("Error: you have passed your virtual credit card digits to receiver input.You can't send money to your self"); 
        setMessageSwitch(true)
       }
       else
       {



       

        var headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          }

          fetch(BasePageAction+"searchRecieverData&card="+val,
          {
          method:'GET',
          header:headers
          })
          .then((response) => response.json())
          .then((response) =>{
                


                  if(response[0].success == 'ok'){

                    setReceiverName(response[0].feedback); 

                  } else{
                    setReceiverName(''); 
                  }
                  setActivateLoader(false);  
                  setIsLoading2(false); 

          }); 

    }


     
}
 

     
}


 
   
//CREDIT CARD TRANSFER BLOCK
const CreditCardTransfer = () => {
   

  setActivateLoader(true);  
  setIsLoading2(true); 
  var authUserToken =  localStorage.getItem('authUserToken');

  var newPass = data.virtualCardPin.toString();
    
  var newPin =  md5(newPass);



  if(data.virtualCardPin == '' || receiverCardNo.length == 0 || receiverName.length == 0 )
  {
        setActivateLoader(false);  
        setIsLoading2(false); 
  
        setMessage('All fields are required');
        setMessageSwitch(true);
  }
  else
  {
    if(newPin == loginData.card_pin)
    {       
          
          if(data.cashoutAmt >= 10)
          {       
                
                
          
                const loadData = {   
                      
                            senderCardNo     :loginData.vcard_digit, 
                            cashoutAmt       :data.cashoutAmt, 
                            virtualCardPin   :newPin,  
                            userToken        :authUserToken, 
                            receiverCardNo   :data.receiverCardNo,
                            receiverName     :receiverName
                      };   
                      
                      var headers = {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                      }
                            
                      if(loginData.card_lock == 'locked')
                      {
                            setActivateLoader(false);  
                            setIsLoading2(false); 
                      
                      
                            setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
                            setMessageSwitch(true)
                      
                      }
                      else  
                      {
                                  fetch(BaseAction+"CreditCardTransfer",
                                  {
                                  method:'POST',
                                  header:headers,
                                  body: JSON.stringify(loadData)
                                  })
                                  .then((response) => response.json())
                                  .then((response) => {

                                        setActivateLoader(false);  
                                        setIsLoading2(false); 
                                      
                                        if(response[0].success == 'ok'){
                                             
                                               
                                              setMessage(response[0].feedback); 
                                              setMessageSwitch(true);
                            
                                        }else{
                            
                                              
                                              setMessage(response[0].feedback); 
                                              setMessageSwitch(true);
                                        }
                                  
                            }); 
                                  
                      }      
                
          }
          else
          {

                setActivateLoader(false);  
                setIsLoading2(false); 
          
                setMessage('Minimum amount to transfer is ₦1,000.00');
                setMessageSwitch(true);

          }

      } 
      else
      {

            setActivateLoader(false);  
            setIsLoading2(false); 
      
            setMessage('Invalid credit card pin passed');
            setMessageSwitch(true);

      }

  }
        



  
}




const copyToClipboard =(text) => {

   
  setMessage('Credit card number copied '+ text);         
  setMessageSwitch(true);

};



 
    
if(activateLoader){

      if(isLoading2)
      {


        return (     

              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );


      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }





}
else
{ 

 
        return (

        <div  style={{textAlign:"center",padding: 20,height:'100vh'}} >

 
        <div   style={{backgroundColor:MainColor,width: '100%',borderRadius:5,paddingTop:10,paddingBottom:15}} >
                        

        <div  style={{display:'flex',flexDirection:'row',width: '100%',marginBottom:20}}>
       
                    <div  style={{width: '45%',marginLeft:20 }}>   
                    <div onClick={() => setSwitchBalance(!switchBalance)}  > 
                   
                    { switchBalance?
                          <div style={{color:'white',fontSize:12}}>
                          <FaRegEye    size={20} style={{ color: 'white',textAlign:'center'  }}  />   Hide Card Bal  
                          </div>  
                          :
                          <div style={{color:'white',fontSize:12}}>
                          <FaRegEyeSlash    size={20} style={{ color: 'white',textAlign:'center'  }}  />Open Card Bal  
                          </div>  
                           
                    }
                                                
                    </div>                                
                    </div>
       
       
           
                                                                   
       
                    <div onClick={()=> copyToClipboard(loginData.card_digit)} style={{width: '45%', }}>   
                    
                    <CopyToClipboard   text={loginData.card_digit}> 
                      <div style={{color:'white',fontSize:12}}>
                      <FaRegCopy    size={20} style={{ color: 'white',textAlign:'center'  }}  /> Copy Card No</div> 
                     
                    </CopyToClipboard>                                  
                               
                    </div>                                                           
        
        </div>
       
       
       
       
       
       
              <div>
              <div style={{ alignItems:'center'}}> 
       
              <div style={{fontWeight: 'bold',color:'white',fontSize:15, paddingBottom: 20,}}>ACCOUNT TRANSFER    </div>
       
       
              
              
              {loginData.mem_fee == 'paid'?
                           
              <div style={{fontSize:25,color:'#fff',padding:10,fontWeight:'bold'}}>
              {switchBalance?
                    <div>
                       {NumberFomat(loginData.vcard_bal)}
                    </div>
               :
                    <div>
                        ****   
                    </div>                                                
              
              }
              
              <div style={{fontSize:11,color:'#fff',}}>Credit Card Bal</div>
              </div> 
                    
              :
              <div style={{fontSize:30,color:'#fff',padding:10}}>****  <div style={{fontSize:14,color:'#fff',}}>Card Bal</div></div>
              }      
        
                    
              
              </div>
       
       
              
              
              
              </div>
       
        
       
       
       
       </div>





                <div  className="taskContainer"> 
           
          
      
          <div className="Container--pch--body">
          
                  <Form style={{textAlign:"left"}}>


              <div style={{color:MainColor,fontSize:16,fontWeight:'bold'}}>Receiver Virtual Credit Card Details  </div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Enter receiver card digits</div>
              <Form.Control type="text"  placeholder={data.receiverCardNo}      onChange={e=>  searchRecieverData(e.target.value,loginData.virtual_pin)}    />
              </Form.Group>


              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Recipient name </div>
              <Form.Control type="text"   value={receiverName}      disabled/>
              </Form.Group>

 
    
        <br/><br/><hr/><br/><br/> 
     


             <div style={{color:MainColor,fontSize:16,fontWeight:'bold'}}>Sender Virtual Credit Card Details</div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Sender virtual credit card</div>
              <Form.Control type="text"    value={loginData.card_digit}     disabled/>
              </Form.Group>


              
              
              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Amount to transfer</div>
              <Form.Control type="number"   placeholder={NumberFomat(data.cashoutAmt)}    onChange={e=>  cashoutCalculation(e.target.value)}      />
              </Form.Group>
              
              
              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Enter credit card PIN</div>
              <Form.Control type="password"  maxlength="4"      onChange={e=>  setData({...data, virtualCardPin:e.target.value})}         />
              </Form.Group>
 
           {//
            Number(data.cashoutAmt) <= Number(loginData.vcard_bal)?

                    Number(data.cashoutAmt) <= 0 ?
                    <div     className="button"   style={{backgroundColor:'red'}}>
                    Enter Valid Amount 
                    </div> 
                    :
                    <div   onClick={() => {CreditCardTransfer()}}     className="button" type="submit">
                    Send Fund
                    </div> 

             :
                 
                   <div        className="button"  style={{backgroundColor:'red'}} >
                   Insufficient fund
                   </div> 

           }



     
   </Form>


        <br /><br />
        </div>
           </div>





                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                  messageSwitch?
      
            
                                  <Modal  show={true}  >
                                  <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
              
                      
            
                      
                                         <div>
            
                                  
                                        <RiMessage3Fill    size={30}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                  
                                        
                      
                      
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                        </div>
            
             
                                            <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                <div style={{color:'white'}}>close</div>
                                                </div>  
                                            </div>
                                       
            
            
            
                                        
                                        
                                        </div>
                                  </div>
                            
                                  </Modal>
                                  :  
                                  null
                      
                                  } 




          </div>
      )
   
 }


}
export default Transfer; 

 /*
{//

        
  loginData.vcard_bal >=  data.cashoutAmt?
                  
      data.cashoutAmt <= 0 ?

        <div className="button" style={{backgroundColor:'green'}}>
        <div style={{textAlign:'center',color:'white',fontSize:16,}}>Enter Valid Amount</div>
        </div>

        :
        <div  onClick={() => CreditCardTransfer()} >
        <div className="button">
        <div style={{textAlign:'center',color:'white',fontSize:16,}}>  Send Fund </div>
        </div>
        </div>
:


    <div  className="button" style={{backgroundColor:'red'}}>
    <div style={{textAlign:'center',color:'white',fontSize:16, }}> Insufficient Balance</div>
    </div>


}
 */