import React, { useState, useEffect,useLayoutEffect,memo} from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom' 
import {BsHandThumbsDown,BsChevronRight, BsFillEyeSlashFill,BsFillEyeFill,BsEye,BsEyeSlash} from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi"; 
import {AiFillCloseCircle,AiFillBell} from "react-icons/ai";    
import { GiTrade} from "react-icons/gi";    
 
import {GiPadlock,GiPadlockOpen,GiCheckMark} from "react-icons/gi";  
import { Modal, Button } from 'react-bootstrap'  
import { MdOutlineGppGood, MdGppGood,MdGroups} from "react-icons/md";   
import {FaHourglassHalf,FaCoins,FaGamepad,FaUsers,FaEnvelopeOpenText,FaRegMoneyBillAlt,FaUnlock,FaLock,FaLockOpen,FaRegTrashAlt} from "react-icons/fa";  
import { BsCheck2Circle } from "react-icons/bs";  
import { AiOutlineReload} from "react-icons/ai";   
import md5                from 'md5-hash';  
import { Naira, AbuseEmail, CardFrontUrl,BaseAction,TextColor2, MainColor,} from '../../BaseUrl'; 
import { AuthContext } from '../../components/context';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";
import './TradeAlert.css';  

const TradeAlert = (props) => {
      const { signOut,reloadUserData} = React.useContext(AuthContext);
      const[activateLoader, setActivateLoader]   = useState(false); 
      const[isLoading2, setIsLoading2]   = useState(false); 
      const[passwordText, setPasswordText] = useState('password');  
      const[goldenCoinMenuSwitch, setGoldenCoinMenuSwitch] = useState(false);  
      const[goldenCoinFetchModal, setGoldenCoinFetchModal] = useState(false);  
      const[goldenCoinData, setGoldenCoinData] = useState([]); 
      const[getSeamlessTrade, setGetSeamlessTrade] = useState(''); 
      const[gameHistoryModal, setGameHistoryModal] = useState(false);  
      const[modalPasswordInput, setModalPasswordInput] = useState(false);  
      const[modalInputGCSeller, setModalInputGCSeller] = useState(false);     
      //////////////////////////////////////////////////////////////
      const[advertData, setAdvertData] = useState([]); 
      //////////////////////////////////////////////////////////////
      const[loginData, setLoginData] = useState([]); 
      const[message, setMessage] = useState('');    
      const[trashGoldenCoin, setTrashGoldenCoin] = useState('');
      const[messageSwitch2, setMessageSwitch2] = useState(false);
      const[messageSwitch, setMessageSwitch] = useState(true);
      const[offQuestBox, setOffQuestBox] = useState(true);
      const[tradeCoinModal, setTradeCoinModal] = useState(false); 
      const [viewBalance, setViewBalance] = useState(false);   
       const[data, setData] = useState({
            message: '',
            password: '',
            coinUnit: '',
            coinValue: '',  
            coinAmt: '',  
            receiverCode: '',  
            receiverName: '',  
            check_textInputChange: false,
            secureTextEntry: true 
         })
        
       
         const updateSecureTextEntry = () => {
           

            setData({
                  ...data, 
                  secureTextEntry: !data.secureTextEntry
                  });



            if(data.secureTextEntry){
                  setPasswordText('text');
            }
            else
            {
                  setPasswordText('password');
            }
            
            }
      
       
        const navigate = useNavigate();      

    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
    setLoginData(data);
      
    }
    
    useEffect(() => {
          
        setTimeout(async() => {
        
            LoginOptionUpdate();  
        
        }, 5000);
                        
    });
    
    useLayoutEffect(() => {
    
          LoginOptionUpdate();
          
    },[]); 
  
  
    useEffect(() => {
    
        if(loginData.public_msg == ''){
              setMessageSwitch(false)
        }
          
          
    },[]); 
  
   
///  DECLINE TRADE REQUEST
const declinedTradeRequest = (val) => {



      setActivateLoader(true);  
      setIsLoading2(true); 

            
            var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }

            var newUse = Number(loginData.coin_trade_sender) + Number(191);
            var newPass = newUse.toString();
            var coinTradeSendertoken = md5(newPass);
           


            var authUserToken =  localStorage.getItem('authUserToken');
            fetch(BaseAction+"DeclinedTradeRequest&coinTradeSendertoken="+coinTradeSendertoken+"&receivertoken="+authUserToken+'&SENDER='+loginData.coin_trade_sender,
            {
            method:'GET',
            header:headers
            })
            .then((response) => response.json())
            .then((response) =>{
                  
            reloadUserData(); 
            setActivateLoader(false);  
            setIsLoading2(false); 
            }); 


      
}

///   APPROVE TRADE BUYER REQUEST
const goldenCoinBuyer = () => {


      setActivateLoader(true);  
      setIsLoading2(true); 

            if(loginData.card_lock == 'locked'  )
            {
                  setActivateLoader(false);  
                  setIsLoading2(false); 


                  setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
                  setMessageSwitch(true)
            


            }
            else
            {    
            var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            const passkey =  localStorage.getItem('authUserToken');

            var newUse = Number(loginData.coin_trade_sender) + Number(191);
            var newPass = newUse.toString();
            var userkey = md5(newPass);


            fetch(BaseAction+"CoinBuyerApproveTransaction&sellerToken="+passkey+"&buyerToken="+userkey+"&seamless_trade=buyer",
            {
            method:'GET',
            header:headers
            })
            .then((response) => response.json())
            .then((response) =>{
                  
           
                        reloadUserData(); 
 
            }); 

      }
      
}
///  APPROVE TRADE SELLER REQUEST
const goldenCoinSeller = () => {


      setActivateLoader(true);  
      setIsLoading2(true); 

            if(loginData.card_lock == 'locked'  )
            {
                  setActivateLoader(false);  
                  setIsLoading2(false); 


                  setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
                  setMessageSwitch(true)
            


            }
            else
            {    
            var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            const sellerToken =  localStorage.getItem('authUserToken');

            var newUse = Number(loginData.coin_trade_sender) + Number(191);
            var newPass = newUse.toString();
            var buyerToken = md5(newPass);


            fetch(BaseAction+"CoinSellerApproveTransaction&buyerToken="+sellerToken+"&sellerToken="+buyerToken+"&seamless_trade=seller",
            {
            method:'GET',
            header:headers
            })
            .then((response) => response.json())
            .then((response) =>{
                  
            reloadUserData(); 
       
            }); 

      }
      
}
 
//FETCH GAME DATA HISTORY
const DeleteDeadGoldenCoin = () => {

      setMessageSwitch2(false)
      setActivateLoader(true);  
      setIsLoading2(true);  
    
 

 
            var headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json'
            }
      
            fetch(BaseAction+"DeleteDeadGoldenCoin&user="+loginData.username+"&deleteCoin="+trashGoldenCoin,
            {
            method:'GET',
            header:headers, 
            })
            .then((response) => response.json())
            .then((response) => { 
                  
                  reloadUserData();
             
                  
            }) 
            .catch(error => {
                  setActivateLoader(true);  
                  setIsLoading2(false);    
            });
     
              
 
  
      
  }

     
      
       
 

 /// CREDIT CARD CASHOUT BLOCK
const confirmPasswordSeller = () => {
      

      var hash   = md5(data.password);
      var passW1 = String(loginData.password); 
      var passW2 = String(hash); 


      
      if( passW1 === passW2)
      {
            setModalInputGCSeller(false);

         
                  goldenCoinBuyer();

          
      } 
      else
      {

            

            setData({
            ...data, 
            message: "Wrong account password passed"
            });
            
      }

            
}
      
 /// CREDIT CARD CASHOUT BLOCK
const confirmPinCashout = () => {
      

      var hash   = md5(data.password);
      var passW1 = String(loginData.card_pin); 
      var passW2 = String(hash); 


      
      if( passW1 === passW2)
      {
            setModalPasswordInput(false);

            goldenCoinSeller();
           
            
      } 
      else
      {

            

            setData({
            ...data, 
            message: "Wrong Credit Card PIN Passed"
            });
            
      }

            
}
      
      


 

    const NumberFomat = (val) =>{
  
       
      var value =  val* 1; 
      var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return   Naira+result;  
                                           
  
  
  }
 
 
   
     const sellerHandler =()=>{
      setGetSeamlessTrade('seller')
      setModalInputGCSeller(true)
     
     }
  
     const buyerHandler =()=>{
      setGetSeamlessTrade('buyer')
      setModalPasswordInput(true)
     
     }
  
   
     const showHandler =()=>{
        setMessage('');
        setActivateLoader(false);
        setIsLoading2(false);
        
     
     }
  
   
   
  
if(activateLoader)
     {
  
      if(isLoading2)
      {
  
  
        return (     
      
              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );
  
  
      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }
  
  
  
  
}
else
{ 

   
  
    return (
  
          <div  style={{padding: 20,height:'100vh'}} >
  
   
           
          <div>
                                               
                                       
                  
                                                      
                  
          <div>{message}</div>

 
    { ///////////////////////////////TRADE ALERT FROM BUYER, SELLER BUTTON IS NEEDED
      loginData.seamless_trade == 'buyer' ?
            <div>
                  <div style={{alignItems:'center',marginBottom:70}}>  
                  <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>TRADE ALERT</div>
                  <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>SHARES(SH) BUYER REQUEST</div>

                        <div style={{alignItems:'center',marginTop:50}}>
                        <img src='images/1gcoin.png'  style={{width: 80, height: 80, }} />
                        <div style={{fontWeight:'bold',fontSize:15,color:'black',textDecorationLine:'underline'}}>1 Shares(SH)  <div style={{fontSize:15,}}>({NumberFomat(loginData.coin_selling_amt)})</div></div>

                        {
                              loginData.coin_strength == 'good'? 
                                    <div style={{color:'green'}}>
                                    <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) status   </div> [ Active ] 
                                          <BsCheck2Circle color={'green'}    size={20}       />
                                    </div> 
                              : loginData.coin_strength == 'red'?
                                    <div style={{color:MainColor}}>
                                    [ Unhealthy ]   
                                    <BsHandThumbsDown  color={'red'}    size={20}       /> 
                                    </div> 
                              : loginData.coin_strength == 'scam'?
                                    <div style={{color:MainColor}}>
                                    [ Bad ]   
                                    <BsHandThumbsDown  color={'red'}    size={20}       /> 
                                    </div> 
                              :null
                              }
                        </div>


                        <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) purchased date
                        <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [ {loginData.reg_date} ] </div>
                        </div> 

                        <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) No
                        <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [{loginData.coin_selling_id}]  </div> 
                        </div>  

                  </div>   

                  <div style={{textAlign:"left",padding: 20,}}>

                        <div style={{fontSize:14,color:TextColor2,marginBottom:5}}>
                         {loginData.private_msg}.
                        </div>


                        <div style={{fontSize:14,color:TextColor2,marginBottom:5}}>
                  Accepting buyer trade request implies you are selling 1 Shares no <span style={{color:'red',fontSize:15,}}> ({loginData.coin_selling_id}) </span> from your Shares(SH) wallet to <span style={{color:'red',fontSize:14}}> {loginData.coin_trade_sender} </span> 
                  at the rate of <span style={{color:'green',fontSize:20,}}> {NumberFomat(loginData.coin_selling_amt)},</span> of which your virtual credit card will be auto credited with the total 
                  amount of <span style={{color:'green',fontSize:20,}}> {NumberFomat(loginData.coin_selling_amt / 100 * loginData.user_pec)} </span> and <span style={{color:'red',fontSize:20,}}> {NumberFomat(loginData.coin_selling_amt / 100 * loginData.admin_pec)} </span> will be deducted from the selling 
                  price to settle {loginData.admin_pec}% selling price charge   if "SELL SHARES(SH)" button is clicked and approve.
                        </div>

  

                        <div   style={{display:'flex',padding: 20,flexDirection:'row',alignItems:'center',}} >

                        <div className="buttonRed" style={{padding:10,fontSize:11,width:'45%',backgroundColor:'green'}}  onClick={()=> declinedTradeRequest()}  >
                        <div style={{color:'white'}}>DECLINE TRADE<br /> REQUEST</div>
                        </div>  


                        <div className="buttonRed" style={{padding:10,fontSize:11,width:'45%',marginLeft:"2.5%"}} onClick={()=> sellerHandler()}  > 
                        <div style={{color:'white'}}>SELL <br />SHARES(SH)</div>
                        </div>  


                        </div>

                  </div>
            </div>

      :   
       null
    }




    {  ///////////////////////////////TRADE ALERT FROM SELLER  BUY BUTTON IS NEEDED
      
      loginData.seamless_trade == 'seller'?
            <div>

                  <div style={{alignItems:'center',marginBottom:70}}>  
                  <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>TRADE ALERT</div>
                  <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>SHARES(SH) SELLER REQUEST</div>

                  <div style={{alignItems:'center',marginTop:50}}>
                  <img src='images/1gcoin.png'  style={{width: 80, height: 80, }} />
                  <div style={{fontWeight:'bold',fontSize:15,color:'black',textDecorationLine:'underline'}}>1 Shares(SH)  <div style={{fontSize:15,}}>({NumberFomat(loginData.coin_selling_amt)})</div></div>

                  {
                        loginData.coin_strength == 'good'? 
                              <div style={{color:'green'}}>
                              <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}> Shares(SH) status   </div> [ Active ] 
                                    <BsCheck2Circle color={'green'}    size={20}       />
                              </div> 
                        : loginData.coin_strength == 'red'?
                              <div style={{color:MainColor}}>
                              [ Unhealthy ]   
                              <BsHandThumbsDown  color={'red'}    size={20}       /> 
                              </div> 
                        : loginData.coin_strength == 'scam'?
                              <div style={{color:MainColor}}>
                              [ Bad ]   
                              <BsHandThumbsDown  color={'red'}    size={20}       /> 
                              </div> 
                        :null
                        }
                  </div>


                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) purchased date
                  <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [ {loginData.reg_date} ] </div>
                  </div> 

                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) No
                  <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [{loginData.coin_selling_id}]  </div> 
                  </div>  

                  </div>   

                  <div style={{textAlign:"left",padding: 20,}}>

                        <div style={{fontSize:14,color:TextColor2,marginBottom:5}}>
                        Hi, {loginData.fullname} you have a "Shares(SH)" trade request from <div style={{color:'red',fontSize:18}}> {loginData.coin_trade_sender} </div>,  please
                        confirm the seller phone number before accepting to
                        buy one Shares (1SH) from seller.
                        </div>


                        <div style={{fontSize:14,color:TextColor2,marginBottom:5}}>
                  Accepting seller trade request means you are buying one Shares (1SH) from <div style={{color:'red',fontSize:14}}> {loginData.coin_trade_sender} </div> 
                  at the rate of {NumberFomat(loginData.coin_selling_amt)}, of which your virtual credit card will be auto debited with total amount of {NumberFomat(loginData.coin_selling_amt)} if "BUY SHARES(SH)" button is clicked and approved.
                        </div>





                        <div style={{fontSize:14,color:'red', marginBottom:10,marginTop:5}}>
                        Note: Accepting trade request by buying 1 Shares(SH) implies that you consent a debit total amount of {NumberFomat(loginData.coin_selling_amt)}  from 
                        your virtual credit card. Strongly note that successfully transaction is irreversible 
                        </div>


                        <div   style={{display:'flex',padding: 20,flexDirection:'row',alignItems:'center',}} >

                        <div className="buttonRed" style={{padding:10,fontSize:11,width:'45%',backgroundColor:'green'}}  onClick={()=> declinedTradeRequest()}  >
                        <div style={{color:'white'}}>DECLINE TRADE<br /> REQUEST</div>
                        </div>  


                        <div className="buttonRed" style={{padding:10,fontSize:11,width:'45%',marginLeft:"2.5%"}} onClick={()=> buyerHandler()}  > 
                        <div style={{color:'white'}}>BUY  <br />SHARES(SH)</div>
                        </div>  


                        </div>

                  </div>

            </div>
     :
            null
    }

          



</div>
  


                        {/// Shares(SH) TRADING ALERT REQUEST
                              modalInputGCSeller?

                              <Modal  show={true}    >  
      
                              
                              <div  >
                              
                              
      
                                   
                        
                                    <div   style={{ marginBottom:10,alignItems:'center'}} >
                                    <img style={{width:'100%',}}  src="images/approve_coin_req.png" />
                                    </div>

                                  
                                    <p  style={{ padding:10,alignItems:'center',fontSize:14}}>Hi {loginData.fullname}, Please to approve this trade request, enter your account login password to verify it's you</p>
                                    <div style={{marginTop:50,alignItems:'flex-start',marginLeft:20}}>
                                          <div style={{textAlign:'left'}}>
                                          <FaLock     size={30} color={MainColor}      /> Enter Password
                                          </div>                           
                                    </div>

                                    <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,marginLeft:20}}>

                                    

                                          <div>
                                                <input    
                                                className="input" 
                                                onChange={e=> setData({ ...data,  password:e.target.value})} 
                                                type={passwordText}
                                                value={data.password}  
                                                secureTextEntry={data.secureTextEntry ? true : false}
                                                style={{width: 200,fontSize:20,color:'black',textAlign:'center'}} 

                                                />                               
                                          </div>

                                    <div className="input"  style={{width:'20%',textAlign:'left',}}>
                                          <div    onClick={()=> updateSecureTextEntry()}  >
                                                {data.secureTextEntry ?

                                                      <BsFillEyeSlashFill
                                                      
                                                      style={{color:MainColor,width: '100%'}} 
                                                      size={25}
                                                      /> 
                                                :
                                                      <BsFillEyeFill   
                                                      style={{color:MainColor,width: '100%'}}  
                                                      size={25}
                                                      /> 
                                                }
                                          </div>   
                                    </div>

                                    </div>
                                      
                                    <div style={{color:'red',fontSize:15}}> <br /> {data.message}</div>
                                    

                                    <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:50,marginBottom:50,justifyContent:'space-around'}}>

                                    <div  onClick={()=>confirmPasswordSeller()} style={{width:'45%',marginLeft:'2.5%'}}>
                                    <div className="button">
                                    <div style={{textAlign:'center',color:'white',fontSize:16,}}> Approve </div>
                                    </div>
                                    </div>


                                    <div  onClick={() => setModalInputGCSeller(false)} style={{width:'45%'}}>
                                    <div className="button" style={{textAlign:'center',fontSize:16,backgroundColor:'green'}}>
                                    <div > Cancel </div>
                                    </div>
                                    </div>






                                    </div>



                              
                              </div>
                              
                             
                              </Modal>
                              :
                              null
            
                        

                        }


                        {/// Shares(SH) TRADING ALERT REQUEST
                              modalPasswordInput?

                              <Modal  show={true}    >  
      
                              
                              <div  >
                              
                              
      
                                    <div style={{ color: TextColor2, fontSize: 20,marginTop: 5,marginBottom: 20, textAlign: 'center',}}>
                                    CREDIT CARD TRANSACTION
                                    </div>
                        
                                    <div   style={{marginTop: 5,marginBottom:10,alignItems:'center'}} >
                                    <img style={{width:250,height:170,alignItems:'center',borderRadius:10}}  src={CardFrontUrl+loginData.card_img} />
                                    </div>

                              

                                    <div style={{marginTop:50,alignItems:'flex-start',marginLeft:20}}>
                                          <div style={{textAlign:'left'}}>
                                          <FaLock     size={40} color={MainColor}      /> Enter Credit Card PIN 
                                          </div>                           
                                    </div>

                                    <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,marginLeft:20}}>

                                    

                                          <div>
                                                <input    
                                                className="input" 
                                                onChange={e=> setData({ ...data,  password:e.target.value})} 
                                                type={passwordText}
                                                value={data.password}  
                                                maxLength={4}
                                                secureTextEntry={data.secureTextEntry ? true : false}
                                                style={{width: 150,fontSize:20,color:'black',textAlign:'center'}} 

                                                />                               
                                          </div>

                                    <div className="input"  style={{width:'20%',textAlign:'left',}}>
                                          <div    onClick={()=> updateSecureTextEntry()}  >
                                                {data.secureTextEntry ?

                                                      <BsFillEyeSlashFill
                                                      
                                                      style={{color:MainColor,width: '100%'}} 
                                                      size={25}
                                                      /> 
                                                :
                                                      <BsFillEyeFill   
                                                      style={{color:MainColor,width: '100%'}}  
                                                      size={25}
                                                      /> 
                                                }
                                          </div>   
                                    </div>

                                    </div>
                                      
                                    <div style={{color:'red',fontSize:15}}> <br /> {data.message}</div>
                                    

                                    <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:50,marginBottom:50,justifyContent:'space-around'}}>

                                    <div  onClick={()=>confirmPinCashout()} style={{width:'45%',marginLeft:'2.5%'}}>
                                    <div className="button">
                                    <div style={{textAlign:'center',color:'white',fontSize:16,}}> Approve </div>
                                    </div>
                                    </div>


                                    <div  onClick={() => setModalPasswordInput(false)} style={{width:'45%'}}>
                                    <div className="button" style={{textAlign:'center',fontSize:16,backgroundColor:'green'}}>
                                    <div > Cancel </div>
                                    </div>
                                    </div>






                                    </div>



                              
                              </div>
                              
                             
                              </Modal>
                              :
                              null
            
                        

                        }


                       {///  (MESSAGE NOTIFICATIONS)taskContainer
                        messageSwitch?
                  
                        
                        <Modal  show={true}    > 
                        <div style={{flex:1,backgroundColor: 'white'}}    >
                        <div>
                  
                        <GiTrade    size={30}  color={MainColor} /> 
                        <div style={{fontWeight:'bold',fontSize:14,color:'black'}}> SHARES(SH) TRADE REQUEST</div>  


                                                     
            
                              <div   style={{marginTop: 20,padding:20}} >
                               {loginData.private_msg == ''?
                               <div  style={{fontSize: 14,color:'black'}} >{message}</div>
                               :
                               <div  style={{fontSize: 14,color:'black'}} >{loginData.private_msg}</div>
                               }
                                     
                              </div>

                              
                              </div>

                              <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                              <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                              <div style={{color:'white'}}>Close</div>
                              </div>  
                              
                          </div>


                        </div>
                  
                        </Modal>
                        :  
                        null

                        }


                      {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                        messageSwitch2?


                        <Modal  show={true}  >
                        <div  onClick={() => setMessageSwitch2(false)}   >
    
            
  
            
                                <div  >
  
                        
                        <AiFillBell  size={30}  color={MainColor} /> 
                              <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>SHARES(SH) DELETE ?</div>  
                        
                              
            
            
                              <div   style={{marginTop: 20}} >
                              <div style={{flexDirection:'column',alignItems:'center'}}> 
                              <img    style={{width: 100, height: 100,alignItems:'center'}}   src='images/1gcoin.png' />  
                              <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>1 Shares(SH) </div>
                              </div>  


                                    <div  style={{fontSize: 14,color:'red',}} >{message}</div>
                              </div>
  
  
                              <div   style={{paddingBottom: 20,marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                              
                              <div  class="buttonBusy" style={{padding:10,fontSize:14,width:'40%',backgroundColor:'green',}}  onClick={() => setMessageSwitch2(false)}  >
                              <div style={{color:'white'}}>Cancel</div>
                              </div>  
  
  
                              <div  class="buttonBusy"  style={{padding:10,fontSize:14, backgroundColor:'red', width:'40%',}} onClick={() => DeleteDeadGoldenCoin()}  > 
                              <div style={{color:'white'}}>Delete </div>
                              </div>  
  
  
                              </div>
                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null
            
                        } 
            </div>
      )
  
 }
     
  };
  


 
export default memo(TradeAlert);  





          