import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'; 
import {FaUser,FaWhatsapp} from "react-icons/fa";    
import {BsCheck2Circle,BsFillBellFill} from "react-icons/bs";
import { RiMessage3Fill } from "react-icons/ri";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Modal, Button,Form } from 'react-bootstrap'; 
import {BaseAction, SubscriptionUrl,MainColor,TelegramLink } from '../../../BaseUrl';
import { AuthContext }   from '../../../components/context';
import Loading          from "../../Loading";
import Loading2          from "../../Loading2";
import { FaRegStar } from "react-icons/fa";

//import '../../../App.css'; 
//import './AccountSetup.css'; 
 
const AccountSetup = ( props ) => {  
const {  reloadUserData,signOut} = React.useContext(AuthContext); 
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false); 
const[voucherCode, setVoucherCode]   = useState(''); 
const[messageNote, setMessageNote]   = useState(false); 
const[messageSwitchTopUp, setMessageSwitchTopUp]   = useState(true); 
const[message, setMessage] = useState('');   
const[messageSwitch, setMessageSwitch] = useState(false);  
const[useVoucherCode, setUseVoucherCode] = useState(false);  
const[loginData, setLoginData] = useState([]); 
const [seconds, setSeconds] = useState(0);
const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 
const navigate = useNavigate(); 
////////////////////////////////////////////////////////////// 

 
 
const generateRandomNumber = () => {
  
  if (seconds > 0) {
    setTimeout(() => setSeconds(seconds - 1), 1000);

    return '00:00:'+seconds;

  } else {
   
    return '00:00:00';
  }



}


const[data, setData] = useState({ 
    ageMate: '',  
    saerchMe: '',  
    meetClique: '',  
    age: '',  
 });

 const LoginOptionUpdate =() => 
 {
  
   const value =  localStorage.getItem('loginSession');
   const data = JSON.parse(value);  
   setLoginData(data);
 
  

 
 
 }
 
 useLayoutEffect(() => {
       
 
  
   
   setTimeout(async() => {

     LoginOptionUpdate();  
     
 
 }, 3000);


                
   });
 

   const FetchTopUpHistory = () => {
  
    setActivateLoader(true);  
    setIsLoading2(true);  
  
  
  
            var headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
      
           fetch(BaseAction+"FetchTopUpHistory",
                {
                method:'GET',
                header:headers,
        
                })
                .then((res) => res.json())
                .then((response) => {

              

                      if(response[0].success == "0")
                      {
                      
                            setActivateLoader(false);  
                            setIsLoading2(false);  
  
                            
                            setMessage(response[0].feedback);  
                            setMessageSwitch(true) 
                      }
                      else 
                      {

                        setTopUpAgentHistoryData(response); 
                        setMessageSwitchTopUp(true);
                        
                        setActivateLoader(false);  
                        setIsLoading2(false);  
                      }



                })  
                .catch(error => {
                  
                    setActivateLoader(true);  
                    setIsLoading2(false); 
                });
        
            

    //  Keyboard.dismiss();
 


    
}
  

const SubmitVoucherCode = () => {
  var authUserToken =  localStorage.getItem('authUserToken');

    setUseVoucherCode(false)
    setActivateLoader(true);  
    setIsLoading2(true);  
  
  
  if(voucherCode.length == 0){
 
    setMessage('Voucher code is required');  
    setMessageSwitch(true);
    
    
    setActivateLoader(false);  
    setIsLoading2(false); 
  }
  else
  {
     var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }
      
           fetch(BaseAction+"SubmitVoucherCode&userToken="+authUserToken+'&VoucherCode='+voucherCode,
                {
                method:'GET',
                header:headers,
        
                })
                .then((res) => res.json())
                .then((response) => {

                                      
                            setMessage(response[0].feedback);  
                            setMessageSwitch(true) 
             
                           

                })  
                .catch(error => {
                  
                    setActivateLoader(true);  
                    setIsLoading2(false); 
                });
        
  }
    
}


const starDisplay =(val)=>{



      if(val == 4){
      return <span> <FaRegStar color={MainColor} /> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
      }else if(val == 3){
      return <span><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
    
      }else if(val == 2){
      return <span> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
    
      }else if(val == 1){
      return <span> <FaRegStar color={MainColor} /></span>
    
      }else if(val == 0){
      return <span></span>
    
      }
    
  
 }
 

 const  ReLoadHanduler =() =>{

 
  setActivateLoader(false);  
  setIsLoading2(false);
  setMessage('');

}
 const  ReLoadHandulerApi =() =>{

  setMessageSwitch(false) 
  reloadUserData();

}
 

const CreditCradRequest=()=>{
  var authUserToken =  localStorage.getItem('authUserToken');
 
 if(loginData.length  == 0){

  }else{
    setSeconds('60')
    var links ='./payment-link?fullname='+loginData.fullname+'&userToken='+authUserToken+'&email='+loginData.email+'&username='+loginData.username+'&credit_card_amt='+loginData.credit_card_amt;
   window.open(links, 'RSB DIGITAL SHARES CREDIT CARD REQUEST ', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');  
  }

}
 
 
if(activateLoader)
{ 

  if(isLoading2)
  {

        return(
            <Modal     show={true}   > 
               <Loading  />
            </Modal>
 
        )
        
  }
  else 
  {
        return(
            <Modal  show={true}   > 
            <Loading2  ReLoadHanduler={ReLoadHanduler}  />
            </Modal>
        )

  }  

}
else
{ 

    return (
     
<div style={{paddingBottom:120}}>
{/////////////////  TOP MENUS BARS 
}
        <div style={{flexDirection:'row',width: '100%',padding:5, backgroundColor:MainColor, }}> 

       
 
                <div style={{width: '100%',textAlign:'center'}}>  
                        <div>
                        <div  style={{ alignItems: 'center', }}>
                        <FaUser size={30}  style={{color: '#fff', textAlign: 'center', }}      />
                        <div style={{fontSize:13,color: '#fff',}}>HI {loginData.fullname}</div> 
                        <div style={{fontSize:13,color: '#fff',}}>Your Trading Code is {loginData.level_1}</div> 
                        
                        </div> 
                        </div> 
                        
                </div>
 
        </div>

        <div  >  
        
        

            <div style={{paddingLeft:25,paddingRight:25,}}>
        
            <div   style={{fontWeight:'bold',fontSize:14,color:'black',textAlign:'center',marginTop:50}}>
                    YOU NEED VIRTUAL CREDIT CARD  
            </div>
            <div style={{fontWeight:'bold',fontSize:14,color:'black',textAlign:'center',  }}>
                 ON THIS TRADING PLATFORM  
            </div>

            
            <div   style={{marginTop: 5,marginBottom:5,alignItems:'center'}} >
           <center> <img  style={{width: 250, height: 170,borderRadius:10}}   src='images/verified.png' /> </center> 
            </div>



          <div >

        {  
          seconds == 0?
          <div style={{marginTop:20,width:'100%',display:'flex',flexDirection:'row'}}>                  
                    <div style={{marginTop:20,width:'45%'}}  onClick={CreditCradRequest} > 
                    <div className="button huge_font_sub_text">REQUEST CREDIT CARD</div>
                    </div>  


                    <div style={{marginTop:20,width:'45%',marginLeft:'5%'}} onClick={()=> FetchTopUpHistory()}   >
                    <div className="button huge_font_sub_text">TOP-UP AGENT HELPS</div>
                    </div> 
          </div>
          :                 
          <div style={{marginTop:20}} >
                  <div style={{textAlign:'center',color:'#777777',fontSize:12}}> Please Wait for system to detect your payment </div>
                  <div  style={{textAlign:'center',color:'red',fontSize:20}}>{generateRandomNumber()} </div>
          </div>                 
          }
                      
          <div style={{marginTop:20,width:'80%',marginLeft:'10%',}} onClick={()=> setUseVoucherCode(true)}   >
          <div className="button  huge_font_sub_text" style={{backgroundColor:'green',color:'white'}}>USE FREE VOUCHER TO PAY CREDIT CARD</div>
          </div> 
          
          
          </div>


                    <div style={{textAlign:'left'}}>
                    
                    

                            <div style={{marginTop:20,}}>
                            
                                <div className="text" style={{textDecorationLine:'underline',fontWeight:'bold',color:'black'}}>RSB DIGITAL SHARES KNOW IT YOURSELF(KIY) </div> 
                                <div  className="smallText">1. Request virtual credit card</div>
                                <div  className="smallText">2. Setout shares(SH) profit sharing </div>
                                <div  className="smallText">3. Pay Monthly Channel / Maintenance Levy</div>
                              
                                
                            </div>
                          
                            <div style={{marginTop:50}}>
                                <div className="text" style={{textDecorationLine:'underline',color:'black',fontWeight:'bold'}}> WHAT'S NEXT AFTER GOTTEN YOUR CREDIT CARD?</div>  
  
                                <div className="smallText" style={ {color:'black',marginBottom:10  }}>  
                                Fund your credit card and buy minimum of {loginData.shares_min}shares(SH) to set out and start earning profit sharing on your shares<br/>
                                <center>OR<br/> </center> 
                                Use your trader's code to invite minimum of {loginData.shares_min} persons and get a reward of {loginData.shares_min} shares(SH) to 
                                set out and start earning profit sharing on your shares(SH) 
                                </div>
                                                            




                            </div>


                            <div style={{marginTop:30,marginBottom:50}}>
                                <div className="text" style={{textDecorationLine:'underline',color:'black',fontWeight:'bold'}}>
                                WHAT YOU CAN DO ON RSB DIGITAL SHARES
                                 </div>  

                                <div className="smallText" style={ {color:'black', marginBottom:10}}> 
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Cashout to our Top-Up Agents for fast cashout(T&C Apply) 
                                </div> 
                                
                                <div className="smallText" style={ {color:'black',marginBottom:10 }}> 
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Top up your credit card via your bank or via our Top-Up Agents 
                                </div> 

                                <div className="smallText" style={ {color:'black',marginBottom:10 }}>
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Access to non-cash refundable loan(Loan will be repay from your  profit sharing)
                                </div> 


                                <div className="smallText" style={ {color:'black',marginBottom:10  }}> 
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Sell shares(SH) anytime; your credit card will be credited instantly
                                </div>
 
                                
                                <div className="smallText"style={ {color:'black',marginBottom:10 }}>
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Buy/Stock more shares(SH) to increase your  profit sharing
                                </div>  
                            
                      
                                <div className="smallText"  style={ {color:'black', marginBottom:10}}> 
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Transfer funds to friends with active credit card on zero charges
                                </div> 

                                <div className="smallText" style={ {color:'black', }}> 
                                <BsCheck2Circle    size={15} color={MainColor}      />
                                Buy / Gift  Airtime & Data to your friends via your credit card
                                </div> 

                         








                                
                            </div>
                </div>
            </div>   
   

      </div>


                              {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                messageSwitchTopUp?


                                <Modal  show={true}  >
                                        <div  onClick={() => setMessageSwitchTopUp(false)}  style={{padding: 20}}  > 
                                        <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                                        </div>
                                        <div>
                                        <div style={{fontSize:14,textAlign:'center',fontWeight:'bold'}}>  CREDIT CARD ACTIVATION (TOP-UP AGENT) </div>
                                        <div style={{fontSize:12,textAlign:'center',padding:5}}>Our agent can help you activate your virtual credit card if you are having
                                        difficulties using with your bank account  </div>
                                        </div><br />
            
                                        <div>
            
                                    
                    
                                        {
                                        topUpAgentHistoryData.map((item) => (
                                            <div>
                            
            
                                            <div   style={{textAlign:'left',backgroundColor:'#f2f2f2',paddingLeft:20,paddingBottom:20,fontSize:12}}>
            
                                            <Link to={'https://api.whatsapp.com/send?phone=234'+item.phone} style={{textDecoration:'none'}}>
                                            <div style={{flexDirection:'row',width: '100%'}}>  
                                            <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                           Agent Status: ({
                                                item.status== 'busy'?
                                                <span style={{color:MainColor,}}>Busy can't take a deal</span>
                                                :
                                                item.status== 'close'?
                                                <span style={{color:MainColor,}}>Not open for biz</span>
                                                :
                                                item.status== 'open'?
                                                <span style={{color:MainColor,}}>Open to take a deal</span>
                                                :
                                               null
                                            }) </div>
                                        </div>


                                        <div style={{flexDirection:'row',width: '100%'}}>  
                                            <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                            ADENT ID:({   item.agent_code  }) 
                                            </div>
                                        </div>
                                
                                                    <div style={{flexDirection:'column',width: '99%', }}>
                                                            
                                
                                                    <div    style={{flexDirection:'column',width: '100%'}}>
                                                    <div  style={{color:'black',}}>Agent name:{item.name}</div>    
                                                    <div  style={{color:'black',}}>Phone number:0{item.phone} </div>    
                                                    <div  style={{color:'black',}}>whatsapp Only  <FaWhatsapp size={20} style={{ color: MainColor,textAlign:'center'  }}  />  </div>    
                                                    <div  style={{color:'black',}}>Agent Rate:{starDisplay(item.rate)} </div> 
                                                    </div> 
                                
                                
                                
                                
                                
                                
                                                    </div>                        
                                            </Link>
                                
                                        </div>  
                                                
                                            </div> 
            
                                            ))
                                        }  
                                        
                                        
                                    
                                        </div>
                            
                                </Modal>
                                :  
                                null
                    
                                } 
                              {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                useVoucherCode?


                                <Modal  show={true}  >
                                        <div  onClick={() => setUseVoucherCode(false)}  style={{padding: 20}}  > 
                                        <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                                        </div>
                                        <div>
                                        <div style={{fontSize:14,textAlign:'center',fontWeight:'bold'}}> FREE CREDIT CARD ACTIVATION  </div>
                                        <div style={{fontSize:12,textAlign:'center',padding:10}}>Get free voucher Code to activate your virtual credit card from any of our bona-fide Top-Up Agent </div>
                                        </div><br />
            
                                        <div>
            
                                    
                    
                                        <div  className="taskContainer"> 
           
          
      
                                        <div className="Container--pch--body">
                                        
                                      <Form style={{textAlign:"left"}}>
                              
                                       
                                           <br /><br />
                                           <Form.Group className="mb-3" controlId="formBasicPassword"> 
                                              <div>Enter Voucher Code</div>
                                            <Form.Control type="text"     onChange={e=>  setVoucherCode(e.target.value)}      />
                                           </Form.Group>
                                  
                                    
                                            <div     onClick={()=> SubmitVoucherCode()}    className="button" type="submit">
                                            Submit Voucher
                                            </div> 
                               
                                       </Form>
                              
                              
                                      <br /><br />
                                      </div>
                                         </div>
                              
                                        
                                    
                                        </div>
                            
                                </Modal>
                                :  
                                null
                    
                                } 

                                                     {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                      messageSwitch?
      
            
                      <Modal  show={true}  >
                      <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
  
          

          
                             <div>

                      
                            <RiMessage3Fill    size={30}  color={MainColor} /> 
                            <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>Message</div>  
                      
                            
          
          
                            <div   style={{marginTop: 20}} >
                            
                                  <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                            </div>


                            <div   > 
                              <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                              <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() =>   LoginOptionUpdate()}  >
                              <div style={{color:'white'}}>close</div>
                              </div>  
                              </div>  
                              
            

                            </div>
                            
                            </div>
                      </div>
                
                      </Modal>
                      :  
                      null
          
                      }   
                      
</div>

    )

}


}

 



export default memo(AccountSetup);     

 