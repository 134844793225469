
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios"; 
import { FaUserCheck,FaUserLock } from "react-icons/fa";
import { MdTipsAndUpdates } from "react-icons/md";
import { BsShieldLockFill } from "react-icons/bs";
import Modal from "../UI/Modal/Modal";  
import { BasePageAction,BodyColor } from '../../BaseUrl';
import { AuthContext } from '../../components/context';
import './Login.css';
import md5               from 'md5-hash';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";

function Login() { 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false); 
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();   


  const { signIn } = React.useContext(AuthContext);



  // function refreshPage() {
  //   setTimeout(()=>{
  //       window.location.reload(false);
  //        setShow(true);
  //   }, 5000);
     
  // }



  


  const handleSubmit = e =>  {
  e.preventDefault();

  setActivateLoader(true);
  setIsLoading2(true);
  
  if(password == '' || phone == '' 
  ){

    setIsLoading2(false);

    setMessage('All form fields are required');
  }
  else
  {
    var newUse = Number(phone) + Number(191);
    var newPass = newUse.toString();


      const data = {    
        username: md5(newPass),
        password: md5(password)
    
      };

 

       
        var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        
        fetch(BasePageAction+"Login",
        {
        method:'POST',
        header:headers,
        body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((response) => {
         
              if(response[0].success == 'success'){
 
                
                //OBJLOCATE 

                var obj = {      
                
                  level_27          : response[0].level_27,
                  user_coach        : response[0].user_coach,
                  level_9           : response[0].level_9, 
                  level_3           : response[0].level_3, 
                  level3_row_paid   : response[0].level3_row_paid, 
                  level_1           : response[0].level_1,
                  photo             : response[0].photo,  
                  username          : response[0].username, 
                  password          : response[0].password,
                  userkey           : response[0].userkey,
                  fullname          : response[0].fullname,   
                  email             : response[0].email,  
                  gender            : response[0].gender,
                  address           : response[0].address, 
                  city              : response[0].city, 
                  state             : response[0].state, 
                  country           : response[0].country, 
                  bank_name         : response[0].bank_name, 
                  account_name      : response[0].account_name,   
                  account_no        : response[0].account_no, 
                  otp               : response[0].otp,   
                  mem_fee           : response[0].mem_fee,   
                  coin_selling_id   : response[0].coin_selling_id,     
                  reg_date          : response[0].reg_date,
                  coin_strength     : response[0].coin_strength,
                  seamless_trade    : response[0].seamless_trade,
                  private_msg       : response[0].private_msg,
                  quest_status      : response[0].quest_status,
                  game_status       : response[0].game_status,
                  weekly_point      : response[0].weekly_point,
                  cashout_status    : response[0].cashout_status,
                  coin_trade_sender : response[0].coin_trade_sender,
                  coin_selling_amt  : response[0].coin_selling_amt,
                  card_main_status  : response[0].card_main_status,
                  card_main_coint   : response[0].card_main_coint,
                  card_main_kpi     : response[0].card_main_kpi,
          
                  coin_buying_status : response[0].coin_buying_status,
                  coin_selling_status: response[0].coin_selling_status,
              
                  
                  ///////////////// Wallet /////////////////////////// 
          
                  card_pin         : response[0].card_pin,
                  vcard_bal        : response[0].vcard_bal,
                  card_img         : response[0].card_img,
                  card_expires     : response[0].card_expires,
                  card_digit       : response[0].card_digit, 
                  game_won_bonus   : response[0].game_won_bonus, 
                  user_bank        : response[0].user_bank, 
                  card_lock        : response[0].card_lock,
                  ///////////////// Coin ///////////////////////////
                  
                  user_coin            : response[0].user_coin, 
                  golden_coin_earn     : response[0].golden_coin_earn, 
                  
                  ///////////////// Setting ///////////////////////////
          
                  launch_target       : response[0].launch_target,
                  launch_status       : response[0].launch_status,
                  credit_card_amt     : response[0].credit_card_amt,
                  card_main_fee       : response[0].card_main_fee,
                  bimothly1_pay_date  : response[0].bimothly1_pay_date,  
                  next_cashout_date   : response[0].next_cashout_date,  
                  tot_reg             : response[0].tot_reg,
                  golden_coin_value   : response[0].golden_coin_value,
                  card_maint_date     : response[0].card_maint_date,
                  public_msg          : response[0].public_msg, 
                  loan_rate           : response[0].loan_rate,
                  recharge_charges    : response[0].recharge_charges,
                  social_task         : response[0].social_task,
                  admin_pec           : response[0].admin_pec,
                  user_pec            : response[0].user_pec,
                  shares_min          : response[0].shares_min,
                  RamadaAwoof         : response[0].RamadaAwoof
                  
                     
              };
          
          
           
                
               
                  localStorage.setItem('authUser', response[0].username);
                  localStorage.setItem('authUserToken', response[0].passkey);
                  localStorage.setItem('loginSession',JSON.stringify(obj));
                  localStorage.setItem('authLogin', '1');
                    
                  

                  //setMessage(response[0].feedback);
                  //window.location.reload();
                  //setNavigate(true);
                 //setShow(false);

                  signIn();
              }
              else   
              {
                setActivateLoader(true);
                setIsLoading2(false);
                setMessage(response[0].feedback);
              }
            


       
      
      })
      .catch(error => { 
        setIsLoading2(false);
        setMessage('Oops! network error, please check your data and try again');
      });

}
}
 

 
const showHandler =()=>{
  setMessage('');
  setActivateLoader(false);
  setIsLoading2(false);
  

}




if(activateLoader)
{

      if(isLoading2)
      {


        return (     

              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );


      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }

}
else
{
       
        return (
        <div className="App">
        <div className="App-header">

        <div className="ContainerL">  

        <div  className='homeHr'>
        <img src="images/favicon.png"   className="imgLogoL"/> <br />
        <div className="TextHeadLogin">Account Login </div> 
        </div>






        <form   onSubmit={handleSubmit}>



        <div className='form-inputs'>
        <label   > <FaUserLock style={{fontSize: 25,color: '#ca1f7b'}} />   Username </label>
        <input
        id="phone"
        type="phone"
        name="phone"
        className="form-input-login"
        placeholder='Enter phone no'
        onChange={e=> setPhone(e.target.value)}
        />

        </div>


        <div className='form-inputs'>
        <label className='form-label'  style={{color: '#777777'}}>  <BsShieldLockFill style={{fontSize: 20,color: '#ca1f7b'}} /> Password</label>
   

        <input
        id="password"
        type="password"
        name="password"
        className="form-input-login"
        placeholder='Enter your password'
        onChange={e=> setPassword(e.target.value)}
        />
        </div>

       <div style={{display:'flex',flexDirection: 'row',justifyContent: 'space-between',marginTop:3,marginBottom:8, fontSize: 12,color:'#777777'}}>
       <div  onClick={() => {navigate('/account-setup')}}>Need an account?</div>
       <div onClick={() => {navigate('/Forgot-Password')}} >Forgot Password?</div>
       
       </div>



        <button type="submit"   className="btntab--active" style={{marginTop: '30px',width: '95%', marginLeft:-15 }}>
        Login
        </button>


        <button onClick={() => {navigate('/account-setup')}}   className="btntab--active" style={{marginTop: '30px',width: '95%', marginLeft:-15 }}>
         Create Account
        </button>

     
        </form>



        </div>
        </div>


        </div>
        )


}
    
  }
export default Login