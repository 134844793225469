
import React, { useState, useEffect,memo,useLayoutEffect } from 'react';   
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom';               
import {FaEnvelopeOpenText,FaWhatsapp } from "react-icons/fa"; 
import {AiFillCloseCircle} from "react-icons/ai"; 
import { IoCloseCircleSharp } from "react-icons/io5";
import {Naira , BaseAction,MainColor,AbuseEmail, SubscriptionUrl,WhatAppCommunity, CardFrontUrl,}  from '../../BaseUrl';  
import { AuthContext } from '../../components/context';  
import AccountSetup      from  '../Dashboard/AccountSetup'
import md5             from 'md5-hash'
import Loading         from '../Loading';
import Loading2        from '../Loading2';
import { Modal, Button } from 'react-bootstrap'; 
import { FaRegStar } from "react-icons/fa";
import './PayWithCreditCard.css'; 




const PayWithCreditCard = (props) => {
const {  reloadUserData,signOut,} = React.useContext(AuthContext); 
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false); 
const[message, setMessage] = useState('');  
const[messageSwitch, setMessageSwitch] = useState(false);
const[agentMessageSwitch, setAgentMessageSwitch] = useState(false);
const[loginData, setLoginData] = useState([]); 
const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 
const[payViaMyCard, setPayViaMyCard]   = useState(false); 
const navigate = useNavigate(); 
const LoginOptionUpdate =() => 
{

const value =  localStorage.getItem('loginSession');
const data = JSON.parse(value);  
setLoginData(data);




 
}

useEffect(() => {
      



setTimeout(async() => {

      LoginOptionUpdate();  
      

}, 5000);


                  
});

useLayoutEffect(() => {

      LoginOptionUpdate();
      
},[]); 


 



const NumberFomat = (val) =>{

    if( val > 0){
          var value =  val * 1; 
          var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return  Naira+result;  
    }else{
            var val = 0
            return  Naira+val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
    }
                                                     
     
    
}



const[data, setData] = useState({
    message: '',
    newCardPin: '', 
    check_textInputChange: false,
    secureTextEntry: true 
 })


 
 
 const UpdateMainFeeViaCreditCard =() => {
    
var vcard_bal     = parseInt(loginData.vcard_bal);
var card_main_fee = parseInt(loginData.card_main_fee);

     setActivateLoader(true);  
     setIsLoading2(true); 

if(data.newCardPin.length != 0){


         var userKey =  localStorage.getItem('authUserToken');
          const payloadData = {   
          userToken   :userKey, 
          cardpin     :md5(data.newCardPin),  
          };
    
          if(loginData.card_lock == 'locked'  )
          {
          setActivateLoader(false);  
          setIsLoading2(false); 


          setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
          setMessageSwitch(true)
          

    
            }
            else
            {
                if(loginData.card_lock == ''  )
                {
                setActivateLoader(false);  
                setIsLoading2(false); 
    
    
                setMessage('Credit card PIN is required for this transaction ');
                setMessageSwitch(true)
                }
                else
                {
                 
                          if(vcard_bal  >= card_main_fee)
                          {


                                        if(payloadData.cardpin  === loginData.card_pin )
                                    {
                        
                                            var headers = {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                            }
                                
                                            fetch(BaseAction+"MonthlyCreditCardMaintByCard",
                                            {
                                            method:'POST',
                                            header:headers,
                                            body: JSON.stringify(payloadData)
                                            })
                                            .then((response) => response.json())
                                            .then((response) => {
                                
                                                reloadUserData();
                                            
                                 
                                
                                            }); 
                                    }
                                    else
                                    {
                                        setActivateLoader(false);  
                                        setIsLoading2(false); 
                            
                            
                            
                                        setMessage('Incorrect credit card PIN passed.Please enter correct PIN');
                                        setMessageSwitch(true)
                                    }   
  
                          }
                          else
                          {
                              setActivateLoader(false);  
                              setIsLoading2(false); 
                  
                  
                  
                              setMessage('You have insufficient fund on your credit card. Please fund your credit card and continue');
                              setMessageSwitch(true)
                          }   
  
             
    
                }
    
            }
 }
 else
 {
 setActivateLoader(false);  
 setIsLoading2(false); 


 setMessage('Credit card PIN field is empty. Please enter a correct PIN');
 setMessageSwitch(true)
 


}
    }


    const FetchTopUpHistory = () => {

        setActivateLoader(true);  
        setIsLoading2(true);  
      
      
      
                var headers = {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
          
               fetch(BaseAction+"FetchTopUpHistory",
                    {
                    method:'GET',
                    header:headers,
            
                    })
                    .then((res) => res.json())
                    .then((response) => {
    
                  
    
                          if(response[0].success == "0")
                          {
                          
                                setActivateLoader(false);  
                                setIsLoading2(false);  
      
                                
                                setMessage(response[0].feedback);  
                                setMessageSwitch(true) 
                          }
                          else 
                          {
    
                            setTopUpAgentHistoryData(response); 
                            setAgentMessageSwitch(true);
                            
                            setActivateLoader(false);  
                            setIsLoading2(false);  
                          }
    
    
    
                    })  
                    .catch(error => {
                      
                        setActivateLoader(true);  
                        setIsLoading2(false); 
                    });
            
                
    
        //  Keyboard.dismiss();
     
    
    
        
    }


    const CreditCradRequest=()=>{
        
        var userToken =  localStorage.getItem('authUserToken');
   navigate('./payment-link?fullname='+loginData.fullname+'&username='+loginData.username+'&email='+loginData.email+'&card_maint=card_maint&userToken='+userToken)
  //card_maint_fee='+loginData.card_main_fee+'&
}


 
 
      


    const WhatAppCommunityHandler=()=>{
        
    //window.location.href=WhatAppCommunity;https://whatsapp.com/channel/0029VaEnllS5K3zRmWG1ZJ1A
    window.location.href=WhatAppCommunity;
    }


    const  ReLoadHanduler =() =>{

 
        setActivateLoader(false);  
        setIsLoading2(false);
      
     
  }


 
  const starDisplay =(val)=>{



    if(val == 4){
    return <span> <FaRegStar color={MainColor} /> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
    }else if(val == 3){
    return <span><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
  
    }else if(val == 2){
    return <span> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
  
    }else if(val == 1){
    return <span> <FaRegStar color={MainColor} /></span>
  
    }else if(val == 0){
    return <span></span>
  
    }
  

}

  if(activateLoader)
  {
  
  
          if(isLoading2)
          {
  
                return(
                    <Modal     show={true}   > 
                       <Loading  />
                    </Modal>
         
                )
                
          }
          else 
          {
                return(
                    <Modal  show={true}   > 
                    <Loading2  ReLoadHanduler={ReLoadHanduler}  />
                    </Modal>
                )
  
          }  
  
  
  
      
  }
  else
  {
    

 if(props.CheckCard == '' || props.CheckCard == null)
{
    <AccountSetup  />

    return(
        <div> 
        <AccountSetup  />
        </div>

    )
}
else
{
           return (
            
            
            <div className="App"> 
            <div className="App-header"> 
            <div className="Container1"> 


            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginBottom:50}}>

            <div   style={{marginTop: 20,alignItems:'center',}} > 
            <div  className='buttonGreen'  onClick={() => setPayViaMyCard(false)}   style={{fontSize:11,}}    >
            <div style={{color:'white'}}>PAY VIA MY BANK</div>
            </div>   
            </div>


            <div   style={{marginTop: 20,alignItems:'center',}} > 
            <div   className='buttonGreen'   onClick={() => setPayViaMyCard(true)}  style={{fontSize:11,backgroundColor:MainColor}}     >
            <div style={{color:'white'}}>PAY VIA MY CREDIT CARD</div>
            </div>   
            </div>





            </div>


            <div onClick={() => FetchTopUpHistory()}  style={{marginTop: 20,marginBottom: 20,alignItems:'center',}} > 
                <div   className='buttonGreen'    style={{fontSize:11,backgroundColor:'#777777'}}     >
                    <div style={{color:'white'}}>FUND CARD VIA TOP-AGENT</div>
                </div>                   
            </div> 
 


        {
            payViaMyCard?

            <div>



                <div> 


                <div style={{alignItems:'center',marginBottom:70}}> 
                <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:14}}>   MONTHLY </span>  </div> 
                <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:14}}> ELECTRONIC CHANNEL & MAINT LEVY  </span>  </div>
               
                <div style={{fontWeight:'bold',fontSize:25,color:'#000000',textDecorationLine:'underline'}}> {NumberFomat(loginData.card_main_fee)}</div>

                <div style={{justifyContent:'center',marginTop:50,}}>
                <img   src={CardFrontUrl+loginData.card_img}  style={{width: 250, height: 170,borderRadius:10,alignItems:'center'}} />
                </div> 


                </div>           




            



                </div>

                <div style={{fontSize:14,color:'black', marginBottom:5,marginTop:5}}>
                Note:  Hi {loginData.fullname} please pay your electronic channel / maintenance levy, failure to do so will lead to 
                forfeiture of your profit sharing dividend. You can also contact our registered approved top-up agent to assist you with payment.

                </div>



                <div style={{fontSize:14,color:'#000000',marginBottom:5,marginTop:5}}>
                Continue to push your dividend to your credit card after maintenance levy paid

                </div>


                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around'}}>

                <div   style={{marginTop: 20,alignItems:'center',}} > 
                <div  onClick={() => CreditCradRequest()}  
                style={{fontSize:11,}}  className='buttonGreen'   >
                <div style={{color:'white'}}>PAY VIA MY BANK</div>
                </div>   
                </div>


            

                </div>


              <br /><br />

            </div>
            :     
            
                // VIA CREDIT CARD onClick={()=> WhatAppCommunityHandler()}
                <div>



                <div> 


                <div style={{alignItems:'center',marginBottom:10}}> 

                <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:14}}>   MONTHLY </span>  </div> 
                <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:14}}>   ELECTRONIC CHANNEL & MAINT LEVY  </span>  </div> 
                <div style={{fontWeight:'bold',fontSize:25,color:'#000000',textDecorationLine:'underline'}}> {NumberFomat(loginData.card_main_fee)}</div>

                <div style={{justifyContent:'center',marginTop:5,}}>
                <img   src={CardFrontUrl+loginData.card_img}  style={{width: 250, height: 170,borderRadius:10,alignItems:'center'}} />
                </div> 


                </div>           




                <div style={{alignItems:'flex-start',width:'100%' }}>    

                <div style={{marginTop:2,color:'#000000',}}>
                <div  style={{textAlign:'left',}}>Virtual credit card to debit</div>
                <div style={{textAlign:'left',color:'#000000',fontSize:18,fontWeight:'bold'}}>{loginData.card_digit}</div> 
                </div>


                <div style={{marginBottom:5,textDecorationLine:'underline',color:'#000000'}}>
                <div  style={{textAlign:'left'}}>Credit Card Balance</div>
                <div  style={{textAlign:'left',fontSize:18,fontWeight:'bold'}}>{NumberFomat(loginData.vcard_bal)}</div>                             
                </div>


                <div style={{marginBottom:50}}>
                                            
                <div style={{alignItems:'flex-start',color:'#000000',}}>
                    <div style={{textAlign:'left',fontSize:16}}>
                    Enter credit card PIN 
                    </div>                           
                </div>


                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,}}>

                

                    <div style={{width:150,textAlign:'left',}}>
                        <input   
                        
                        onChange={e=> setData({ ...data,  newCardPin:e.target.value})}
                        value={data.newCardPin}  
                        maxLength={4}
                        secureTextEntry={data.secureTextEntry ? true : false}  
                        className='input'
                        style={{width: '100%',fontSize:18,color:'black',textAlign:'center'}}

                        />                               
                    </div>


                </div>

                
            </div>



                </div> 



                </div>

                <div style={{fontSize:14,color:'black', marginBottom:5,marginTop:5}}>
                Note:  Hi {loginData.fullname} please pay your electronic channel / maintenance levy, failure to do so will lead to 
                forfeiture of your profit sharing dividend. You can also contact our registered approved top-up agent to assist you with payment.

                </div>

                <div style={{fontSize:14,color:'#000000', marginBottom:5,marginTop:5}}>
                Continue to push your dividend to your credit card after maintenance levy paid

                </div>


                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around'}}>

            

                <div   style={{marginTop: 20,alignItems:'center',}} > 
                <div className='button2' onClick={UpdateMainFeeViaCreditCard}  style={{fontSize:11,backgroundColor:MainColor,}}     >
                <div style={{color:'white'}}>PAY WITH CREDIT CARD</div>
                </div>   
                </div>

                </div>

                <br /><br />

        </div>

        }


            
           
            

                     {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?
                  
                        
                        <Modal  show={true}    > 
                        <div style={{flex:1,backgroundColor: MainColor}} onClick={() => setMessageSwitch(false)}   >
                        <div>
                  
                              <AiFillCloseCircle    size={25}  color={'white'} />                         
            
                              <div   style={{marginTop: 20,padding:20}} >
                               
                                    <div  style={{fontSize: 14,color:'white'}} >{message}</div>
                              </div>

                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null

                        }

                        {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                        agentMessageSwitch?


                        <Modal  show={true}  >
                                <div  onClick={() => setAgentMessageSwitch(false)}  style={{padding: 20}}  > 
                                <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                                </div>
                                <div>
                                <div style={{fontSize:16,textAlign:'center',fontWeight:'bold'}}>  CREDIT CARD TOP-UP AGENT  </div>
                                <div style={{fontSize:12,textAlign:'center',}}>Our agent can help you top up your virtual credit card if you are having
                                difficulties using with your bank account  </div>
                                </div><br />
    
                                <div>
    
                            
            
                                {
                                topUpAgentHistoryData.map((item) => (
                                    <div>
                    
    
                                    <div   style={{textAlign:'left',backgroundColor:'#f2f2f2',paddingLeft:20,paddingBottom:20,fontSize:12}}>
            
                                    <Link to={'https://api.whatsapp.com/send?phone=234'+item.phone} style={{textDecoration:'none'}}>
                                    <div style={{flexDirection:'row',width: '100%'}}>  
                                    <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                   Agent Status: ({
                                        item.status== 'busy'?
                                        <span style={{color:MainColor,}}>Busy can't take a deal</span>
                                        :
                                        item.status== 'close'?
                                        <span style={{color:MainColor,}}>Not open for biz</span>
                                        :
                                        item.status== 'open'?
                                        <span style={{color:MainColor,}}>Open to take a deal</span>
                                        :
                                       null
                                    }) </div>
                                </div>


                                <div style={{flexDirection:'row',width: '100%'}}>  
                                    <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                    ADENT ID:({   item.agent_code  }) 
                                    </div>
                                </div>
                        
                                            <div style={{flexDirection:'column',width: '99%', }}>
                                                    
                        
                                            <div    style={{flexDirection:'column',width: '100%'}}>
                                            <div  style={{color:'black',}}>Agent name:{item.name}</div>    
                                            <div  style={{color:'black',}}>Phone number:0{item.phone} </div>    
                                            <div  style={{color:'black',}}>whatsapp Only  <FaWhatsapp size={20} style={{ color: MainColor,textAlign:'center'  }}  />  </div>    
                                            <div  style={{color:'black',}}>Agent Rate:{starDisplay(item.rate)} </div> 
                                            </div> 
                        
                        
                        
                        
                        
                        
                                            </div>                        
                                    </Link>
                        
                                </div> 
                                        
                                    </div> 
    
                                    ))
                                }  
                                
                                
                            
                                </div>
                    
                        </Modal>
                        :  
                        null
            
                        } 
            </div>
            </div>
            </div>
        ) 
}


    }
}

 
export default memo(PayWithCreditCard); 
 