import React, { useState, useEffect ,useRef} from "react";
import { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'  
import {BaseAction, BasePlaceholder,ProfileImgLink,MainColor,TopTabColor,CardFrontUrl} from '../../../BaseUrl'; 
import { BsDatabase,BsBank2 } from "react-icons/bs";     
import { IoIosPhonePortrait } from "react-icons/io"; 
import { IoCloseCircleOutline,IoCloseCircle  } from "react-icons/io5"; 
import { FcConferenceCall } from "react-icons/fc";
import { PiHandCoinsFill ,PiGenderIntersexThin } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";
import { FaGlobeAmericas,FaRegCheckSquare,FaEnvelopeOpenText,FaLock,FaUserTie} from "react-icons/fa";
import {FaSchoolLock ,FaRegEyeSlash,FaRegEye  ,FaCreditCard ,FaAngleRight ,FaRegBuilding,FaCalendarCheck ,FaCity ,FaRegFile ,FaUser , FaGears,FaGamepad,FaToggleOn,FaToggleOff  } from "react-icons/fa6";  
import { AuthContext }    from '../../../components/context';
import   Loading          from "../../Loading";
import   Loading2         from "../../Loading2"; 
import {FloatingLabel,Card, Table, Form,Button,Modal} from 'react-bootstrap';
import { GiTeacher }      from "react-icons/gi";
import { RiMapPinFill }   from "react-icons/ri";
import { MdOutlineMarkEmailUnread,MdMarkEmailUnread  } from "react-icons/md";
import md5                from 'md5-hash';
import './Profile.css';




const Profile = (props) => {    
const { signOut,reloadUserData,reloadUserData_2} = React.useContext(AuthContext);
const navigate = useNavigate();   
const [loginData, setLoginData] = useState([]);  
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false); 
const[profilePhoto, setProfilePhoto] = useState('');
const[message, setMessage] = useState('');
const[profileModalSwitch, setProfileModalSwitch] = useState(false);
const[messageSwitch, setMessageSwitch] = useState(false);
const[modalCreditCardPinUpdate, setModalCreditCardPinUpdate] = useState(false);
const[modalBankAccountUpdate, setModalBankAccountUpdate] = useState(false);
const[accountModalPassword, setAccountModalPassword] = useState(false);
const selectFile =useRef();
const[data, setData] = useState({
  newCardPin: '',
  confirmCardPin: '',
  otpCode: '',
  message: '',
  password2: '',
  password: '',
  bankName: '',
  accountName: '',  
  accountNumber: '', 
  check_inputChange: false,
  secureTextEntry: true,
  passwordText: 'password',
})
 
     
     
  
      
const LoginOptionUpdate =() => 
{

const value =  localStorage.getItem('loginSession');
const data = JSON.parse(value);  

setLoginData(data);
 
  
}

useEffect(() => { 

setTimeout(() => { 
      LoginOptionUpdate();   
}, 5000);


                  
});

useEffect(() => { 
  LoginOptionUpdate(); 
},[]); 

const updateSecureTextEntry = (val) => {

  if(val == 'hide'){

    setData({  ...data, 
      secureTextEntry: false,
      passwordText:'text'
      });

  }else if(val == 'open'){

    setData({  ...data, 
      secureTextEntry: val,
      passwordText:'password'
      });

  }

  
  }

 
 const  logMeOut =() => {

    signOut()
    navigate('/')
    }

    

  //CREDIT CARD PIN UPDATE
  const bankAccountUpdate = () => {
    var authUserToken =  localStorage.getItem('authUserToken');   
    setActivateLoader(true);  
    setIsLoading2(true); 
    
          const payloadData = {   
          userToken    :authUserToken, 
          bankName     :data.bankName,
          accountName  : data.accountName,  
          accountNumber: data.accountNumber,  
          otpCode      : data.otpCode,  
          };
    
   
                if(data.bankName.length == 0 || data.accountName.length == 0 || data.accountNumber.length == 0 || data.otpCode.length == 0)
                {
                setActivateLoader(false);  
                setIsLoading2(false); 
    
    
                setMessage('All form fields are required. Thanks');
                setMessageSwitch(true)
                }
                else
                {
                        
                                var headers = {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                }
                    
                                fetch(BaseAction+"bankAccountUpdate",
                                {
                                method:'POST',
                                header:headers,
                                body: JSON.stringify(payloadData)
                                })
                                .then((response) => response.json())
                                .then((response) => {
                    
                                setActivateLoader(false);  
                                setIsLoading2(false);  
                    
                    
                                if(response[0].success == 1){
                    
                                setMessage(response[0].feedback);
                                setMessageSwitch(true) 
                                setModalBankAccountUpdate(false)
                    
                                }else{
                    
                                setMessage(response[0].feedback);
                                setMessageSwitch(true)
                                }
                    
                                 }); 
                          
     
    
                }
    

        
}



//CREDIT CARD PIN UPDATE
  const accountPasswordUpdate = () => {
    var authUserToken =  localStorage.getItem('authUserToken');   
    setActivateLoader(true);  
    setIsLoading2(true); 
    

    
          const payloadData = {   
          userToken:authUserToken, 
          password :md5(data.password),
          password2: md5(data.password2),  
          otpCode  : data.otpCode,  
          };
    
   
                if(data.password.length == 0 || data.password2.length == 0 || data.otpCode.length == 0)
                {
                setActivateLoader(false);  
                setIsLoading2(false); 
    
    
                setMessage('All form fields are required. Please enter numeric pin. Thanks');
                setMessageSwitch(true)
                }
                else
                {
                 
                      if(data.password  === data.password2 )
                      {
          
                            var headers = {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                            }
                
                            fetch(BaseAction+"AccountPasswordUpdate",
                            {
                            method:'POST',
                            header:headers,
                            body: JSON.stringify(payloadData)
                            })
                            .then((response) => response.json())
                            .then((response) => {
                
                            setActivateLoader(false);  
                            setIsLoading2(false);  
                
                
                            if(response[0].success == 1){
                
                            setMessage(response[0].feedback);
                            setMessageSwitch(true) 
                            setAccountModalPassword(false)
                
                            }else{
                
                            setMessage(response[0].feedback);
                            setMessageSwitch(true)
                            }
                
                              }); 
                      }
                      else
                      {
                          setActivateLoader(false);  
                          setIsLoading2(false); 
              
              
              
                          setMessage('Your new password do not match');
                          setMessageSwitch(true)
                      }   
  
                }
    

        
}
 

  
const UpdateCardPin =() => {
  var authUserToken =  localStorage.getItem('authUserToken');
  setActivateLoader(true);  
  setIsLoading2(true); 
  
        const payloadData = {   
        userToken  :authUserToken, 
        cardpin    :md5(data.newCardPin),
        cardpin2   :md5(data.confirmCardPin),  
        otpCode    :data.otpCode,  
        };
  
 
              if(data.newCardPin.length == 0 || data.confirmCardPin.length == 0 || data.otpCode.length == 0)
              {
              setActivateLoader(false);  
              setIsLoading2(false); 
  
  
              setMessage('All form fields are required. Please enter numeric pin. Thanks');
              setMessageSwitch(true)
              }
              else
              {
                 
                        if(data.newCardPin  === data.confirmCardPin )
                        {
            
                              var headers = {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json'
                              }
                  
                              fetch(BaseAction+"CardPinUpdate",
                              {
                              method:'POST',
                              header:headers,
                              body: JSON.stringify(payloadData)
                              })
                              .then((response) => response.json())
                              .then((response) => {
                  
                              setActivateLoader(false);  
                              setIsLoading2(false);  
                  
                  
                              if(response[0].success == 1){
                  
                              setMessage(response[0].feedback);
                              setMessageSwitch(true) 
                              setModalCreditCardPinUpdate(false)
                  
                              }else{
                  
                              setMessage(response[0].feedback);
                              setMessageSwitch(true)
                              }
                  
                               }); 
                        }
                        else
                        {
                            setActivateLoader(false);  
                            setIsLoading2(false); 
                
                
                
                            setMessage('Your new credit card PIN  do not match');
                            setMessageSwitch(true)
                        }   
    
  
              }
  
  
  
  
  }


const SendOTPCode = () => {

  setActivateLoader(true);  
  setIsLoading2(true); 
  
  var authUserToken =  localStorage.getItem('authUserToken');
  
              var headers = {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
              }
              fetch(BaseAction+"sendOTPCode&userToken="+authUserToken,
              {
              method:'GET',
              header:headers
              })
              .then((response) => response.json())
              .then((response) =>{
                    
              setActivateLoader(false);  
              setIsLoading2(false); 
                    
                    if(response[0].success == 'ok'){
                      
                    setMessage(response[0].feedback); 
                    setMessageSwitch(true);
                    }else{
  
                    setMessage(response[0].feedback);
                    setMessageSwitch(true);
                    }
                    
              })
              .catch(error => {
                setActivateLoader(true);  
                setIsLoading2(false); 
                });

        
  
        
  }

  


const UpdatePhoto = () =>{
 
         setActivateLoader(true);  
         setIsLoading2(true);  
  
  if( selectFile.current.files.length != 0 ){

        let formDataPost = new FormData();    
    
        formDataPost.append('photo_profile',selectFile.current.files[0]);
        formDataPost.append('user',loginData.username);
        var authUserToken =  localStorage.getItem('authUserToken');
 
        var headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          } 
      
           fetch(BaseAction+"profilePhotoUpdate&=Token"+authUserToken,
           {
            method: "POST", 
            header: headers, 
            body:formDataPost

          })
          .then((response) => response.json())
            .then((response) => {
      
                   reloadUserData(); 
                   setActivateLoader(false);  
                   setIsLoading2(false);  

                    setMessage(response[0].feedback)
                    setMessageSwitch(true)
 
            }) 
            .catch(error => {
            
              setActivateLoader(true);  
              setIsLoading2(false);  
            });
 
      }
      else
      {

        
        setMessage('Please choose an image');

      }

}

 
   
////////// SELL GOLDEN COIN  
const  SellGoldenCoin = () =>{
 

  setActivateLoader(true);  
  setIsLoading2(true); 
  var authUserToken =  localStorage.getItem('authUserToken');
  var headers = {
   'Accept': 'application/json',
   'Content-type': 'application/json'
   }

   fetch(BaseAction+"SellGoldenCoin&userToken="+authUserToken,
   {
   method:'GET',
   header:headers, 
   })
   .then((response) => response.json())
   .then((response) => { 

               
          setMessage(response[0].feedback);
          setMessageSwitch(true)
            
          setActivateLoader(false);  
          setIsLoading2(false); 
            
           
        
   }) 
   .catch(error => {
        setActivateLoader(true);  
        setIsLoading2(false);    
     });



}   

///////// BUY GOLDEN COIN  
const  BuyGoldenCoin = () =>{
  var authUserToken =  localStorage.getItem('authUserToken');

  setActivateLoader(true);  
  setIsLoading2(true); 

  var headers = {
   'Accept': 'application/json',
   'Content-type': 'application/json'
   }

   fetch(BaseAction+"BuyGoldenCoin&userToken="+authUserToken,
   {
   method:'GET',
   header:headers, 
   })
   .then((response) => response.json())
   .then((response) => { 

    setMessage(response[0].feedback);
    setMessageSwitch(true)
      
    setActivateLoader(false);  
    setIsLoading2(false); 
     
        
   }) 
   .catch(error => {
        setActivateLoader(true);  
        setIsLoading2(false);    
     });



}   

      const showHandler =()=>{

        setActivateLoader(false);
        setMessage('')
      }
      
 


 if(activateLoader)
{ 


    if(isLoading2)
    {


      return (     

            <div> 
              <Loading  /> 
            </div>
          );


    }
    else
    {
        return (         
            <div> 
                <Loading2   Show={showHandler} >
                    <small style={{color: 'black',fontSize:13}}>{message} </small>
                </Loading2> 
            </div>
        );
    }


}
else
{ 
 
                return (
                  <div  style={{width: '100%', height: '100%',backgroundColor:'#f2f2f2'}}> 
   
                
             
              
             
                     <div className="profileCon">
             
                         <div style={{  alignItems: 'center',    }}>
                         <div style={{fontSize:14,color: 'white', }}>My Profile</div>
                          
                                   <div onClick={()=> setProfileModalSwitch(true)}  style={{alignItems: 'center',marginBottom: 5,}}>
                                   <img  src={ProfileImgLink+loginData.photo}  style={{height:70,width:70}}  />
             
                                   </div>
              
             
             
                       </div>
             
                    </div>
             
             
                 
                   <div style={{paddingLeft: 20,paddingRight:20,paddingBottom:100}}>
             
             
                   <div className="card elevation lineFonts"  style={{marginBottom:50,}} >
             
                        <div  className="lineFonts"  style={{textAlign:'center',color:'black'}}>
                       <FaGears    size={20} color={'black'}      />  TRADING SETTINGS   
                        </div>
             
       
             
                         <div  className="ContainerBox" >
             
                         <div  className="lineFonts"  style={{paddingLeft:5}}>
                         <PiHandCoinsFill     size={20} color={MainColor}      />  Buy Shares(SH)
                         </div>
             
                         <div   onClick={() => BuyGoldenCoin()}>
                           <div style={{flexDirection:'column',paddingRight:40}}>
                             {loginData.coin_buying_status == 'open'? 
                                   <div>
                                   <FaToggleOn     size={30} color={'green'}         />  
                                   <div style={{fontSize:11}}> ON </div>  
                                   </div>
                             : 
                                   <div>
                                   <FaToggleOff      size={30} color={'#777777'}         />  
                                   <div style={{fontSize:11}}> OFF </div>  
                                   </div> 
                               }  
                           </div>
                         </div>
             
             
                         </div>
             
                         <div  className="ContainerBox" >
             
                         <div  style={{paddingLeft:5}}>
                         <PiHandCoinsFill    size={20} color={MainColor}      /> Sell Shares(SH)    
                         </div>
             
                         <div   onClick={() => SellGoldenCoin()}>
                           <div style={{flexDirection:'column',paddingRight:40}}>
                             {loginData.coin_selling_status == 'open'? 
                                 <div>
                                 <FaToggleOn      size={30} color={'green'}         />  
                                 <div style={{fontSize:11}}> ON </div>  
                                 </div>
                            : 
                                 <div>
                                 <FaToggleOff     size={30} color={'#777777'}         />  
                                 <div style={{fontSize:11}}> OFF </div>  
                                 </div> 
                             }
                              
                           </div>
                         </div>
             
             
                         </div>
             
                   </div>
             
             
                   <div className="card elevation "  style={{marginBottom:50,}} >
             
                     <div className="lineFonts" style={{textAlign:'center',color:'black'}}>
                     <FaRegFile     size={20} color={'black'}      />  PERSONAL DATE 
                     </div>
             
             
                       <div  className="ContainerBox" >
             
                           <div className="lineFonts" style={{paddingLeft:5}}>
             
                             <div style={{color:'black'}}> 
                             <FaUser     size={20} color={MainColor}      />  {loginData.fullname}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',}}> Fullname </div>
                           </div>
              
                       </div>
             
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div  style={{color:'black'}}> 
                             <IoIosPhonePortrait     size={20} color={MainColor}      />  {loginData.username}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}>Phone  number </div>
                           </div>
              
                       </div>
             
             
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" >
             
                             <div className="lineFonts"  style={{color:'black'}}> 
                             <BsDatabase     size={20} color={MainColor}      />  {loginData.level_1}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}>Trading Code </div>
                           </div>
              
                       </div>
             
                       <div className="ContainerBox" >
             
                           <div  className="lineFonts"  style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <GiTeacher    size={20} color={MainColor}      />  {loginData.user_coach}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}>Trading Coach </div>
                           </div>
              
                       </div>
             
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <RiMapPinFill     size={20} color={MainColor}      />  {loginData.address}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}>Address </div>
                           </div>
              
                       </div>
             
             
                     
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <MdOutlineMarkEmailUnread    size={20} color={MainColor}      />  {loginData.email}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Email </div>
                           </div>
              
                       </div>
             
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <PiGenderIntersexThin     size={20} color={MainColor}      />  {loginData.gender}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Gender </div>
                           </div>
              
                       </div>
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <FaCity      size={20} color={MainColor}      />  {loginData.city}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> City </div>
                           </div>
              
                       </div>
             
             
             
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <FaCity      size={20} color={MainColor}      />  {loginData.state}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> State </div>
                           </div>
              
                       </div>
             
             
                       
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <FaGlobeAmericas     size={20} color={MainColor}      />  {loginData.country}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Country </div>
                           </div>
              
                       </div>
             
                       
                       <div  className="ContainerBox" >
             
                           <div  className="lineFonts" style={{paddingLeft:5}}>
             
                             <div className="lineFonts" style={{color:'black'}}> 
                             <FaCalendarCheck    size={20} color={MainColor}      />  {loginData.reg_date}
                             </div>    
                           
                             <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Registration Date </div>
                           </div>
              
                       </div>
             
             
             
             
             
             
             
             
              
             
             
                   </div>
                    
             
                   <div className="card elevation lineFonts"  style={{marginBottom:50,}} >
             
                   <div className="lineFonts"  style={{textAlign:'center',color:'black'}}>
                   <BsBank2     size={20} color={'black'}      /> ACCOUNT DETAILS 
                   </div>
             
              
             
             
                     <div  className="ContainerBox" >
             
                         <div  className="lineFonts" style={{paddingLeft:5}}>
             
                           <div className="lineFonts" style={{color:'black'}}> 
                           <FaRegBuilding    size={20} color={MainColor}      />  {loginData.bank_name}
                           </div>    
                         
                           <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Bank Name </div>
                         </div>
             
             
                         <div   onClick={() => setModalBankAccountUpdate(true)}>
                         <div style={{flexDirection:'column',paddingRight:40}}> 
                           <FaAngleRight      size={30} color={'#777777'}         />     
                         </div>
                       </div>
             
             
                     </div>
             
             
             
                     <div  className="ContainerBox" >
             
                         <div  className="lineFonts" style={{paddingLeft:5}}>
             
                           <div className="lineFonts" style={{color:'black'}}> 
                           <FaUser   size={20} color={MainColor}      />  {loginData.account_name}
                           </div>    
                         
                           <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Account Name </div>
                         </div>
             
             
                         <div   onClick={() => setModalBankAccountUpdate(true)}>
                         <div style={{flexDirection:'column',paddingRight:40}}> 
                           <FaAngleRight      size={30} color={'#777777'}         />     
                         </div>
                       </div>
             
             
                     </div>
             
              
             
                     <div  className="ContainerBox" >
             
                         <div  className="lineFonts" style={{paddingLeft:5}}>
             
                           <div className="lineFonts" style={{color:'black'}}> 
                           <FaRegCheckSquare      size={20} color={MainColor}      />  {loginData.account_no}
                           </div>    
                         
                           <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Account Number </div>
                         </div>
             
             
                         <div   onClick={() => setModalBankAccountUpdate(true)}>
                         <div style={{flexDirection:'column',paddingRight:40}}> 
                           <FaAngleRight      size={30} color={'#777777'}         />     
                         </div>
                       </div>
             
             
                     </div>
             
             
                
             
             
                   </div>
             
             
                   <div className="card elevation lineFonts"  style={{marginBottom:50,}} >
             
                       <div  className="lineFonts"  style={{textAlign:'center',color:'black'}}>
                       <FaSchoolLock     size={20} color={'#777777'}      /> SECURITY SETTINGS 
                       </div>
             
                 
             
             
                         <div  className="ContainerBox" >
             
                             <div  className="lineFonts" style={{paddingLeft:5}}>
                             <div   onClick={() => setAccountModalPassword(true)}>
                               <div className="lineFonts" style={{color:'black'}}> 
                               <FaRegEyeSlash    size={20} color={MainColor}      />  ************
                               </div>    
                             
                               <div className="lineFonts" style={{color:'#777777',marginLeft:20}}> Change Login Password </div>
                             
                             </div>
                             </div>
             
                             <div   onClick={() => setAccountModalPassword(true)}>
                             <div style={{flexDirection:'column',paddingRight:40}}> 
                               <FaAngleRight      size={30} color={'#777777'}         />     
                             </div>
                           </div>
             
             
                         </div>
             
             
             
             
                         <div  className="ContainerBox" >
             
                             <div  className="lineFonts" style={{paddingLeft:5}}>
                             <div   onClick={() => setModalCreditCardPinUpdate(true)}>
                               <div className="lineFonts" style={{color:'black'}}> 
                               <FaCreditCard     size={20} color={MainColor}      />  ************
                               </div>    
                             
                               <div className="lineFonts" style={{color:'#777777',marginLeft:20}}>Change Virtual Credit Card Pin </div>
                               </div>
                             </div>
             
             
                             <div   onClick={() => setModalCreditCardPinUpdate(true)}>
                             <div style={{flexDirection:'column',paddingRight:40}}> 
                               <FaAngleRight      size={30} color={'#777777'}         />     
                             </div>
                           </div>
             
             
                         </div>
              
             
                   </div>
     
                   
                   <div className="card elevation lineFonts"  style={{marginBottom:50,}} >
             
                   <div  className="lineFonts"  style={{textAlign:'center',color:'black'}}>
                  <FcConferenceCall    size={20} color={'black'}      />  CONTACT OUR TEAM 
                   </div>
        
   

                    <div style={{textAlign:'left',fontSize:14}}>
                        <div  style={{paddingLeft:5,}}>
                        <p  style={{textDecoration:'underline',color:'#777777'}}>   Make Inquiries  </p>
                        <p style={{color:'#777777'}}>  <MdMarkEmailUnread  size={20} color={'black'}  />   Inquiry@rsbdigitalshares.com.ng</p>
                        </div>  

                        <div  style={{paddingLeft:5,}}>
                        <p  style={{textDecoration:'underline',color:'#777777'}}>Report Top-Up Agent  </p>
                        <p style={{color:'#777777'}}> <MdMarkEmailUnread  size={20} color={'black'}  />   report_agent@rsbdigitalshares.com.ng</p>
                        </div>  


                        <div   style={{paddingLeft:5,}}>
                        <p  style={{textDecoration:'underline',color:'#777777'}}>Block Credit Card </p>
                        <p style={{color:'#777777'}}> <MdMarkEmailUnread  size={20} color={'black'}  />   report_card@rsbdigitalshares.com.ng</p>
                        </div>  


                    </div>         
                     
        
                  </div>






                   <div  onClick={()=>logMeOut()}>
                     <div className="card elevation lineFonts"  style={{marginBottom:50,}} >
             
                     <div  className="ContainerBox" >
                       <div  className="lineFonts" style={{paddingLeft:5}}>
                           <div style={{alignItems: 'center',}}>
             
                           <FiLogOut  size={24}  color={TopTabColor}   style={{  textAlign: 'center'}}     />
                           <div>Log Out</div>          
             
                           </div> 
                       </div>
             
             
                   
                     <div style={{flexDirection:'column',paddingRight:40}}> 
                     <FaAngleRight      size={30} color={'#777777'}         />     
                     </div>
                   
             
             
                     </div>
             
             
             
                     </div>
                   </div>
             
             
             
             
                   </div>
                 
             
                  
             
             
              
                               {///SINGLR BUTTON MODAL (PERSONAL MESSAGE) 1111
                                 messageSwitch?
                         
                               
                                 <Modal  show={true}     >
                                 <div >
               
                                             <div   onClick={() => setMessageSwitch(false)}  >
                                             <IoCloseCircleOutline  size={30}  color="black" style={{margin:20}}/>
                                             </div>  
               
                                 <div >
                                 
                                 <FaEnvelopeOpenText   size={20}  color={MainColor} /> 
                                       <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>  NOTIFICATION</div>
                                       
               
               
                                       <div   style={{marginTop: 20}} >
                                       
                                             <div  style={{fontSize: 14}} >{message}</div>
                                       </div>
                                       
                                       </div>
                                       </div>
                           
                                 </Modal>
                                 :  
                                 null
               
                                 }
                    
              
                                 {/// CREDIT CARD UPDATE setProfileModalSwitch
                                  profileModalSwitch?
             
                                 <Modal  show={true}    >
                                 <div style={{padding:'10%',}} >
             

                                 <div onClick={()=>setProfileModalSwitch(false)}>
                                 <IoCloseCircle size={25} style={{alignItems:'flex-end',marginBottom:10}} />
                                 </div>
                                 
                                 <Form className="mb-5"> 
       
                                 <Form.Group className="mb-2" controlId="formBasicPassword">
       
                                 <Form.Control type="file" placeholder="Account Name" ref={selectFile} />
                                 </Form.Group>
       
       
       
                                 <div onClick={UpdatePhoto}   className="openHistory" type="submit">
                                 Update Photo
                                 </div>
       
       
                                 </Form> 
       
                                 
                                 </div>
                                 </Modal>
                                 :
                                 null 
             
                               }
             
             
             
                               {/// ACCOUNT PASSWORD MODIFICATIONS 
                                modalCreditCardPinUpdate?
             
                                 <Modal  show={true}    >
                                  
                                 <div style={{paddingLeft:'10%',paddingBottom:20}}>
             
                                 
                                 <div >
                                 
                                 
             
                                       <div style={{ color: 'black', fontSize: 20,marginTop: 5,marginBottom: 20, textAlign: 'center',}}>
                                       CREDIT CARD PIN CHANGE
                                       </div>
                           
                                       <div   style={{marginTop: 5,marginBottom:10,alignItems:'center'}} >
                                       <img style={{width:300,height:200,alignItems:'center',borderRadius:10}}  src={CardFrontUrl+loginData.card_img} />
                                       </div>
                                 
                                       <div>
                                           <div style={{marginTop:50,alignItems:'flex-start'}}>
                                           <div style={{textAlign:'left'}}>
                                           <FaLock color={MainColor} size={20} /> Create new credit card PIN
                                           </div>                           
                                           </div>
             
             
                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,marginTop:10,}}>
             
             
             
                                           <div style={{width:'80%',textAlign:'left',}}>
                                           <Form.Control   
                                           onChange={e => setData({ ...data, newCardPin:e.target.value})}
                                           value={data.newCardPin} 
                                           type={data.passwordText}
                                          style={{width: 150,fontSize:15,color:'black',textAlign:'left'}} 
                                          maxLength={4}
                                           />                               
                                           </div>
             
             
             
                                           </div>
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
                                           <div style={{alignItems:'flex-start'}}>
                                                 <div style={{textAlign:'left'}}>
                                                 <FaLock color={MainColor} size={20} /> Confirm new credit card PIN
                                                 </div>                           
                                           </div>
             



                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,}}>
             
                                           
             
                                               <div style={{width:'90%',textAlign:'left',}}>
                                                     <Form.Control   
                                                     onChange={e => setData({ ...data, confirmCardPin:e.target.value})}
                                                     value={data.confirmCardPin}   
                                                     type={data.passwordText}
                                                     style={{width:150,fontSize:15,color:'black',textAlign:'left'}}  
                                                     maxLength={4}
                                                     />                               
                                               </div>
             
                                               <Button   style={{textAlign:'center',height:35,marginLeft:5}}>
                                                     <div     >
                                                           {data.secureTextEntry ?
             
                                                           <FaRegEyeSlash  className="passwordHide" style={{color:'white',width: '100%',height:18}}                                            
                                                           size={20}
                                                           onClick={()=>updateSecureTextEntry('hide')} 
                                                           /> 
                                                           :
                                                           <FaRegEye  className="passwordHide" style={{color:'white',width: '100%',height:18}}  
                                                           size={20}
                                                           onClick={()=>updateSecureTextEntry('open')} 
                                                           /> 
                                                           }
                                                     </div>   
                                               </Button>
             
                                           </div>
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
             
                                         <div style={{alignItems:'flex-start'}}>
                                         <div style={{textAlign:'left'}}>
                                         <FaLock color={MainColor} size={20} />Enter OTP from your email 
                                         </div>                           
                                         </div>
             
             
                                         <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,}}>
             
             
             
                                         <div>
                                         <Form.Control   
                                         onChange={e => setData({ ...data, otpCode:e.target.value})}
                                         value={data.otpCode}  
                                         maxLength={4}
             
                                         style={{width: 150,fontSize:15,color:'black',}} 
                                         
                                         />                               
                                         </div>
             
                                         <Button  style={{width:'30%',textAlign:'left',height:35,marginLeft:5}}>
                                          <div    onClick={() => SendOTPCode(false)}  > 
                                          <div style={{textAlign:'center',color:'white',fontSize:13,}}>Send OTP </div> 
                                          </div>   
                                         </Button>
             
                                         </div>
             
                                      </div>
             
             
             
                            
                                      <div style={{fontSize:13,padding:5,color:'blue'}} >{message}</div>
                                       
             
                                       <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:5}}>
             
                                       <div  onClick={() => setModalCreditCardPinUpdate(false)} style={{width:'45%'}}>
                                       <div className="button2">
                                       <div style={{textAlign:'center',fontSize:16,}}> Cancel </div>
                                       </div>
                                       </div>
             
             
                                       <div  onClick={UpdateCardPin} style={{width:'45%',marginLeft:'2.5%'}}>
                                       <div className="button">
                                       <div style={{textAlign:'center',color:'white',fontSize:16,}}> Update  PIN</div>
                                       </div>
                                       </div>
             
             
             
                                       </div>
             
             
             
                                 
                                 </div>
                                 
                                 
                                 </div>
                                 </Modal> 
                                 :
                                 null 
             
                               }


                               {/// ACCOUNT PASSWORD MODIFICATIONS 
                                 accountModalPassword?
             
                                 <Modal  show={true}    >
                                  
                                 <div style={{paddingLeft:'10%',paddingBottom:20}}>
             
                                 
                                 <div >
                                 
                                 
             
                                       <div style={{ color: 'black', fontSize: 20,marginTop: 5,marginBottom: 20, textAlign: 'center',}}>
                                       CHANGE ACCOUNT PASSWORD
                                       </div>
                           
                    
                                 
                                       <div>
                                           <div style={{marginTop:50,alignItems:'flex-start'}}>
                                           <div style={{textAlign:'left'}}>
                                           <FaLock color={MainColor} size={20} /> Create new passsword
                                           </div>                           
                                           </div>
             
             
                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,marginTop:10,}}>
             
             
             
                                           <div style={{width:'80%',textAlign:'left',}}>
                                           <Form.Control   
                                           onChange={e => setData({ ...data, password:e.target.value})}
                                           value={data.password} 
                                           type={data.passwordText}
                                          style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}} 
             
                                           />                               
                                           </div>
             
             
             
                                           </div>
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
                                           <div style={{alignItems:'flex-start'}}>
                                                 <div style={{textAlign:'left'}}>
                                                 <FaLock color={MainColor} size={20} />Confirm new passsword 
                                                 </div>                           
                                           </div>
             
             
                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,}}>
             
                                           
             
                                               <div style={{width:'90%',textAlign:'left',}}>
                                                     <Form.Control   
                                                     onChange={e => setData({ ...data, password2:e.target.value})}
                                                     value={data.password2}   
                                                     type={data.passwordText}
                                                     style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}}  
             
                                                     />                               
                                               </div>
             
                                               <Button   style={{width:'20%',textAlign:'center',height:35,marginLeft:5}}>
                                                     <div     >
                                                           {data.secureTextEntry ?
             
                                                           <FaRegEyeSlash  className="passwordHide" style={{color:'white',width: '100%',height:18}}                                            
                                                           size={20}
                                                           onClick={()=>updateSecureTextEntry('hide')} 
                                                           /> 
                                                           :
                                                           <FaRegEye  className="passwordHide" style={{color:'white',width: '100%',height:18}}  
                                                           size={20}
                                                           onClick={()=>updateSecureTextEntry('open')} 
                                                           /> 
                                                           }
                                                     </div>   
                                               </Button>
             
                                           </div>
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
             
                                         <div style={{alignItems:'flex-start'}}>
                                         <div style={{textAlign:'left'}}>
                                         <FaLock color={MainColor} size={20} />Enter OTP from your email 
                                         </div>                           
                                         </div>
             
             
                                         <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,}}>
             
             
             
                                         <div>
                                         <Form.Control   
                                         onChange={e => setData({ ...data, otpCode:e.target.value})}
                                         value={data.otpCode}  
                                         maxLength={4}
             
                                         style={{width: 150,fontSize:15,color:'black',}} 
                                         
                                         />                               
                                         </div>
             
                                         <Button  style={{width:'30%',textAlign:'left',height:35,marginLeft:5}}>
                                          <div    onClick={() => SendOTPCode(false)}  > 
                                          <div style={{textAlign:'center',color:'white',fontSize:13,}}>Send OTP </div> 
                                          </div>   
                                         </Button>
             
                                         </div>
             
                                      </div>
             
             
             
                            
                                      <div style={{fontSize:13,padding:5,color:'blue'}} >{message}</div>
                                       
             
                                       <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:5}}>
             
                                       <div  onClick={() => setAccountModalPassword(false)} style={{width:'45%'}}>
                                       <div className="button2">
                                       <div style={{textAlign:'center',fontSize:16,}}> Cancel </div>
                                       </div>
                                       </div>
             
             
                                       <div  onClick={accountPasswordUpdate} style={{width:'45%',marginLeft:'2.5%'}}>
                                       <div className="button">
                                       <div style={{textAlign:'center',color:'white',fontSize:16,}}> Update  </div>
                                       </div>
                                       </div>
             
             
             
                                       </div>
             
             
             
                                 
                                 </div>
                                 
                                 
                                 </div>
                                 </Modal> 
                                 :
                                 null 
             
                               }
                            

                               {/// BANK ACCOUNT UPDATE 
                                 modalBankAccountUpdate?
             
                                 <Modal  show={true}    >
                                 
                                 <div style={{paddingLeft:'10%',paddingBottom:20,paddingTop:10}}>
             
                                 
                                 <Form >
                                 
                                 
                                      <div style={{textAlign:'center'}}>
                                      <FaRegBuilding color={MainColor} size={30} />  
                                      </div>
                                       <div style={{ color: 'black', fontSize: 16,marginTop: 5,marginBottom: 20, textAlign: 'center'}}>
                                      
                                       UPDATE BANK ACCOUNT DETAILS
                                       </div>
                           
                    
                                 
                                       <div> 
             
              
             
             
                                           <div style={{textAlign:'left',}}>
                                           <div> Bank Name</div>
                                           <Form.Control  
                                           type="text"  
                                           onChange={e => setData({ ...data, bankName:e.target.value})}
                                           value={data.bankName}  
                                           placeholder={loginData.bank_name} 
                                            style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}}  
             
                                           />                               
                                           </div>
             
             
             
                                            
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
                                           <div style={{alignItems:'flex-start'}}>
                                                 <div style={{textAlign:'left'}}>
                                                 Account Name 
                                                 </div>                           
                                           </div>
             
             
                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,}}>
             
                                           
             
                                               <div style={{width:'90%',textAlign:'left',}}>
                                                     <Form.Control  
                                                     type="text" 
                                                     onChange={e => setData({ ...data, accountName:e.target.value})}
                                                     value={data.accountName}   
                                                     placeholder={loginData.account_name}
                                                      style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}}  
             
                                                     />                               
                                               </div>
              
             
                                           </div>
             
                                           
                                       </div>
             
             
             
             
                                       <div style={{marginTop:10,}}>
                                           <div style={{alignItems:'flex-start'}}>
                                                 <div style={{textAlign:'left'}}>
                                                 Account Number
                                                 </div>                           
                                           </div>
             
             
                                           <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,}}>
             
                                           
             
                                               <div style={{width:'90%',textAlign:'left',}}>
                                                     <Form.Control   
                                                     type="number"
                                                     maxLength={10}
                                                     onChange={e => setData({ ...data, accountNumber:e.target.value})}
                                                     value={data.accountNumber}   
                                                     placeholder={loginData.account_no}
                                                     style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}}  
             
                                                     />                               
                                               </div>
              
             
                                           </div>
             
             
                                       </div>
             
             
                                       <div style={{marginTop:10,}}>
             
                                         <div style={{alignItems:'flex-start'}}>
                                         <div style={{textAlign:'left'}}>
                                         <FaLock color={MainColor} size={26} /> Enter OTP from your email 
                                         </div>                           
                                         </div>
             
             
                                         <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,}}>
             
             
             
                                                <div >
                                                <Form.Control   
                                                onChange={e => setData({ ...data, otpCode:e.target.value})}
                                                value={data.otpCode}  
                                                maxLength={4}
                                                  
                                                style={{width: 150,fontSize:15,color:'black', }} 
                    
                                                />                               
                                                </div>
                    
                                                <Button  style={{textAlign:'left',height:38,marginLeft:5}}>
                                                  <div    onClick={() => SendOTPCode(false)}  > 
                                                  <div style={{textAlign:'center',color:'white',fontSize:13,}}>Send OTP </div> 
                                                  </div>   
                                                </Button>
             
                                         </div>
                                             <div style={{fontSize:13,padding:5,color:'blue'}} >{message}</div>
                                      </div>
             
             
             
                            
                                           
                                       
             
                                       <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:50}}>
             
                                       <div  onClick={() => setModalBankAccountUpdate(false)} style={{width:'45%'}}>
                                       <div className="button2">
                                       <div style={{textAlign:'center',fontSize:16,}}> Cancel </div>
                                       </div>
                                       </div>
             
             
                                       <div  onClick={bankAccountUpdate} style={{width:'45%',marginLeft:'2.5%'}}>
                                       <div className="button">
                                       <div style={{textAlign:'center',color:'white',fontSize:16,}}> Update 
                                        </div>
                                       </div>
                                       </div>
             
             
             
                                       </div>
             
             
             
                                 
                                 </Form>
                                 
                                 
                                 </div>
                                 </Modal>             
                                 :
                                 null 
             
                               }
                 </div>

                )      
} 


}
export default Profile;       