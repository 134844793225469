
import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation} from 'react-router-dom'; 
import { HiStatusOffline } from "react-icons/hi";
import { MdTipsAndUpdates } from "react-icons/md";
import { BsShieldLockFill } from "react-icons/bs";
import Modal from "../UI/Modal/Modal";  
import { BasePageAction } from '../../BaseUrl';
import { AuthContext } from '../../components/context';
import './Login.css';
 

function AccountRecovery() { 
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [accountUser, setAccountUser] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password, setPassword] = useState('');
  const [tabSwitch, setTabSwitch] = useState(true);
  const navigate = useNavigate();   


  const { signIn } = React.useContext(AuthContext);
  const search = useLocation().search;

 

  const showHandler =()=>{

    setShow(false);
    //refreshPage();
 }
 
 const autoCheckPage =()=>{
  const recovery_code=new URLSearchParams(search).get('recovery_code');
  const user=new URLSearchParams(search).get('user');
  setUsername(user)
  
     
      var headers = {  'Accept': 'application/json',  'Content-Type': 'application/json'  }   
      fetch(BasePageAction+"AccountRecoveryCheck&user="+user+"&recovery_code="+recovery_code,
      {
      method:'GET',
      header:headers, 
      })
      .then((response) => response.json())
      .then((response) => {            
       
        if(response[0].success == '1'){

           setTabSwitch(false); 
           setMessage(response[0].feedback);
           
        }else if(response[0].success == '2'){

          setTabSwitch(true);
          setMessage(response[0].feedback); 

          
        } 
            
      })
      .catch(error => { 
        setMessage('Oops! network error, please check your data and try again');
      });
   
}


useEffect(() => {

 var  approveRun = true;
 
  if(approveRun){
    autoCheckPage(); 
  }    

  return () => {
    approveRun = false;
  }

},[]);



  const handleSubmit = e =>  {
  e.preventDefault();

  setShow(true);
  
 

      const data = {   
        password1: password1, 
        password : password,
        user     : username
    
      };


    if( password1 === password)
    {   
                  var headers = {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                  }
        
                  fetch(BasePageAction+"UpdatePassword",
                  {
                  method:'POST',
                  header:headers,
                  body: JSON.stringify(data)
                  })
                  .then((response) => response.json())
                  .then((response) => {
                  
                        if(response[0].success == 'ok')
                        {

                          setMessage(response[0].feedback);
                  
                        }
                        else   
                        {
                          
                          setMessage(response[0].feedback);
                        }
                      



                    })
                    .catch(error => {
                      setMessage('Oops! network error, please check your data and try again');
                    });


      }
      else   
      {
        
        setMessage('Your password did not match. Please try again');
      }

 
}
 
if(show){ 
  return (
    <div>
          {
                
            message == ''?
              <center>
                <Modal show={show}  >    
                <img src="images/loading.gif"  style={{height: '100px'}}   /> 
                <br  /> <small style={{color: 'white'}}>Please Wait..</small>
                </Modal> 
              </center>
              :

            <center>
              
              
              <Modal clicked={showHandler} show={show}   > 
                
                  <div  className="rackdropDiv" style={{color: 'white'}}  > 
                  <MdTipsAndUpdates className="blink_me" style={{fontSize: 60,color: '#0278c0'}} /><br/>
                    {message} 
                  </div>

              </Modal>
              

            </center>

        
          }

  </div>

  )
}
 
    if(tabSwitch){ 

      return (
        <div className="App">
        <div className="App-header">

        <div className="ContainerS">  


        
        <div  className='homeHr'>
        <img src="images/favicon.png"   className="imgLogoL"/> <br />
        <div className="TextHeadLogin">SME LOAN BUILDER<br />PASSWORD RESET </div> 
        </div>

 
        <hr  className='homeHr2'/>


        <p>You have a broken password recovery link here   </p>
        <br/><br/>
        <HiStatusOffline   style={{fontSize: 160,color: 'white'}} />
        <br/><br/>

        <p>Your password recovery link expires or invalid  </p>

      


  
        </div>




        </div>

        </div>

        )
    }
    else
    {
       
        return (
        <div className="App">
        <div className="App-header">

        <div className="ContainerL">  

        <div  className='homeHr'>
        <img src="images/favicon.png"   className="imgLogoL"/> <br />
        <div className="TextHeadLogin">SME LOAN BUILDER<br />PASSWORD RESET </div> 
        </div>



         <p> Hi {message} you can reset your password below</p>  


        <form   onSubmit={handleSubmit}>



        <div className='form-inputs'>
        <label htmlFor='create-phone' className='form-label'> <BsShieldLockFill style={{fontSize: 25,color: '#0278c0'}} /> Password </label>
        <input
        id="password1"
        type="password"
        name="password1"
        className="form-input-login"
        placeholder='Enter your password'
        onChange={e=> setPassword1(e.target.value)}
        />

        </div>


        <div className='form-inputs'>
        <label htmlFor='create-password' className='form-label'>  <BsShieldLockFill style={{fontSize: 20,color: '#0278c0'}} /> Confirm Password</label>
   

        <input
        id="password"
        type="password"
        name="password"
        className="form-input-login"
        placeholder='Enter your password'
        onChange={e=> setPassword(e.target.value)}
        />
        </div>




        <button type="submit"   className="btntab--active" style={{marginTop: '30px',width: '95%', marginLeft:-15 }}>
          Reset Password
        </button>
 
        </form>



        </div>
        </div>


        </div>
        )


    }
    
  }
export default AccountRecovery