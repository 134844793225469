
import React, { useState,memo, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate ,useLocation,useHistory,browserHistory } from 'react-router-dom';  
import 'bootstrap/dist/css/bootstrap.min.css';  
import { BiArrowBack} from "react-icons/bi";  //
import { HiThumbUp} from "react-icons/hi";  //
import { RiMessage3Fill} from "react-icons/ri";  //
import  './PaymentLink.css';  
import { MainColor,SubscriberPayment, CardMaintSuccess,publicKey,BaseAction,CardFrontUrl,CardTopUpUrl} from '../../BaseUrl'; 
import { usePaystackPayment } from 'react-paystack';
import { AuthContext }  from '../context'; 
import md5             from 'md5-hash'
import Loading         from '../Loading';
import Loading2        from '../Loading2';
import { Modal, Button } from 'react-bootstrap'; 
import '../../App.css';  



function PaymentLink( props ) { 
  const { signOut,reloadUserData,reloadUserData_2} = React.useContext(AuthContext);
  const[activateLoader, setActivateLoader]   = useState(true); 
  const[isLoading2, setIsLoading2]   = useState(true); 
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState(''); 
  const [amount, setAmount] = useState('0'); 
  const[messageSwitch, setMessageSwitch] = useState(false);
  const[message, setMessage] = useState(''); 
  const [myToken, setMyToken] = useState('');
  const [serverFeedback, setServerFeedback] = useState('');
  const [verifyCheckPayment, setVerifyCheckPayment] = useState(false);
  const [cardNo, setCardNo] = useState('');
  const [searchCount, setSearchCount] = useState('1');

  const { signIn1 } = React.useContext(AuthContext);
  const navigate = useNavigate();



  const cardTopUpHandler = (event) => { 
    setAmount(event.target.value); 
  }


  
  const goBack = () => {
    navigate(-1);
  }
const search = useLocation().search;


useLayoutEffect(() => 
{     

 
  setEmail(email_m);
  setFullname(full_name);
  setPhone(phone_no);
  setMessage('')

  setActivateLoader(true);
 
  
    var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }

    fetch(BaseAction+"CheckPaymentCard&user="+phone_no,
    {
    method:'GET',
    header:headers
    })
    .then((response) => response.json())
    .then((response) => {
     
      
      if(response[0].success == 'success'){
        

        setVerifyCheckPayment(true);
        setCardNo(response[0].cardNo);
        setActivateLoader(false);
      }else{

        setVerifyCheckPayment(false);
        setActivateLoader(false);
      }
    


   
  
  })
  .catch(error => {
    setMessage('');
  });



},[]);


const email_m       = new URLSearchParams(search).get('email'); 
const full_name     = new URLSearchParams(search).get('fullname');
const token         = new URLSearchParams(search).get('token');
const phone_no      = new URLSearchParams(search).get('username');
const cardTopUp     = new URLSearchParams(search).get('cardTopUp');
const card_maint    = new URLSearchParams(search).get('card_maint');
const credit_card_amt    = new URLSearchParams(search).get('credit_card_amt');
const userToken     = new URLSearchParams(search).get('userToken');

//f
useEffect(() => 
{      
  const email_m       = new URLSearchParams(search).get('email'); 
  const full_name     = new URLSearchParams(search).get('fullname');
  const raw_token      = new URLSearchParams(search).get('token');
  const phone_no      = new URLSearchParams(search).get('username');
  const cardTopUp     = new URLSearchParams(search).get('cardTopUp');
  const userToken     = new URLSearchParams(search).get('userToken');
  const credit_card_amt    = new URLSearchParams(search).get('credit_card_amt');
  setPhone(phone_no);
  setMyToken(userToken);

  if(searchCount == '1'){
    CheckPayment(phone_no);
  }
  

     
  
    // const value =  localStorage.getItem('loginSession');
    // const data = JSON.parse(value);  
    // if(data == null){
  
    // }else{
    //   if(data.card_main_status == 'active'){
    //     navigate('/')
   
    //   }
  
    // }
  
});


 
 
const config = {
   
  reference: (new Date()).getTime().toString(),
  email: email,
  amount: amount*100,
  publicKey: publicKey,
};


// you can call this function anything
const CheckPayment = (val) => {

  setMessage('')

  setActivateLoader(true);
 
  
    var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }

    fetch(BaseAction+"CheckPaymentCard&user="+val,
    {
    method:'GET',
    header:headers
    })
    .then((response) => response.json())
    .then((response) => {
     
      setSearchCount('0');
      if(response[0].success == 'success'){
        

        setVerifyCheckPayment(true);
        setCardNo(response[0].cardNo);
        setActivateLoader(false);
      }else{

        setVerifyCheckPayment(false);
        setActivateLoader(false);
      }
    


   
  
  })
  .catch(error => {
    setMessage('Oops! network error, please check your data and try again');
  });
 


};

//SubscriberPayment
//CardMaintSuccess
//CardTopUpUrl
// you can call this function anything
const cardMaintSuccess = (reference) => {
 
  setMessage('');
  setIsLoading2(true)
  setActivateLoader(true);
 

  const data = {   
    fullname: fullname, 
    phone: phone, 
    amount: amount,
    email: email, 
    userToken: myToken, 
    reference: reference.reference

  };


  
    var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }

    fetch(CardMaintSuccess,
    {
    method:'POST',
    header:headers,
    body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((response) => {
     
         if(response[0].success == '1')
         {
    
          reloadUserData();
          
         }
         else
         {
                
            setMessage(response[0].feedback);
            setMessageSwitch(true)
            setIsLoading2(false)
            setActivateLoader(false);  
         }

  
  })
  .catch(error => {
    setMessage('Oops! network error, please check your data and try again');
  });

 
 


};

// you can call this function anything
const onTopUpSuccess = (reference) => {
 
  setMessage('');
  setActivateLoader(true);
 

  const data = {   
    fullname: fullname,  
    amount: amount,
    email: email, 
    userToken: myToken, 
    reference: reference.reference

  };


  
    var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }

    fetch(CardTopUpUrl,
    {
    method:'POST',
    header:headers,
    body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((response) => {
     
   
      setMessage(response[0].feedback);
      setMessageSwitch(true)
      setActivateLoader(false);  
  
  })
  .catch(error => {
    setMessage('Oops! network error, please check your data and try again');
  });

 
 


};
// you can call this function anything
const onSuccessCreditCardRequest = (reference) => {
 
  setMessage('');
  setActivateLoader(true);
 

  const data = {    
    fullname : fullname,  
    amount   : amount,
    email    : email, 
    userToken: myToken, 
    reference: reference.reference

  };


  
    var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
    }

    fetch(SubscriberPayment,
    {
    method:'POST',
    header:headers,
    body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((response) => {
     
      setActivateLoader(false);
      setSearchCount('0')
      
        setServerFeedback(response.feedback)
        setCardNo(response[0].cardNo);
        setVerifyCheckPayment(true); 

    


   
  
  })
  .catch(error => {
    setMessage('Oops! network error, please check your data and try again');
  });

 
 


};
 

const onClose = () => {
  setActivateLoader(true); 
setMessage('Payment Transaction Cancelled');
}


const PaystackCreditCardRequest = (props) => {

setAmount(props.ChrAmt)

   const initializePayment = usePaystackPayment(config);
 

        if(fullname == '' || phone == '' || email == ''  )
        {

          setMessage('All form fields are required');

        }
        else
        {
        return (
                
          <div  onClick={() => { initializePayment(onSuccessCreditCardRequest, onClose) }}  > 
          Pay Credit Card Fee
          </div>

        );

        }

 
 
    
};

const PaystackCardTopUp = (props) => {

   

   const initializePayment = usePaystackPayment(config);
 

 
        return (
                
          <div  onClick={() => { initializePayment(onTopUpSuccess, onClose) }}  > 
          Top Up Credit Card
          </div>

        );

 

 
 
    
};

const PaystackCardMaintPayment = (props) => {

  setAmount(props.ChrAmt)  

   const initializePayment = usePaystackPayment(config);
 

 
        return (
                
          <div  onClick={() => { initializePayment(cardMaintSuccess, onClose) }}  > 
           Pay Levy
          </div>

        );

 

 
 
    
};


//card_maint
 

const showHandler =()=>{

  setActivateLoader(false);
  setMessage('')
}


const showHandlerNavigate =()=>{


 // window.location ="https://rsbdigitalshares.com.ng";
  setMessageSwitch(false)
  setActivateLoader(false);
}




 

  const handleSubmit = e =>  {
  e.preventDefault();
  setMessage('')
  setActivateLoader(true);
 
      if(fullname == '' || phone == '' || email == ''  ){

        setMessage('All form fields are required');

      }
      else
      {

      
      }
}


  const CardTopUpSubmit = e =>  {
  e.preventDefault();
  setMessage('')
  setActivateLoader(true);
 
      if(amount.length == 0 ){

        setMessage('All form fields are required');

      }
      else
      {

      
      }
}

 
const  ReLoadHanduler =() =>{

 
  setActivateLoader(false);  
  setIsLoading2(false);


}



if(activateLoader)
{


      if(isLoading2)
      {


        return (     

              <div> 
                <Loading  /> 
              </div>
            );


      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }



    
}
else
{ 
  
      if(card_maint == 'card_maint')
      {
             /// MONTHLY MAINTENANCE LEVY 
            return (
              <div className="App">
              <div className="App-header"> 
              
              <div className="Container1">  

              <div   onClick={goBack} > 
              <BiArrowBack  size={50} color={MainColor}  />
              </div>


              <div>  <img src={CardFrontUrl+'/'+cardNo}   style={{borderRadius:15}}   className="imgcard" /></div>
                    
              <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:22}}>  CHANNEL / CREDIT CARD  </span>  </div>
              <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:18}}>MONTHLY MAINTENANCE LEVY  </span>  </div>
              <div style={{color:'black', fontSize:15}} > {message} </div>
              <hr  className='homeHr2'/>
      
      
      
      
      
      
              <form   onSubmit={CardTopUpSubmit}>
      
      
      
              <div className='form-inputs'>
              <label htmlFor='create-email' className='form-label' style={{color:'#000000'}}>Card Holder Fullname</label>
              <input
              style={{backgroundColor:'#e9e9ed'}}
              id="text"
              type="text"
              name="Fullname"
              className="form-input"  
              value={fullname}
              readOnly
              />
      
              </div>
      
      
                <div className='form-inputs'>
                  <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Card Holder Phone</label>

                  <input 
                  style={{backgroundColor:'#e9e9ed'}}
                  type="text"
                  name="number"
                  className="form-input"  
                  value={phone}
                  readOnly
                  />  
                </div>
      

      
              <div className='form-inputs' style={{display:'flex',flexDirection:'row', }}>
      
              <div className='form-inputs'>
              <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Card Holder Email</label>

              <input 
              style={{backgroundColor:'#e9e9ed'}}
              type="text"
              name="number"
              className="form-input"  
              value={email}
              readOnly
              />  
              </div>

            <div className='form-inputs' style={{with:'45%',marginLeft:'5%'}}> 
                <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Levy Amount </label>


              <select id="amount" name="amount" className="form-input"  onChange={cardTopUpHandler}>

              <option value="450">{props.CardMainFee} </option> 

              </select>
    
                
            </div>
              
              </div>
      
                                  
              <button type="submit"    className='form-input-btn' style={{backgroundColor:'black',color:'white'}} > 
              <PaystackCardMaintPayment  ChrAmt={props.CardMainFee}   />   
              </button>
      
          
      
              </form>
      
      
      
              </div>
              </div>
      
                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                  messageSwitch?


                                  <Modal  show={true}  >
                                  <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
              
                      
            
                      
                                          <div>
            
                                  
                                        <RiMessage3Fill    size={30}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                  
                                        
                      
                      
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                        </div>
            
              
                                            <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                <div style={{color:'white'}}>close</div>
                                                </div>  
                                            </div>
                                        
            
            
            
                                        
                                        
                                        </div>
                                  </div>
                            
                                  </Modal>
                                  :  
                                  null
                      
                                  }               
              </div>
              )
      }
      else
      {
            if(cardTopUp == 'cardTopUp')
            {

                // Credit Card Top Up
              return (
                <div className="App">
                <div className="App-header"> 
                
                <div className="Container1">  
      
                <div   onClick={goBack} > 
                <BiArrowBack  size={50}  color={MainColor} />
                </div>
      
      
                <div>  <img src={CardFrontUrl+'/'+cardNo}   style={{borderRadius:15}}   className="imgcard" /></div>
               
        
                <div > <span style={{color:'#000000',fontWeight:'bolder',fontSize:25}}> CREDIT CARD TOP UP </span>  </div>
                <p style={{color:'#000000',}} >Welcome to Virtual Credit Card Top Up   </p>
                <hr  className='homeHr2'/>
        
        
        
        
        
        
                <form   onSubmit={CardTopUpSubmit}>
        
        
        
                <div className='form-inputs'>
                <label htmlFor='create-email' className='form-label' style={{color:'#000000'}}>Subscriber Fullname</label>
                <input
                style={{backgroundColor:'#e9e9ed'}}
                id="text"
                type="text"
                name="Fullname"
                className="form-input"  
                value={fullname}
                readOnly
                />
        
                </div>
        
        
                  <div className='form-inputs'>
                    <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Subscriber Phone</label>
      
                    <input 
                    style={{backgroundColor:'#e9e9ed'}}
                    type="text"
                    name="number"
                    className="form-input"  
                    value={phone}
                    readOnly
                    />  
                  </div>
        
      
        
                <div className='form-inputs' style={{display:'flex',flexDirection:'row', }}>
        
                <div className='form-inputs'>
                <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Email</label>
      
                <input 
                style={{backgroundColor:'#e9e9ed'}}
                type="text"
                name="number"
                className="form-input"  
                value={email}
                readOnly
                />  
                </div>
      
              <div className='form-inputs' style={{with:'45%',marginLeft:'5%'}}> 
                  <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Enter Amount </label>
      
      
                <select id="amount" name="amount" className="form-input"  onChange={cardTopUpHandler}>
      
                <option value=""> </option>
                <option value="550">NGN500</option>
                <option value="1100">NGN1000</option>
                <option value="2150">NGN2000</option>
                <option value="5200">NGN5000</option>
      
                </select>
      
                  
              </div>
                
                </div>
        
                                    
                <button type="submit"    className='form-input-btn'   style={{backgroundColor:'black',color:'white'}}> 
                <PaystackCardTopUp   />  
                
                </button>
        
            
        
                </form>
        
        
        
                </div>
                </div>
                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                  messageSwitch?


                                  <Modal  show={true}  >
                                  <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
              
                      
            
                      
                                          <div>
            
                                  
                                        <RiMessage3Fill    size={30}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                  
                                        
                      
                      
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                        </div>
            
              
                                            <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                <div style={{color:'white'}}>close</div>
                                                </div>  
                                            </div>
                                        
            
            
            
                                        
                                        
                                        </div>
                                  </div>
                            
                                  </Modal>
                                  :  
                                  null
                      
                                  }         
                
                </div>
                )

            }
            else
            {

                    if(verifyCheckPayment == true)
                    {
                      return (
                        <div className="App">
                        <div className="App-header"> 
                        
                        <div className="Container1">  
                        <img src="images/logo.png"  style={{height:70,width:70}} /> 
                        <h3 className="TextHeader" style={{color:'black'}}>RSB DIGITAL SHARES </h3>
                        <h3 className="TextHeader" style={{color:'black'}}>VIRTUAL CREDIT CARD REQUEST </h3><hr  className='homeHr'/>
                        <p style={{color:'green'}}>SUCCESSFULL </p>

                        <p className="text" style={{color:'green'}}>{serverFeedback} </p>
                
                    
                        <hr  className='homeHr2'/>
                
                        <HiThumbUp  size={70}  style={{color:'green'}} />
                        <p className="text" style={{color:'black'}}>Hi {fullname} </p>
                        <p className="text" style={{color:'black'}}> Your Virtual Credit Card is Active</p>
                        <div>  <img src={CardFrontUrl+'/'+cardNo}   style={{borderRadius:15}}   className="imgcard" /></div>
              
                        <button type="submit"   onClick={goBack}  className='form-input-btn'   style={{backgroundColor:MainColor,color:'white'}}> 
                        Close Page to return to Mobile App / Web App
                        </button>
              
                      
                
                        </div>
                        </div>
                
                                  {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                    messageSwitch?


                                    <Modal  show={true}  >
                                    <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
                
                        
              
                        
                                            <div>
              
                                    
                                          <RiMessage3Fill    size={30}  color={MainColor} /> 
                                          <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                    
                                          
                        
                        
                                          <div   style={{marginTop: 20}} >
                                          
                                                <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                          </div>
              
                
                                              <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                  <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                  <div style={{color:'white'}}>close</div>
                                                  </div>  
                                              </div>
                                          
              
              
              
                                          
                                          
                                          </div>
                                    </div>
                              
                                    </Modal>
                                    :  
                                    null
                        
                                    }                       
                        </div>
                        )
                    }
                    else
                    {    
                      
              
              
                        return (
                          <div className="App">
                          <div className="App-header"> 
                          
                          <div className="Container1">  
          
                        
          
          
                          
                          <p style={{color:'black',fontSize:18,fontWeight:'bolder',}}>RSB DIGITAL SHARES </p>
                  
                          <div > <span style={{color:'#000000',fontWeight:'bold',fontSize:18}}> ANNUAL CREDIT CARD REQUEST</span>  </div>
                          <hr  className='homeHr2'/>
                          <img src="images/verified.png"   className="imgcard"   /> 
                  
                  
                  
                  
                  
                          <form   onSubmit={handleSubmit}>
                  
                  
                  
                          <div className='form-inputs'>
                          <label htmlFor='create-email' className='form-label' style={{color:'#000000'}}>Subscriber Fullname</label>
                          <input
                          style={{backgroundColor:'#e9e9ed'}}
                          id="text"
                          type="text"
                          name="Fullname"
                          className="form-input"  
                          value={fullname}
                          readOnly
                          />
                  
                          </div>
                  
                  
                            <div className='form-inputs'>
                              <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Subscriber Phone</label>
          
                              <input 
                              style={{backgroundColor:'#e9e9ed'}}
                              type="text"
                              name="number"
                              className="form-input"  
                              value={phone}
                              readOnly
                              />  
                            </div>
                  
                
                  
                          <div className='form-inputs' style={{display:'flex',flexDirection:'row', }}>
                  
                          <div className='form-inputs'>
                          <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Email</label>
          
                          <input 
                          style={{backgroundColor:'#e9e9ed'}}
                          type="text"
                          name="number"
                          className="form-input"  
                          value={email}
                          readOnly
                          />  
                          </div>
          
                        <div className='form-inputs' style={{with:'45%',marginLeft:'5%'}}> 
                            <label htmlFor='create-password' className='form-label' style={{color:'#000000'}}>Credit Card Fee </label>
          
                            <input
                            id="text"
                            type="text"
                            name="text" 
                            style={{backgroundColor:'#e9e9ed'}}
                            className="form-input" 
                            value={credit_card_amt}
                            readOnly
                            />  
                        </div>
                  
                          </div>
                  
                  
                          <button type="submit"  style={{backgroundColor:'black',color:'white'}}  className='form-input-btn'> 
                            <PaystackCreditCardRequest ChrAmt={credit_card_amt} />
                          </button>
                  
                      
                  
                          </form>
                  
                  
                  
                          </div>
                          </div>
                  
                          

                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                  messageSwitch?


                                  <Modal  show={true}  >
                                  <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
              
                      
            
                      
                                          <div>
            
                                  
                                        <RiMessage3Fill    size={30}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                  
                                        
                      
                      
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                        </div>
            
              
                                            <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                <div style={{color:'white'}}>close</div>
                                                </div>  
                                            </div>
                                        
            
            
            
                                        
                                        
                                        </div>
                                  </div>
                            
                                  </Modal>
                                  :  
                                  null
                      
                                  } 



                          </div>
                          )
          
              
                    }
            
            }

      }
    
  }

}





 
export default memo(PaymentLink);