import React, { useState,useEffect, useRef,useLayoutEffect,useMemo,memo} from 'react';
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom';
import { GoUnverified, GoVerified   } from "react-icons/go";   
import { TbHandStop,TbReload   } from "react-icons/tb";   
import { GiTrade} from "react-icons/gi";  
import { FiUsers} from "react-icons/fi";  
import { RiMessage3Fill} from "react-icons/ri";  
import { FaArrowLeft,FaUserTie} from "react-icons/fa";  
import { IoIosCloseCircle,IoIosOptions} from "react-icons/io";  
import {BsFillBellFill,BsSearch,BsThreeDotsVertical} from "react-icons/bs";   
import {Naira, BaseAction, MainColor, BasePlaceholder} from '../../BaseUrl';
import {Card, Modal} from 'react-bootstrap';
import { AuthContext } from '../../components/context';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";
import './SellGoldenCoin.css'; 
 

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 



const SellGoldenCoin = (props) => {
  const[activateLoader, setActivateLoader]   = useState(true); 
  const[isLoading2, setIsLoading2]   = useState(true);
  const[tradeCoinData, setTradeCoinData] = useState([]);
  const[loginData, setLoginData] = useState([]); 
  const[shortMenuModal, setShortMenuModal]   = useState(false); 
  const[challenger, setChallenger] = useState(''); 
  const[contestant, setContestant] = useState(''); 
  const[message, setMessage] = useState(''); 
  const[messageSwitch, setMessageSwitch] = useState(false);
  const[messageNote, setMessageNote] = useState(false);
  const[callContact, setCallContact] = useState(''); 
 
  const searchRef = useRef();
  const [data, setData] = useState([]);
 
  const[contactSeller, setContactSeller] = useState(''); 
  const navigate = useNavigate();   
 
  ///////////////////////////////////////////
    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
    setLoginData(data);
      
    }
   
    
    useEffect(() => {
          
        setTimeout(async() => {
        
            LoginOptionUpdate();  
        
        }, 5000);
                        
    });
    
    useLayoutEffect(() => {
    
          LoginOptionUpdate();
          
    },[]); 
  
  
    $(document).ready(function () {
      $('#example').DataTable();
      });
    
      
  
  
 
 

 
  const callAllData = () =>{
     
    setActivateLoader(true);
    setIsLoading2(true);
    var authUserToken =  localStorage.getItem('authUserToken');
  
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"GoldenCoinBuyer&passkey="+authUserToken,
       {
       method:'GET',
       header:headers
       })
              
             .then((response) => response.json())
             .then((responseJson) =>
             {  
                
            
                 
               
                 setData(responseJson); 
                 setActivateLoader(false);
                 setIsLoading2(false);
       
           
           
  
           })
           .catch((error) => { 
               setIsLoading2(false);   
                
             });
   
  
  
  
  
  }
  
  useEffect(() => {

    let runNow = true
   
      
        if(runNow){ 
  
        callAllData();
  
        }
        
      
  
  
      return () => {
        runNow = false;
      }; 
   
   
   
    },[tradeCoinData]);



  const  ChallengUser =(val,val2,val3) =>{
 

    var value =  loginData.golden_coin_value * 1; 
    var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       



  if(loginData.golden_coin_earn >= 1)
  {
      if(val3 >= loginData.golden_coin_value)
        {
                if(loginData.userkey === val )
                {
                  setMessageNote(true)
                  setMessage('Sorry you can not send a trade request to your self');
                  setMessageSwitch(true); 
                

                }else{
                  setMessageNote(false)
                  setChallenger(loginData.username);
                  setContestant(val);
                  setMessage('Are you sure you want to sell 1 shares?, if yes proceed to send trade request to '+ val2);
                  setMessageSwitch(true); 

                  
                }

      }
      else
      {
        setMessageNote(true)
        setMessage(val2+' does not have enough fund to buy your Shares');
        setMessageSwitch(true); 

      }


}
  else
  {
    setMessageNote(true)
    setMessage('You need minimum of 1 healthy Shares (1SH) to sell to buyers ');
    setMessageSwitch(true); 

  }

}   

 
//SELLER - TO - BUYER
const seamlessBuyerToSeller = () =>{
 
  setMessage('');
  setMessageSwitch(false);


        var headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
        }
        var userPasskey =  localStorage.getItem('authUserToken');
        fetch(BaseAction+"SeamlessSellGoldenCoin&SellerPassKeyToken="+userPasskey+"&BuyerUserKeyToken="+contactSeller+'&TradeFrom=seller',
        {
        method:'GET',
        header:headers, 
        })
        .then((response) => response.json())
        .then((response) => { 
              
  
                

              setMessageNote(true);
              setMessageSwitch(true);
              setMessage(response[0].feedback);
              
              
        }) 
        .catch(error => {
              
          
        });
  

}




 
 

const showHandler =()=>{
  setMessage('');
  setActivateLoader(false);
  setIsLoading2(false);
  

}

   

 
const ContactHandler = (val1, val2, val3) => {

   
  ChallengUser(val1,val2,val3);
  setContactSeller(val1);

}
  
if(activateLoader){
  
      if(isLoading2)
      {
  
  
        return (     
      
              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );
  
  
      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }
  
  
  
  

    }
else
{ 

    return (
  
          <div class="table-responsive">
  
   
          <Row style={{paddingTop:20,}}>
          <Col style={{fontSize:20,marginBottom:10,}}>
          SHARES(SH) BUYERS
          </Col> 
        </Row>


           

        <table id="example"   class="table-responsive table table-bordered table-striped display"  style={{padding:15,width:'100%',marginTop:50}}>
   

          <thead >
            <tr>
             
              <th style={{ width: '40%' }}>Shares(SH) Buyers</th>
              <th style={{ width: '60%',}}>Message </th> 
            </tr>
          </thead>

          <tbody  > 
            {data.map(item => {
              return (
                <tr  style={{textAlign: 'left'}} onClick={() => ContactHandler(item.userkey,item.fullname,item.vcard_bal)} >
                  <td style={{ }} >
                            { item.vcard_bal >= loginData.golden_coin_value?
                              <div style={{ color: 'black', fontSize: 14,  }} >
                              <FaUserTie  size={24} color={'green'} />  {item.fullname} <br />
                              {item.trade_code}   
                              </div>                
                                :
                                <div style={{ color: 'black', fontSize: 14,  }} >
                              <FaUserTie  size={24} color={MainColor} /> {item.fullname} <br />
                              {item.trade_code}   
                              </div>                            
                            }
                  </td>

                  <td style={{  display:'flex',flexDirection:'row'}}>
                      <div>  <RiMessage3Fill  size={24} color={'black'} /> </div>
                      
                      <div >
                      <div style={{ color:'black', fontSize: 14,  }} >  Hi, i urgently need a Shares(SH) to buy. Click  to sell</div>
                     
                      </div>
                  </td> 
                </tr>
              );

              
            })}
          </tbody>


        </table>

    
 


                     {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                      messageSwitch?
      
            
                      <Modal  show={true}  >
                      <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
  
          

          
                             <div>

                      
                            <GiTrade    size={30}  color={MainColor} /> 
                            <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> SHARES(SH) TRADE REQUEST</div>  
                      
                            
          
          
                            <div   style={{marginTop: 20}} >
                            
                                  <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                            </div>


                           
                            

                              { messageNote?
                                <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                    <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                    <div style={{color:'white'}}>close</div>
                                    </div>  
                                </div>
                                :
                                <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >

                                  <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                  <div style={{color:'white'}}>Cancel</div>
                                  </div>  

                                  <div  className="buttonBusy"  style={{padding:10,marginLeft:20,fontSize:14,width:'45%',backgroundColor:'green'}} onClick={() => seamlessBuyerToSeller()}  > 
                                  <div style={{color:'white'}}>Yes</div>
                                  </div>

                                </div>
                              }



                            
                            
                            </div>
                      </div>
                
                      </Modal>
                      :  
                      null
          
                      }   
                      
 
            </div>
      )
  
 }
     
};
  


 
export default memo(SellGoldenCoin);  





          