import { NavLink, useNavigate} from "react-router-dom";  
import React, { useState, useEffect }  from 'react';
import { FaBars,FaHistory, FaHome, FaLock, FaGamepad, FaUser,FaWallet,FaCoins,FaRegUserCircle,FaUsers,FaRegMoneyBillAlt } from "react-icons/fa";
import { MdMessage,MdOutlineNotificationImportant } from "react-icons/md"; 
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { VscReferences } from "react-icons/vsc";
import { SiCodeforces } from "react-icons/si";
import { TiGroup } from "react-icons/ti";
import { BiCog } from "react-icons/bi";
import { GiMoneyStack, } from "react-icons/gi";
import { AiOutlineLogout, AiFillCreditCard,  } from "react-icons/ai";
import { GiWallet,GiReceiveMoney } from "react-icons/gi";
import { BsCartCheck } from "react-icons/bs"; 
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { AuthContext } from '../../components/context';
import { TopColor,MainColor,BodyColor } from '../../BaseUrl';
import './Sidebar.css'

 



const routes = [
  {
    id: '1',
    path: "/",
    name: "Home",
    icon: <FaHome />,
  },
 
 
  {
    id: '3',
    path: "/InstantLoan",
    name: "Get Instant Loan ",
    icon: <FaRegMoneyBillAlt />,
  },

 
  {
    id: '4',
    path: "/buy-airtime-data",
    name: "Buy Airtime/Data",
    icon: <AiFillCreditCard />,
  },
  {
    id: '7',
    path: "/money-transfer",
    name: "Money Transfer",
    icon: <VscReferences />,
  },
  // {
  //   id: '8',
  //   path: "/Cashout",
  //   name: "Cashout to Admin",
  //   icon: <AiFillCreditCard />,
  // },
  {
    id: '81',
    path: "/cashout-to-agent",
    name: "Cashout to Agent",
    icon: <AiFillCreditCard />,
  },
  {
    id: '6',
    path: "/virtual-credit-card",
    name: "View Credit Card",
    icon: <AiFillCreditCard />,
  },



  {   id: '9',
    path: "/fund-credit-card",
    name: "Fund Credit Card",
    icon: <GiReceiveMoney />,
  },

  {
    id: '12',
    path: "/transaction-history",
    name: "Transaction History",
    icon: <FaHistory />,
  },
  // {
  //   id: '5',
  //   path: "/Credit-card/",
  //   name: "Credit Card",
  //     icon: <GiWallet />,
  //     subRoutes: [
  //       {
  //         id: '6',
  //         path: "/virtual-credit-card",
  //         name: "View Credit Card",
  //         icon: <AiFillCreditCard />,
  //       },



  //       {   id: '9',
  //         path: "/fund-credit-card",
  //         name: "Fund Credit Card",
  //         icon: <GiReceiveMoney />,
  //       },

  //       {
  //         id: '12',
  //         path: "/transaction-history",
  //         name: "Transaction History",
  //         icon: <FaHistory />,
  //       },
  //     ],
  // },
  {
    id: '13',
    path: "/golden-coin-trade",
    name: "Buy/Sell SH",
      icon: <GiWallet />,
      subRoutes: [
        {   id: '14',
          path: "/buy-shares",
          name: "Buy Shares(SH) ",
          icon: <GiReceiveMoney />,
        },
        {
          id: '15',
          path: "/sell-shares",
          name: "Sell Shares(SH)",
          icon: <FaWallet />,
        },
  
      ],
  },
  {
    id: '16',
    path: "/Settings",
    name: "Settings",
    icon: <BiCog />,
    exact: true,
    subRoutes: [
      {
        id: '17',
        path: "/profile",
        name: "View Profile",
        icon: <FaUser />,
      },
 
 
      {
        id: '18',
        path: "/Logout",
        name: "Logout",
        icon: <AiOutlineLogout    />,
      },
    ],
  }
];

const SideBar = ({children ,props}) => {


const [width1, setWidth1] = useState(''); 
const [width2, setWidth2] = useState('');  
const [show, setShow] = useState(false);
const [isOpen, setIsOpen] = useState(false);//false default
const [loginToken, setLoginToken] = useState('');
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const { notMsg } = React.useContext(AuthContext);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
 
  useEffect(() => {
     
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value); 
    setLoginToken(data);
    SreenAdjust()


  },[]);


 
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }


  }, [windowDimenion])


const SreenAdjust = () => {
 
  if(windowDimenion.winWidth > 768){
    //Desktop settings
    setIsOpen(true);
    setWidth1('25%');
    setWidth2('0px');

  }else{
    //Mobile settings
    setIsOpen(false);
    setWidth1('70%');
    setWidth2('0');

    
  }


}
 

const ProfileHandler = () => {
   
  setIsOpen(false);           
  navigate('/profile');

}

const MouseOverHanher = () => {
  
  if(windowDimenion.winWidth > 768){
    //Desktop settings 

  }else{
    //Mobile settings 
    setIsOpen(false);
    
  }


}
//console.log(width1 , width2)


  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };
 


  
//onClick={()=> setIsOpen(false)} 
if(show){

  navigate('/Login');

}
else
{
 
    return (    
   
    <div>
            <nav className='mainNav' style={{backgroundColor:MainColor,width:'100%'}}>
            


              <div  className='navTab1' >
              <FaBars onClick={toggle}  size={18} style={{color:  '#fff'}}/>
              </div>


       
               <div className='navTab2'>
               <FaHistory onClick={()=>  navigate('/transaction-history')}  size={18} style={{color:  '#fff'}}/><br/>
               <small style={{fontSize:12,color:'#fff'}}></small> 
               </div>

              
              <div className='navTab3'    >
              <FaRegUserCircle  size={18} onClick={ProfileHandler} style={{color:  '#fff'}}/><br/>
              <small style={{fontSize:11,color:'#fff'}} >{loginToken.fullname}</small> 
              </div>


            
            </nav>


            <div className="main-container" >
        
                <motion.div
                style={{backgroundColor: '#eee',color:TopColor}}

                    animate={{
                      width: isOpen ? width1 : width2,

                      transition: {
                        duration: 0.5,
                        type: "spring",
                        damping: 10,
                      },
                    }}
                    className={`sidebar`}
                   
                >

 
                          <div className="top_section" style={{ width: isOpen ? width1 : width2,}}>
                           
                            <AnimatePresence>
                            {isOpen && (
                              <motion.h1
                                variants={showAnimation}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                                className="logo"
                              >
                                
                              
                              </motion.h1>
                            )}
                          </AnimatePresence>
                          </div>

                
                         <section className="routes" >

                         
                      
                        {
                          routes.map((route) => {
                          
                          if (route.subRoutes) {
                            return (
                              <SidebarMenu
                              key={route.id}
                                setIsOpen={setIsOpen}
                                route={route}
                                showAnimation={showAnimation}
                                isOpen={isOpen}
                                MouseOverHanher={MouseOverHanher}
                              />
                            );
                          }

                          return (
                            <NavLink
                              to={route.path}
                              key={route.id}
                              className="link"
                             onClick={MouseOverHanher} 
                            >
                              <div className="icon">{route.icon}</div>
                              <AnimatePresence>
                                {isOpen && (
                                  <motion.div
                                    variants={showAnimation}
                                    initial="hidden"
                                    animate="show"
                                    exit="hidden"
                                    className="link_text"
                                   
                                  >
                                    {route.name}
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </NavLink>

                            
                          );

                  

                        })
                        
                        }

                        



                        </section>





              

              </motion.div>

              <main onMouseOver={MouseOverHanher} className="mainChildren"  style={{width: '100%'}}>{children}</main>  
      
            </div>              

    </div>

  );


}
   

 



            

}
export default SideBar;
