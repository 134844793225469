 
import React, { useState, useEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'  
import {BaseAction, CardFrontUrl,MainColor} from '../../../BaseUrl'; 
import Loading          from "../../Loading";
import Loading2          from "../../Loading2"; 
import { FaRegEyeSlash,FaRegEye,FaEnvelopeOpenText  } from "react-icons/fa";
import { IoCloseCircleOutline,IoCloseCircle  } from "react-icons/io5";   
import md5                from 'md5-hash';
import { AuthContext }   from '../../../components/context';
import {Modal,Card, Table, Form,Button} from 'react-bootstrap';
import './PasswordChange.css'; 




export default  function PinChange() {  
  const {  reloadUserData,signOut} = React.useContext(AuthContext); 
  const[loginData, setLoginData] = useState([]);  
  const[message, setMessage] = useState(''); 
  const[messageSwitch, setMessageSwitch] = useState(false); 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false); 
  const[authUser, setAuthUser]   = useState(''); 
  const[newCardPin, setNewCardPin]   = useState(''); 
  const[confirmCardPin, setConfirmCardPin]   = useState(''); 
  const[data, setData] = useState({ 
    updateSecureTextEntry:'',
    passwordText: 'password',
  })
    const navigate = useNavigate();
 
    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
  
    setLoginData(data);
      if(data.card_main_status == 'passive'){
        navigate('/')
   
      }
      
    }
    
    useEffect(() => { 
    setTimeout(async() => { 
          LoginOptionUpdate();   
    }, 5000);
    
    
                      
    });
    
    useEffect(() => { 
      LoginOptionUpdate(); 
    },[]); 
   

  
    const updateSecureTextEntry = (val) => {

      if(val == 'hide'){
    
        setData({  ...data, 
          secureTextEntry: false,
          passwordText:'text'
          });
    
      }else if(val == 'open'){
    
        setData({  ...data, 
          secureTextEntry: val,
          passwordText:'password'
          });
    
      }
    
      
      }
    
         
  
      const UpdateCardPin =() => {
      
        setActivateLoader(true);  
        setIsLoading2(true); 
          var authUserToken =  localStorage.getItem('authUserToken');


              const payloadData = {   
              userToken  :authUserToken, 
              cardpin    :md5(newCardPin),
              cardpin2   :md5(confirmCardPin)
              };
        
       
                    if(newCardPin.length == 0 || confirmCardPin.length == 0)
                    {
                    setActivateLoader(false);  
                    setIsLoading2(false); 
        
        
                    setMessage('All form fields are required. Please enter numeric pin. Thanks');
                    setMessageSwitch(true)
                    }
                    else
                    {
                       
                              if(newCardPin  === confirmCardPin )
                              {
                  
                                    var headers = {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                    }
                        
                                    fetch(BaseAction+"CardPinCreate",
                                    {
                                    method:'POST',
                                    header:headers,
                                    body: JSON.stringify(payloadData)
                                    })
                                    .then((response) => response.json())
                                    .then((response) => {
                        
                           
                        
                        
                                    if(response[0].success == 1){
                        
                                    // setMessage(response[0].feedback);
                                    // setMessageSwitch(true)  
                                    // reloadUserData();
                                    //LoginOptionUpdate();
                                    }else{
                        
                                    setMessage(response[0].feedback);
                                    setMessageSwitch(true)
                                    }
                        
                                     }); 
                              }
                              else
                              {
                                  setActivateLoader(false);  
                                  setIsLoading2(false); 
                      
                      
                      
                                  setMessage('Your new credit card PIN  do not match');
                                  setMessageSwitch(true)
                              }   
          
        
                    }
        
        
        
        
        }
      

  
    
 
       

        const  ReLoadHandulerApi =() =>{

          setMessageSwitch(false) 
          reloadUserData();
        
        }
         
      
     
 const showHandler =()=>{

  setActivateLoader(false);
  setMessage('')
  setNewCardPin('');
  setConfirmCardPin('');
}  

 
 

if(activateLoader)
{ 


    if(isLoading2)
    {


      return (     

            <div> 
              <Loading  /> 
            </div>
          );


    }
    else
    {
        return (         
            <div> 
                <Loading2   Show={showHandler} >
                    <small style={{color: 'black',fontSize:13}}>{message} </small>
                </Loading2> 
            </div>
        );
    }


}
else
{ 

                return (
                  <div  style={{textAlign:"left",padding: 20,height:'100vh'}} >

 
         

                  <div  className="taskContainer">
                      <div className="Container--acup"></div>
                 
                
                <h2 style={{textAlign:'center',paddingTop: '10px'}}>VIRTUAL CREDIT CARD  </h2>
                <h4 style={{textAlign:'center',paddingTop: '10px'}}>CREATE NEW PIN</h4>
                
                <div className="Container--pch--body">

                <div className="Text" style={{paddingTop: '15px'}}>
                Hi {loginData.fullname}, to continue please create your credit card transaction PIN
                . Do not disclose your transaction PIN code to anyone
                </div>

                <br /><br />
                <div   style={{marginTop: 5,marginBottom:10,alignItems:'center'}} >
                <img style={{width:300,height:200,alignItems:'center',borderRadius:10}}  src={CardFrontUrl+loginData.card_img} />
                </div>
                <br /> 
                <br />
              <Form>
  
               
  
              <Form.Group className="mb-3" controlId="formBasicPassword">
              <div>Enter New Pin</div>
                <Form.Control type={data.passwordText}     style={{width:150}}  maxLength={4}  onChange={e=> setNewCardPin(e.target.value)}  />
              </Form.Group>
  
   
               <div> Confirm New Pin</div>
              <div style={{display:'flex',flexDirection:'row',height:50,}}>
             
                                           
             
              <div style={{ textAlign:'left',}}>
              
                    <Form.Control   
                    onChange={e=> setConfirmCardPin(e.target.value)} 
                    value={data.confirmCardPin}   
                    type={data.passwordText}
                    style={{width:150,fontSize:15,color:'black',textAlign:'left'}}  
                    maxLength={4}
                    />                               
              </div>

              <Button   style={{height:35,marginLeft:5,textAlign:'left',}}>
                    <div     >
                          {data.secureTextEntry ?

                          <FaRegEyeSlash  className="passwordHide" style={{color:'white',width: '100%',height:18}}                                            
                          size={20}
                          onClick={()=>updateSecureTextEntry('hide')} 
                          /> 
                          :
                          <FaRegEye  className="passwordHide" style={{color:'white',width: '100%',height:18}}  
                          size={20}
                          onClick={()=>updateSecureTextEntry('open')} 
                          /> 
                          }
                    </div>   
              </Button>

          </div>
  
              <div onClick={UpdateCardPin}   className="button" >
               CREATE PIN
              </div> 
  
  
            </Form>
  
  
  
              <br /><br />
              </div>
                 </div>



                              {///SINGLR BUTTON MODAL (PERSONAL MESSAGE) 1111
                                messageSwitch?
        
              
                                <Modal  show={true}     >
                                <div >
              
                                            <div   onClick={() => setMessageSwitch(false)}  >
                                            <IoCloseCircleOutline  size={30}  color="black" style={{margin:20}}/>
                                            </div>  
              
                                <div >
                                
                                <FaEnvelopeOpenText   size={20}  color={MainColor} /> 
                                      <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>  NOTIFICATION</div>
                                      
              
              
                                      <div   style={{marginTop: 20}} >
                                      
                                            <div  style={{fontSize: 14}} >{message}</div>
                                      </div>
                                      
                                      </div>
                                      </div>
                          
                                </Modal>
                                :  
                                null
              
                                }


              </div>
                
 
                )      
      }   

}

