import React, { useState, useEffect,useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom'
import '../Dashboard.css'; 
import '../../../App.css'; 
import { Modal}            from 'react-bootstrap';
import { FaRegCreditCard, FaRegEyeSlash,FaRegEye } from "react-icons/fa"; 
import { FaRegCopy } from "react-icons/fa";
import { RxSwitch } from "react-icons/rx";
import { RiMessage3Fill } from "react-icons/ri";
import { MainColor,Naira,CardFrontUrl,CardBacktUrl } from '../../../BaseUrl';
import {FloatingLabel,Card, Table, Form,Button} from 'react-bootstrap';
import { AuthContext } from '../../context';
import {CopyToClipboard} from 'react-copy-to-clipboard';
 

const GetVirtualCard = (props) => {  

const [loginData, setLoginData] = useState([]); 
const[switchCreditCard, setSwitchCreditCard] = useState(true); 
const[switchBalance, setSwitchBalance] = useState(false); 
const[messageSwitch, setMessageSwitch] = useState(false);
const[message, setMessage] = useState(''); 
  

  const navigate = useNavigate();      
 
  const NumberFomat = (val) =>{

    var value =  val* 1; 

    if(val >= 1){

     var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
     return   Naira+result;  
     
    }else{

      return   Naira+'0.00'; 

    }
       
     
    
}

    const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
    
  }
  
  useEffect(() => {
        
  
  
  
  setTimeout(async() => {
  
        LoginOptionUpdate();  
        
  
  }, 5000);
  
  
                    
  });
  
  useLayoutEffect(() => {
  
        LoginOptionUpdate();
        
  },[]); 
 
 
  const copyToClipboard =(text) => {

   
     setMessage('Credit card number copied '+ text);         
     setMessageSwitch(true);

 };

  
      return (

        <div  style={{textAlign:"center",padding: 20,height:'100vh'}} >

        <div >
        {loginData.mem_fee == 'paid'?
            
        <div>
                  
     

                 {
                   switchCreditCard?
                   <div   style={{marginTop: 5,marginBottom:10,alignItems:'center'}} >
                   <img style={{width:300,height:200,alignItems:'center',borderRadius:10}}  src={CardFrontUrl+loginData.card_img} />
                   </div>
                   :
               
                   <div   style={{marginTop: 5,marginBottom:10,alignItems:'center'}} >
                   <img style={{width:300,height:200,alignItems:'center',borderRadius:10}}  src={CardBacktUrl+loginData.card_img} />
                   </div>
             
                 }
        </div>


         

         : 

         <div> 
               <img src="images/verified.png"  style={{width:300,height:200,alignItems:'center',borderRadius:10}}  /> 
         </div>

         }
    
 </div>

 <div   style={{backgroundColor:MainColor,width: '100%',borderRadius:5,paddingTop:10,paddingBottom:15}} >
                        

 <div  style={{display:'flex',flexDirection:'row',width: '100%',marginBottom:20}}>

             <div  style={{width: '30%',marginLeft:20 }}>   
             <div onClick={() => setSwitchBalance(!switchBalance)}  > 
            
             { switchBalance?
                   <div style={{color:'white',fontSize:12}}>
                   <FaRegEye    size={20} style={{ color: 'white',textAlign:'center'  }}  />   Card Bal  
                   </div>  
                   :
                   <div style={{color:'white',fontSize:12}}>
                   <FaRegEyeSlash    size={20} style={{ color: 'white',textAlign:'center'  }}  />   Card Bal  
                   </div>  
                    
             }
                                         
             </div>                                
             </div>


             <div  style={{width: '30%', }}>   
             <div onClick={() => setSwitchCreditCard(!switchCreditCard)}  >                                  
             <div style={{color:'white',fontSize:12}}>
             <RxSwitch    size={20} style={{ color: 'white',textAlign:'center'  }}  />  Switch Card</div> 
             </div>                                
             </div>
                                                            

             <div onClick={()=> copyToClipboard(loginData.card_digit)} style={{width: '30%', }}>   
             
             <CopyToClipboard   text={loginData.card_digit}> 
               <div style={{color:'white',fontSize:12}}>
               <FaRegCopy    size={20} style={{ color: 'white',textAlign:'center'  }}  /> Copy Card No</div> 
              
             </CopyToClipboard>                                  
                        
             </div>                                                           
 
 </div>






       <div>
       <div style={{ alignItems:'center'}}> 

       <div style={{fontWeight: 'bold',color:'white',fontSize:15, paddingBottom: 20,}}>VIRTUAL CREDIT CARD    </div>


       
       
       {loginData.mem_fee == 'paid'?
                    
       <div style={{fontSize:25,color:'#fff',padding:10,fontWeight:'bold'}}>
       {switchBalance?
             <div>
                {NumberFomat(loginData.vcard_bal)}
             </div>
        :
             <div>
                 ****   
             </div>                                                
       
       }
       
       <div style={{fontSize:14,color:'#fff',}}> Card Bal</div>
       </div> 
             
       :
       <div style={{fontSize:30,color:'#fff',padding:10}}>****  <div style={{fontSize:14,color:'#fff',}}>Card Bal</div></div>
       }      
 
             
       
       </div>


       
       
       
       </div>

 



</div>
                     {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                      messageSwitch?
      
            
                      <Modal  show={true}  >
                      <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
  
          

          
                             <div>

                      
                            <RiMessage3Fill    size={30}  color={MainColor} /> 
                            <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                      
                            
          
          
                            <div   style={{marginTop: 20}} >
                            
                                  <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                            </div>

 
                                <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                    <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                    <div style={{color:'white'}}>close</div>
                                    </div>  
                                </div>
                           



                            
                            
                            </div>
                      </div>
                
                      </Modal>
                      :  
                      null
          
                      }   
                      


          </div>
      )
   
 }

export default GetVirtualCard; 

 