import React, { useState, useEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom'  
import {BaseAction, SubscriptionUrl,MainColor} from '../../../BaseUrl'; 
import Loading          from "../../Loading";
import Loading2          from "../../Loading2";
import { Modal, Button } from 'react-bootstrap'; 
import { FaWhatsapp } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";



const FundCreditCard = (props) => {  
const navigate = useNavigate();   
const[loginData, setLoginData] = useState([]); 
const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 
const[message, setMessage] = useState(''); 
const[messageSwitch, setMessageSwitch] = useState(false); 
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false); 


  const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  

  setLoginData(data);
    if(data.card_main_status == 'passive'){
      navigate('/')
 
    }
    
  }
  
  useEffect(() => { 
  setTimeout(async() => { 
        LoginOptionUpdate();   
  }, 5000);
  
  
                    
  });
  
  useEffect(() => { 
    LoginOptionUpdate(); 
  },[]); 
 
  const FetchTopUpHistory = () => {

    setActivateLoader(true);  
    setIsLoading2(true);  
  
  
  
            var headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
      
           fetch(BaseAction+"FetchTopUpHistory",
                {
                method:'GET',
                header:headers,
        
                })
                .then((res) => res.json())
                .then((response) => {

              

                      if(response[0].success == "0")
                      {
                      
                            setActivateLoader(false);  
                            setIsLoading2(false);  
  
                            
                            setMessage(response[0].feedback);  
                            setMessageSwitch(true) 
                      }
                      else 
                      {

                        setTopUpAgentHistoryData(response); 
                        setMessageSwitch(true);
                        
                        setActivateLoader(false);  
                        setIsLoading2(false);  
                      }



                })  
                .catch(error => {
                  
                    setActivateLoader(true);  
                    setIsLoading2(false); 
                });
        
            

    //  Keyboard.dismiss();
 


    
}

const showHandler =()=>{

  setActivateLoader(false);
  setMessage('')
}
const starDisplay =(val)=>{

 if(val == 4){
  return <span> <FaRegStar color={MainColor} /> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
 }else if(val == 3){
  return <span><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>

 }else if(val == 2){
  return <span> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>

 }else if(val == 1){
  return <span> <FaRegStar color={MainColor} /></span>

 }else if(val == 0){
  return <span></span>

 }




}


const CreditCradRequest=()=>{
var userToken =  localStorage.getItem('authUserToken');
window.location.href='payment-link?fullname='+loginData.fullname+"&username="+loginData.username+"&email="+loginData.email+"&cardTopUp=cardTopUp&userToken="+userToken;

}


 //fund-credit-card
 if(activateLoader)
{ 


    if(isLoading2)
    {


      return (     

            <div> 
              <Loading  /> 
            </div>
          );


    }
    else
    {
        return (         
            <div> 
                <Loading2   Show={showHandler} >
                    <small style={{color: 'black',fontSize:13}}>{message} </small>
                </Loading2> 
            </div>
        );
    }


}
else
{ 


        return (

        <div  style={{AlignItems:"center",padding: 20,height:'100vh'}} > 
        
   


          <div   style={{marginLeft:'10%',width:'100%',marginTop: 100,flexDirection:'column',display:'flex',justifyContent:'space-around'}} >




              <div  className="button"  style={{padding:10,fontSize:14,width:'80%',}}  onClick={() => FetchTopUpHistory()}  > 
              <div style={{color:'white'}}>FUND CREDIT CARD VIA AGENT </div>
             </div>
             
             


              <div  className="button"  style={{padding:10,fontSize:14,width:'80%',backgroundColor:'black',marginTop:100}}  onClick={() => CreditCradRequest()}  > 
              <div style={{color:'white'}}>FUND CREDIT CARD VIA BANK </div>
             </div>
             
             


          </div>


                  {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                    messageSwitch?


                    <Modal  show={true}  >
                          <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  > 
                            <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                          </div>
                           <div>
                           <div style={{fontSize:16,textAlign:'center',fontWeight:'bold'}}>  CREDIT CARD TOP-UP AGENT  </div>
                           <div style={{fontSize:12,textAlign:'center',padding:5}}>
                           Our agent can help you top up your virtual credit card if you are having
                           difficulties using with your bank account  </div>
                           </div><br />

                            <div>

                      
        
                          {
                            topUpAgentHistoryData.map((item) => (
                                <div>
                

                                <div   style={{textAlign:'left',backgroundColor:'#f2f2f2',paddingLeft:20,paddingBottom:20,fontSize:12}}>

                                <Link to={'https://api.whatsapp.com/send?phone=234'+item.phone} style={{textDecoration:'none'}}>
                                <div style={{flexDirection:'row',width: '100%'}}>  
                                <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                               Agent Status: ({
                                    item.status== 'busy'?
                                    <span style={{color:MainColor,}}>Busy can't take a deal</span>
                                    :
                                    item.status== 'close'?
                                    <span style={{color:MainColor,}}>Not open to deal</span>
                                    :
                                    item.status== 'open'?
                                    <span style={{color:MainColor,}}>Open to take deal</span>
                                    :
                                   null
                                }) </div>
                            </div>


                            <div style={{flexDirection:'row',width: '100%'}}>  
                                <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                ADENT ID:({   item.agent_code  }) 
                                </div>
                            </div>
                    
                                      <div style={{flexDirection:'column',width: '99%', }}>
                                             
                    
                                      <div    style={{flexDirection:'column',width: '100%'}}>
                                      <div  style={{color:'black',}}>Agent name:{item.name}</div>    
                                      <div  style={{color:'black',}}>Phone number:0{item.phone} </div>    
                                      <div  style={{color:'black',}}>whatsapp Only  <FaWhatsapp size={20} style={{ color: MainColor,textAlign:'center'  }}  />  </div>    
                                      <div  style={{color:'black',}}>Agent Rate:{starDisplay(item.rate)} </div>  
                                      </div> 
                    
                    
                    
                    
                    
                    
                                      </div>                        
                                </Link>
                    
                          </div>  
                                  
                                </div> 

                              ))
                            }  
                            
                          
                        
                            </div>
              
                    </Modal>
                    :  
                    null
        
                    } 

                                            


        </div>
      )
   
  } 

}
export default FundCreditCard; 

  