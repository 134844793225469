import React from 'react'; 
import {FaEnvelopeOpenText,} from "react-icons/fa";  
import '../App.css';   


 
export default function Loading2(props) {
  return (
  
    <div className="Backdrop "  onClick={props.Show}>
      <center>
              <div className="modalMessage">
              <FaEnvelopeOpenText className="blink_me"   /><br/> 
                <br  />  
                <span  style={{color: 'black',fontSize:13}}> {props.children}  </span>
            </div>
      </center>


    </div>
    

  );
}
 