import React, { useState, useEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom'
import '../Dashboard.css'; 
import '../../../App.css'; 
import { Modal}            from 'react-bootstrap'; 
import { FaRegStar,FaWhatsapp } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiMessage3Fill } from "react-icons/ri"; 
import { IoCloseCircleSharp } from "react-icons/io5";
import { AbuseEmail,Naira,BasePageAction,MainColor,BaseAction } from '../../../BaseUrl';
import Loading1          from "../../Loading";
import Loading2          from "../../Loading2";
import md5               from 'md5-hash';
import {FloatingLabel,Card, Table, Form,Button} from 'react-bootstrap';
import { AuthContext } from '../../context';
 
 

const CashoutToAgent = (props) => {  
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false);
const[loginData, setLoginData] = useState([]); 
const[switchBalance, setSwitchBalance] = useState(true); 
const[messageSwitch, setMessageSwitch] = useState(false);
const[message, setMessage] = useState(''); 
const[requestLoan, setRequestLoan] = useState(false);
const[cardCashout, setCardCashout] = useState(false);
const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 

  const navigate = useNavigate();  
  const { reloadData ,reloadUserData} = React.useContext(AuthContext);
  const [cashoutAmt, setcashoutAmt] = useState('');
  const[messageSwitchTopUp, setMessageSwitchTopUp]   = useState(true); 
  const [virtualCardPin, setVirtualCardPin] = useState(''); 
  const [otp, setOtp] = useState('');
  const [show, setShow] = useState(false);
  const [receiverCardNo, setReceiverCardNo] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const[data, setData] = useState({
    newCardPin: '',
    confirmCardPin: '',
    otpCode: '',
    adminFee: '',
    cashoutAmt: '',  
    receiverCardNo: '',  
    virtualCardPin: '',  
    OTP: '',  
 });

 
  const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
 
  }
  
  useEffect(() => { 
  setTimeout(async() => { 
        LoginOptionUpdate();   
  }, 5000);
  
  
                    
  });
  
  useEffect(() => { 
    LoginOptionUpdate(); 
    FetchTopUpHistory();
  },[]); 
 
 

const starDisplay =(val)=>{

    if(val == 4){
     return <span> <FaRegStar color={MainColor} /> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
    }else if(val == 3){
     return <span><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
   
    }else if(val == 2){
     return <span> <FaRegStar color={MainColor} /><FaRegStar color={MainColor} /></span>
   
    }else if(val == 1){
     return <span> <FaRegStar color={MainColor} /></span>
   
    }else if(val == 0){
     return <span></span>
   
    }
   
   
   
   
   }


const cashoutCalculation = (val) =>{
     
  var valModify =  parseFloat(val.replace( /[^\d\.]*/g, ''));

    var adminFee =  valModify / 100 * 15; 
 

    if(valModify  == '' || isNaN(valModify)){

          setData({ 
          ...data, 
          adminFee:0,
          cashoutAmt:0

          })

    }
    else
    {

        
                setData({ 
                ...data, 
                adminFee:adminFee,
                cashoutAmt:valModify

                })           
                


    }
      
     
}


const NumberFomat = (val) =>{


  
  if( val > 0){
    var value =  val* 1; 
    var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return  Naira+result;  
}else{

    var val = 0
    return  Naira+val.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  
       
     
    
}



   
 
 

const showHandler =()=>{

setShow(false);
reloadUserData() ;
}

   

const FetchTopUpHistory = () => {
  
    setActivateLoader(true);  
    setIsLoading2(true);  
  
  
  
            var headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
      
           fetch(BaseAction+"FetchTopUpHistory",
                {
                method:'GET',
                header:headers,
        
                })
                .then((res) => res.json())
                .then((response) => {

              

                      if(response[0].success == "0")
                      {
                      
                            setActivateLoader(false);  
                            setIsLoading2(false);  
  
                            
                            setMessage(response[0].feedback);  
                            setMessageSwitch(true) 
                      }
                      else 
                      {

                        setTopUpAgentHistoryData(response); 
                        setMessageSwitchTopUp(true);
                        
                        setActivateLoader(false);  
                        setIsLoading2(false);  
                      }



                })  
                .catch(error => {
                  
                    setActivateLoader(true);  
                    setIsLoading2(false); 
                });
        
            

    //  Keyboard.dismiss();
 


    
} 

 
const confirmPinCashout = () => {
  setRequestLoan(false)

  setActivateLoader(true);  
  setIsLoading2(true); 

  var userPasskey =  localStorage.getItem('authUserToken');
  
if(data.virtualCardPin == '' ){
  setActivateLoader(false);  
  setIsLoading2(false); 
 
  setMessage('All fields are required');
  setMessageSwitch(true);
}
else
{

if(data.cashoutAmt >= 1000)
{
   
  const loadData = {   
        cashoutAmt       :data.cashoutAmt, 
        virtualCardDigit :loginData.vcard_digit,  
        userToken        :userPasskey,
        admin_fee        :data.adminFee,
        virtualCardPin   :data.virtualCardPin,
        bank_name        :loginData.bank_name,
        acct_name        :loginData.account_name,
        acct_no          :loginData.account_no,
        agent_id         :data.receiverCardNo,
      };
  
  
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      }
        
   if(loginData.card_lock == 'locked')
  {
   
        setActivateLoader(false);  
        setIsLoading2(false); 
    
    
        setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
        setMessageSwitch(true)
        
         
    
  }
  else
  {
        
              fetch(BaseAction+"accountAgentCashout",
              {
              method:'POST',
              header:headers,
              body: JSON.stringify(loadData)
              })
              .then((response) => response.json())
              .then((response) => {

                    setActivateLoader(false);  
                    setIsLoading2(false); 

                if(response[0].success == 'ok'){
                   
                    
                    setMessage(response[0].feedback); 
                    setMessageSwitch(true);
  
                }else{
  
                    
                    setMessage(response[0].feedback); 
                    setMessageSwitch(true);;
                }
                
            }); 
  
  }
       
}
else{
  setActivateLoader(false);  
  setIsLoading2(false); 
 
  setMessage('Minimum amount to cashout is ₦1,000.00');
  setMessageSwitch(true);
}

}
  

      
}

 
const searchRecieverData = (val) => {
 

   
    if(val.length == 6){
            setActivateLoader(true);  
            setIsLoading2(true); 
      
              setReceiverCardNo(val);
              setData({
                ...data,
                receiverCardNo:val
              })
  
        if( val === loginData.card_digit){
            setActivateLoader(false);  
            setIsLoading2(false); 
  
          setMessage("Error: you have passed your virtual credit card digits to receiver input.You can't send money to your self"); 
          setMessageSwitch(true)
         }
         else
         {
  
  
  
         var authUserToken =  localStorage.getItem('authUserToken');
  
          var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
  
            fetch(BasePageAction+"searchToUpAgentData&agent_id="+val+"&userToken="+authUserToken,
            {
            method:'GET',
            header:headers
            })
            .then((response) => response.json())
            .then((response) =>{
                  
  
  
                    if(response[0].success == 'ok'){
  
                      setReceiverName(response[0].feedback); 
  
                    } 
                    else
                    {
                      setReceiverName(''); 
                      setReceiverCardNo('');
                      setData({
                        ...data,
                        receiverCardNo:''
                      })

                      setMessage(response[0].feedback); 
                      setMessageSwitch(true)


                    }


                    setActivateLoader(false);  
                    setIsLoading2(false); 
  
            }); 
  
      }
  
  
       
  }
   
  
       
  }
  
   
//CASHOUT FROM CREDIT CARD
const CashoutFromCreditCard = () => {
     

      

  if( data.cashoutAmt.length == 0  || data.adminFee.length == 0  || data.receiverCardNo.length == 0 || receiverName.length == 0)
  {
       
        setMessage('All fields are required'); 
         setMessageSwitch(true);
  }
  else
  { 
    setRequestLoan(true)
                
  }
  
        
  }
  
  const totoalCal =()=>{
     
    var tot = parseInt(data.cashoutAmt) + parseInt(data.adminFee);
    return tot;
  }
 
  const cardPinCheck =(text)=>{
     
   
    var newPass = text.toString();
      
    var newPin =  md5(newPass);


    setData({...data, virtualCardPin:newPin})

    return newPin;
  }
 

 

 

 
    
if(activateLoader){

      if(isLoading2)
      {


        return (     

              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );


      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }





}
else
{ 

 
        return (

        <div  style={{textAlign:"center",padding: 20,height:'100vh'}} >

 
        <div   style={{backgroundColor:MainColor,width: '100%',borderRadius:5,paddingTop:10,paddingBottom:15}} >
                        

   
       
       
       
       
       
       
              <div>
              <div style={{ alignItems:'center'}}> 
       
              <div style={{fontWeight: 'bold',color:'white',fontSize:15, paddingBottom: 20,paddingTop: 50,}}>CASHOUT TO TOP-UP AGENT    </div>
       
       
              
              
              {loginData.mem_fee == 'paid'?
                           
              <div style={{fontSize:25,color:'#fff',padding:10,fontWeight:'bold'}}>
              {switchBalance?
                    <div>
                       {NumberFomat(loginData.vcard_bal)}
                    </div>
               :
                    <div>
                        ****   
                    </div>                                                
              
              }
              
              <div style={{fontSize:11,color:'#fff',}}>Credit Card Bal</div>
              </div> 
                    
              :
              <div style={{fontSize:30,color:'#fff',padding:10}}>****  <div style={{fontSize:14,color:'#fff',}}>Card Bal</div></div>
              }      
        
                    
              
              </div>
       
       
              
              
              
              </div>
       
        
       
       
       
       </div>





                <div  className="taskContainer"> 
           
          
      
          <div className="Container--pch--body">
          
        <Form style={{textAlign:"left"}}>

         
         <br /><br /><br /><br />


        <div style={{color:MainColor,fontSize:16,fontWeight:'bold'}}>Top-Up Agent details  </div>

     <Form.Group className="mb-3" controlId="formBasicPassword" style={{marginTop:20}} >
     <div>Enter top-up agent ID</div>
       <Form.Control type="text"  placeholder={data.receiverCardNo}      onChange={e=>  searchRecieverData(e.target.value)}    />
     </Form.Group>


     <Form.Group className="mb-3" controlId="formBasicPassword">
     <div>Top-up agent fullname </div>
       <Form.Control type="text"   value={receiverName}      disabled/>
     </Form.Group>
 

        <br/><br/><hr/><br/><br/> 
     

        <Form.Group className="mb-3" controlId="formBasicPassword"> 
        <div>Enter cashout amount</div>
          <Form.Control type="number"   placeholder={NumberFomat(data.cashoutAmt)}    onChange={e=>  cashoutCalculation(e.target.value)}      />
        </Form.Group>


       <Form.Group className="mb-3" controlId="formBasicPassword">
       <div>Agent fee</div>
         <Form.Control type="text"       value={NumberFomat(data.adminFee)}        disabled />
       </Form.Group>

 
 

 
           {//
            Number(loginData.vcard_bal) >= totoalCal()?

                   Number(data.cashoutAmt) <= 0 ?
                    <div     className="button"   style={{backgroundColor:'red'}}>
                    Enter Valid Amount 
                    </div> 
                    :
                    <div   onClick={() => {CashoutFromCreditCard()}}     className="button" type="submit">
                    Continue
                    </div> 

             :
                 
                   <div        className="button"  style={{backgroundColor:'red'}} >
                   Insufficient fund
                   </div> 

           }

           <br/>
           <div   onClick={() => {FetchTopUpHistory()}}     className="button"  style={{backgroundColor:MainColor}}>
          Fetch top-up agent
           </div> 
 

   </Form>


        <br /><br />
        </div>
           </div>





                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                  messageSwitch?
      
            
                                  <Modal  show={true}  >
                                  <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
              
                      
            
                      
                                         <div>
            
                                  
                                        <RiMessage3Fill    size={30}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> Message</div>  
                                  
                                        
                      
                      
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                                        </div>
            
             
                                            <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                                <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                                <div style={{color:'white'}}>close</div>
                                                </div>  
                                            </div>
                                       
            
            
            
                                        
                                        
                                        </div>
                                  </div>
                            
                                  </Modal>
                                  :  
                                  null
                      
                                  } 
                                {///SINGLR BUTTON MODAL CARD PIN
                                  requestLoan?
      
            
                                  <Modal  show={true}  style={{paddingLeft:20,paddingRight:20}}>
                                  <div  onClick={() => setRequestLoan(false)}  style={{padding: 20}}  > <AiFillCloseCircle    size={30}  color={MainColor} />  </div>
                            
                                         <div> DEBIT VIRTUAL CREDIT CARD</div><br /> 
                                        <Form style={{textAlign:"left",paddingLeft:20,paddingRight:20}}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div>Credit card digits</div>
                                        <Form.Control type="text"       value={loginData.card_digit}        disabled />
                                        </Form.Group>

                                  

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div>Enter Credit Card PIN</div>
                                          <Form.Control type="password"  maxlength="4"      onChange={e=>  cardPinCheck(e.target.value)}         />
                                        </Form.Group>
 


                                    {
                                          data.virtualCardPin === loginData.card_pin?

                                          <div  onClick={() => confirmPinCashout()} >
                                            <div className="button" style={{marginTop:30,backgroundColor:'green'}}>
                                            <div style={{textAlign:'center',color:'white',fontSize:16,}}>Submit Cashout  </div>
                                            </div>            
                                          </div>
                                          :
                                          <div>
                                            <div className="button" style={{marginTop:30,backgroundColor:'red'}}>
                                            <div style={{textAlign:'center',color:'white',fontSize:16,}}>Enter Invalid PIN </div>
                                            </div>            
                                          </div>

                                    }


                                    <br />   <br /> 
                                        </Form>
                                      
                                  </Modal>
                                  :  
                                  null
                      
                                  } 


                              {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                                messageSwitchTopUp?


                                <Modal  show={true}  >
                                        <div  onClick={() => setMessageSwitchTopUp(false)}  style={{padding: 20}}  > 
                                        <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                                        </div>
                                        <div>
                                        <div style={{fontSize:14,textAlign:'center',fontWeight:'bold'}}>CASHOUT TOP-UP AGENT </div>
                                        <div style={{fontSize:12,textAlign:'center',padding:5}}>
                                        Our top-up agent can pay you instantly when you send money to an agent via Agent ID Code. T&C Apply    
                                        </div>
                                        </div><br />
            
                                        <div>
            
                                    
                    
                                        {
                                        topUpAgentHistoryData.map((item) => (
                                            <div>
                            
            
                                            <div   style={{textAlign:'left',backgroundColor:'#f2f2f2',paddingLeft:20,paddingBottom:20,fontSize:12}}>
            
                                            <Link to={'https://api.whatsapp.com/send?phone=234'+item.phone} style={{textDecoration:'none'}}>
                                                    <div style={{flexDirection:'row',width: '100%'}}>  
                                                        <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                                       Agent Status: ({
                                                            item.status== 'busy'?
                                                            <span style={{color:MainColor,}}>Busy can't take cashout</span>
                                                            :
                                                            item.status== 'close'?
                                                            <span style={{color:MainColor,}}>Not open to take cashout</span>
                                                            :
                                                            item.status== 'open'?
                                                            <span style={{color:MainColor,}}>Open to take cashout</span>
                                                            :
                                                           null
                                                        }) </div>
                                                    </div>


                                                    <div style={{flexDirection:'row',width: '100%'}}>  
                                                        <div style={{color:'black',fontSize:12,fontWeight:'bold',textDecorationLine:'underline'}}>
                                                        ADENT ID:({   item.agent_code  }) 
                                                        </div>
                                                    </div>
                                
                                                    <div style={{flexDirection:'column',width: '99%', }}>
                                                            
                                
                                                    <div    style={{flexDirection:'column',width: '100%'}}>
                                                    <div  style={{color:'black',}}>Agent name:{item.name}</div>    
                                                    <div  style={{color:'black',}}>Phone number:0{item.phone} </div>    
                                                    <div  style={{color:'black',}}>whatsapp Only  <FaWhatsapp size={20} style={{ color: MainColor,textAlign:'center'  }}  />  </div>    
                                                    <div  style={{color:'black',}}>Agent Rate:{starDisplay(item.rate)} </div> 
                                                    </div> 
                                
                                
                                
                                
                                
                                
                                                    </div>                        
                                            </Link>
                                
                                        </div>  
                                                
                                            </div> 
            
                                            ))
                                        }  
                                        
                                        
                                    
                                        </div>
                            
                                </Modal>
                                :  
                                null
                    
                                } 

          </div>
      )
   
 }


}
export default CashoutToAgent; 

 