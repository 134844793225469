import React, { useState,useEffect, useRef,useLayoutEffect,memo} from 'react';
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom';
import { MdPending } from "react-icons/md";  
import { AiFillCloseCircle} from "react-icons/ai";  
import { FaRegMoneyBillAlt,FaCoins} from "react-icons/fa";  
import { IoIosCloseCircle,IoIosOptions} from "react-icons/io";  
import {BsFillBellFill,BsSearch,BsFillHandThumbsUpFill} from "react-icons/bs";   
import {Naira, BaseAction, MainColor, BasePlaceholder} from '../../../BaseUrl';
import {Card, Modal} from 'react-bootstrap';
import { AuthContext } from '../../../components/context';
import Loading1          from "../../Loading";
import Loading2          from "../../Loading2"; 
import md5               from 'md5-hash';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

//TransferHistory
const TransferHistory = (props) => {
  const[activateLoader, setActivateLoader]   = useState(true); 
  const[isLoading2, setIsLoading2]   = useState(true);
  const[tradeCoinData, setTradeCoinData] = useState([]);
  const[loginData, setLoginData] = useState([]); 
  const[messageNote, setMessageNote] = useState(false);
  const[challenger, setChallenger] = useState(''); 
  const[contestant, setContestant] = useState(''); 
  const[tradeCoinModal, setTradeCoinModal] = useState(false); 
  const[message, setMessage] = useState(''); 
  const[messageSwitch, setMessageSwitch] = useState(false);
  const[viewReceipt, setViewReceipt] = useState(false);
  const[searchByName, setSearchByName] = useState(true);
  const[filterGetAllCountry, setFilterGetAllCountry] = useState([]);
  const[searchBar, setSearchBar] = useState(true);
  const searchRef = useRef();
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState('')


    const navigate = useNavigate();    

    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
    setLoginData(data);
      
    }
    
    useEffect(() => {
          
        setTimeout(async() => {
        
            LoginOptionUpdate();  
        
        }, 5000);
                        
    });
    
    useLayoutEffect(() => {
    
          LoginOptionUpdate();
          
    },[]); 
  
  
    $(document).ready(function () {
      $('#example').DataTable();
      });
    
      
 //var authUserToken =  localStorage.getItem('authUserToken');;
 const NumberFomat = (val) =>{

  var value =  val* 1; 

  if(val >= 1){

   var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   return   Naira+result;  
   
  }else{

    return   Naira+'0.00'; 

  }
     
   
  
}
  
 
  

  const onSearchLoan = (text) => {

// 
    if (text == '') {
      
        setFilterGetAllCountry('');
  
    } 
    else
    {
  
      let tempList = data.filter(item => {
        return item.trans_ref_id.toLowerCase().indexOf(text.toLowerCase()) > -1;
      });
      setFilterGetAllCountry(tempList);
          
      setTradeCoinModal(true)
    }
  
  
  
  }
    
  
  const GoldenCoinConvert = (val) => {
  
  var NewVal = val / 100 ;
  
  return NewVal;
  
  }

 
  const reloadPage = () => {
  
    window.location.reload();
  
  }

 
  const RateAgentCashout = (val) => {
     
   
  
  
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
        var authUserToken =  localStorage.getItem('authUserToken')
        fetch(BaseAction+"RateAgentCashout&token="+authUserToken+"&cashout_id="+val,
       {
       method:'GET',
       header:headers
       })
              
             .then((response) => response.json())
             .then((response) =>
             {  
 

              setMessage(response[0].feedback);
                setMessageSwitch(true) 
        
           
  
           })
           .catch((error) => { 
               setIsLoading2(false);   
               
             });
   
  
  
  
  
  }



  const callAllData = () =>{
     
    setActivateLoader(true);
    setIsLoading2(true);
  
  
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
        var authUserToken =  localStorage.getItem('authUserToken')
        fetch(BaseAction+"FetchAllTransactionHistory&token="+authUserToken,
       {
       method:'GET',
       header:headers
       })
              
             .then((response) => response.json())
             .then((responseJson) =>
             {  
                
            
                 
              //setMessageNote(true)
                 setData(responseJson);
       
                 setActivateLoader(false);
                 setIsLoading2(false);
       
           
           
  
           })
           .catch((error) => { 
               setIsLoading2(false);   
              
             });
   
  
  
  
  
  }
  
  useLayoutEffect(() => {

    let runNow = true
   
      
        if(runNow){ 
  
        callAllData();
  
        }
        
      
  
  
      return () => {
        runNow = false;
      }; 
   
   
   
    },[tradeCoinData]);



  const  ChallengUser =(val,val2) =>{

    var newUse = Number(loginData.username) + Number(191);
    var newPass = newUse.toString();    
    var username= md5(newPass);
      
    setChallenger(loginData.username);
    setContestant(val);

    if(loginData.user_coin >= 100)
  {
            if(username === val )
            {
              setMessageNote(true)
              setMessage('Sorry!! you can not challenge your self');
              setMessageSwitch(true);            
            }
            else
            {
              setMessageNote(false)
              setMessage('Are you sure you want to challenge '+val2+'  ?');
              setMessageSwitch(true);              
            }

  }
  else
  {
    setMessageNote(true)
    setMessage('Hi' +loginData.fullname +',you need minimum of 1 box game coin to challenge user. You can load your  box game coin wallet from your credit card');
    setMessageSwitch(true); 

  }

}   


const  QuestChallenge =() =>{
setMessageSwitch(false);


 
var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
fetch(BaseAction+"QuestPublicClick&contestant="+contestant+"&challenger="+challenger,
  {
  method:'GET',
  header:headers
  })
         
        .then((response) => response.json())
        .then((response) =>
        {  
                       
       
          // setActivateLoader(false);  
          // setIsLoading2(false);   
          setMessageNote(true)
          setMessage(response[0].feedback);
          setMessageSwitch(false);
      })
      .catch((error) => { 
        // setActivateLoader(true);  
        //   setIsLoading2(false);   
        });





}   


 

 

 

const showHandler =()=>{
  setMessage('');
  setActivateLoader(false);
  setIsLoading2(false);
  

}

   

 

  
if(activateLoader){
  
      if(isLoading2)
      {
  
  
        return (     
      
              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );
  
  
      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }
  
  
  
  

    }
else
{ 

    return (
  
          <div class="table-responsive">
  
              <Row style={{paddingTop:20,}}>
              <Col style={{fontSize:20,marginBottom:10,}}>
             TRANSACTION HISTORY
              </Col> 
            </Row>

             <table id="example"   class="table-responsive table table-bordered table-striped display"  style={{padding:15,width:'100%',marginTop:50}}>
          
             <thead  style={{backgroundColor:MainColor}} >
               <tr   style={{textAlign: 'left',backgroundColor:MainColor,}}>
                
                 <th  style={{ width: '40%',padding:15 }}> </th> 
               </tr>
             </thead>
   
             <tbody  > 
               {               
                data.map(item => {
                 return (
                   <tr       style={{textAlign: 'left'}} >
                      {item.success == 'success'? 
                        <td  style={{textAlign: 'center'}}>
                            No transaction data found
                        </td> 
                      :
                        <td>
                                <div  style={{display:'flex',flexDirection:'row',flexDirection:'space-between'}}>
                                
                                <div  style={{width:'20%'}}> 
                                      <div className="button" onClick={()=> onSearchLoan(item.trans_ref_id)}    style={{fontSize:12,width:'80%',backgroundColor:'green',marginTop:5}}>View </div> 
                                </div>
                              
                                <div  style={{width:'40%'}} className="text"> 

                                      <span style={{fontWeight:'bold',fontSize:14}}>{ item.trans_ref_id} </span>
                                      <br/>
                                      <span className="huge_font_sub_text" style={{color:'green',fontWeight:'bold'}}>{ item.trans_caption} <br/> {item.trans_status}</span>
                                                  
                                </div>    
                              
                      
                                <div style={{width:'30%'}} >

                                        {item.trans_sender == loginData.username && item.trans_caption == 'money transfer' || item.trans_caption == 'agent cashout'|| item.trans_caption == 'airtime-data' || item.trans_caption == 'agent cashout'?
                                      <div  style={{textAlign:'right'}}> 

                                                  <span    style={{color:'red',fontWeight:'bold'}}>{NumberFomat(item.trans_amt)}  </span>
                                                  <br/> 
                                                  <span   className="huge_font_sub_text" style={{color:'#777777', }}>{item.trans_date}  </span> 

                                      </div>
                                        :
                                        <div  style={{textAlign:'right'}}> 

                                                <span    style={{color:'green',fontWeight:'bold'}}>{NumberFomat(item.trans_amt)}  </span> 
                                                <br/> 
                                                <span    className="huge_font_sub_text" style={{color:'#777777', }}>{item.trans_date}  </span>  

                                      </div>
                                        }

                                </div>

                                  </div>

                                  <div  style={{width:'100%'}}>
                                  { item.rate_cashout == 'unrate'?
                                      <div onClick={() => RateAgentCashout(item.trans_ref_id)} className="button" style={{fontSize:12,width:'100%',height:30}}>Rate Agent Transaction</div>
                                      :
                                      null
                                  }                                
                                 </div>
                        </td> 
                      }
                   </tr>
                     
                 );
   
                 
               })
              
              }


             </tbody>
   
 
           </table> 
 
  
 


 



                      {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                      messageSwitch?
      
            
                      <Modal  show={true}  >
                      <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
  
          

          
                             <div>

                      
                            <BsFillBellFill    size={30}  color={MainColor} />   
          
                            <div   style={{marginTop: 20}} >
                            
                                  <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                            </div>


                            <div   > 
                              
                              <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                              <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => reloadPage()}  >
                              <div style={{color:'white'}}>close</div>
                              </div>  
                              </div>  
                       
                            </div>
                            
                            </div>
                      </div>
                
                      </Modal>
                      :  
                      null
          
                      }   
                      
                         {/// SHARES TRADING MODAL 252525
                          tradeCoinModal?


                          <Modal show={true}>
                          
                        
  
                          <div onClick={()=> setTradeCoinModal(false)}> 
                          <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> 
                          </div>
  
                          <div style={{color:'black'}}>
  
  
                          {
                            filterGetAllCountry.map((item) => (
                                <div    className="borderBottom "  style={{textAlign:'center',backgroundColor:'#fff',paddingRight:10,paddingBottom:5,borderBottomWidth:2,borderColor: MainColor,}}>
                
    
    
                                
    
                                <div style={{flexDirection:'row',width: '100%',alignItems:'center',}}>
                                
                                
                                
                                
                                <div  style={{borderColor: MainColor,paddingRight:20}}>
                                      
                                      <div style={{alignItems:'center'}}>
                                            <div style={{color:'black',fontSize:14,fontWeight:'bold'}}>RSB DIGITAL SHARES </div> 
                                            <div style={{color:'black',fontSize:14,fontWeight:'bold',marginBottom:25 }}>TRANSACTION RECEIPT </div> 
                                      </div>
                                       
                                      {item.trans_status == 'success'?
                                         <div style={{ marginBottom:25 }}>
                                      <BsFillHandThumbsUpFill  size={50}  color={'green'} />
                                      <p style={{color:'green',textTransform:'uppercase'}}>{item.trans_status}</p>
                                      </div>
                                      :
                                      
                                      <div style={{ marginBottom:25 }}>
                                      <MdPending  size={50}  color={'red'} />
                                      <p style={{color:'green',textTransform:'uppercase'}}>{item.trans_status}</p>
                                      </div>
                                      }


                                      <div    style={{paddingBottom:25,display:'flex',flexDirection:'row',width: '97%',paddingLeft:'3%', }}>
                                     

                                          <div  style={{flexDirection:'column',width: '50%',textAlign:'left',fontSize:14,}}>

                                              <div>Transaction Type </div>
                                              <div>Status </div>
                                              <div>Transaction Ref  </div>
                                              <div>Amount </div>
                                              <div>Fee </div>
                                              <div>Sender </div>
                                              <div>Receiver </div> 
                                              <div>Rating </div>
                                              <div>Date</div>

                                          </div>



                                          <div style={{flexDirection:'column',width: '50%',textAlign:'right',fontSize:14,}}>

                                          <div  style={{color:MainColor, fontSize:13,fontWeight:'bold', textTransform:'uppercase'}}>{item.trans_caption}</div>
                                          <div>{item.trans_status}</div>
                                          <div>{item.trans_ref_id}</div>
                                          <div>{NumberFomat(item.trans_amt)} </div>
                                          <div>{NumberFomat(item.trans_fee)} </div>
                                          <div>{item.trans_sender} </div>
                                          <div>{item.trans_receiver} </div>
                                          <div>{item.rate_cashout} </div>
                                          <div> {item.trans_date} </div>


                          

                                          </div>



                    
                                      </div>    

                                      <div style={{flexDirection:'row',width: '100%',alignItems:'center',}}>
                                      <p>*****************************************</p>
                                        <p style={{fontWeight:'bold'}}> REMARK</p>
                                        <p style={{fontSize:12 }}> {item.trans_msg}</p>
                                      </div>
                                </div>
                    
                    
                                </div>
                    
                        
                       
                             </div> 
    
                              ))
                            } 
                               
                          </div>
              
                          
                          </Modal>
                          :  
                          null
              
                          }
            </div>
      )
  
 }
     
};
  


 
export default memo(TransferHistory);  





          