
import React, { useState, useEffect,memo,useLayoutEffect ,useRef} from 'react';
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom' 
import {TopTabColor,Naira ,AbuseEmail, ReloadBaseAction,BaseAction,LightBlack,MainColor}  from '../../BaseUrl';   
import { IoIosCloseCircle }      from "react-icons/io";  
import { AiFillCloseCircle }     from "react-icons/ai";  
import { FaEnvelopeOpenText }    from "react-icons/fa";  
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { Modal}                  from 'react-bootstrap';
import { AuthContext }           from '../../components/context';
import Loading1                  from "../Loading";
import Loading2                  from "../Loading2";
import md5                       from 'md5-hash';
import {FloatingLabel,Card, Table, Form,Button,Container,Row,Col} from 'react-bootstrap';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col'; 
//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
import './DataAirtimeTransaction.css';



function DataAirtimeTransaction(props) {
const {  reloadUserData, } = React.useContext(AuthContext);
const[activateLoader, setActivateLoader]  = useState(false); 
const[isLoading2, setIsLoading2] = useState(false);
const[messageSwitch, setMessageSwitch] = useState(true);
const[message, setMessage] = useState('Select network to get started');  
const[networkDataSelect, setNetworkDataSelect] = useState(false);
const[networkAirtimeSelect, setNetworkAirtimeSelect] = useState(false);
const[purchaseSelect, setPurchaseSelect] = useState(true);
const[confirmDataPurchase, setConfirmDataPurchase] = useState(false);
const[confirmAirtimePurchase, setConfirmAirtimePurchase] = useState(false);
const[countryModalFetch, setCountryModalFetch] = useState(false);
const[loginData, setLoginData] = useState([]); 
const[oldGetAllCountry, setOldGetAllCountry] = useState([]); 
const[getAllCountry, setGetAllCountry] = useState([]); 
const searchRef = useRef();
const [updateBgColorMtn, setUpdateBgColorMTN]  = useState('');
const [updateBgColorGLO, setUpdateBgColorGLO]  = useState('');
const [updateBgColorAIREL, setUpdateBgColorAIRTEL]  = useState('');
const [updateBgColor9MOBILE, setUpdateBgColor9MOBILE]  = useState('');
//////////////////////////////////////////////////////////////
var authUserToken =  localStorage.getItem('authUserToken');
//////////////////////////////////////////////////////////////
const[data, setData] = useState({
    newCardPin: '',  
    network: '',
    number:'',
    amount:'', 
    amountProcess:'', 
    message:'',
    check_textInputChange: false,
    secureTextEntry: true,
    countryName:'Nigeria',
    countryCode:'NG',
    operatorID:'',  
    recipientPhone:'',  
    sender_phone:loginData.username,  
  })
   
 
 
 
const PurchaseDataFromCCCard =() => 
{
   
    var hash   = md5(data.newCardPin);
    var passW1 = String(loginData.card_pin); 
    var passW2 = String(hash); 

     var vcard_bal_int =parseInt(loginData.vcard_bal);
     var amount_int    =parseInt(data.amount);
 
    setActivateLoader(true);  
    setIsLoading2(true); 



    if(loginData.card_lock == 'locked'  )
    {
    setActivateLoader(false);  
    setIsLoading2(false); 


    setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
    setMessageSwitch(true)
    


      }
      else
      {
       
 
            var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
                var userData = {
                    countryCode     :data.countryCode,    
                    operatorID      :data.operatorID,  
                    amount          :data.amountProcess,  
                    recipientPhone  :data.recipientPhone,  
                    sender_phone    :loginData.username, 
                    passkey         :authUserToken  
                };

            
                if(  data.recipientPhone.length == 0 || data.operatorID.length == 0 || data.amount.length == 0 ) 
                {
            
                
                    setActivateLoader(false);  
                    setIsLoading2(false); 
                    setMessageSwitch(true) 
                    setMessage("OOPS!. All fields are required");
            
                    
                }
                else
                {

                        if(passW1  === passW2 )
                        {

                                if(amount_int  >  vcard_bal_int)
                                {
                                    setMessage('You have insufficient balance on your credit card');
                                    setActivateLoader(false);  
                                    setIsLoading2(false); 
                                    setMessageSwitch(true) 
                                }
                                else
                                {
                                    fetch(BaseAction+"SendTopUp",
                                    {
                                    method:'POST',
                                    header:headers,
                                    body: JSON.stringify(userData)
                                    })
                                    .then((response) => response.json())
                                    .then((response) =>{ 
                                        
                                        setActivateLoader(false);  
                                        setIsLoading2(false); 
                                        setConfirmDataPurchase(false)
                                        setMessageSwitch(true) 
                                        setMessage(response[0].feedback);
                                    
                                    
                                    })                        
                                    .catch(error => {
                                            
                                        setActivateLoader(true);  
                                        setIsLoading2(false); 
                                    });
        
                                }
                        }
                        else
                        {

                            setActivateLoader(false);  
                            setIsLoading2(false); 
                            setMessageSwitch(true) 
                            setMessage('Wrong credit card PIN passed');
                        }


            }  
       }
}

const PurchaseAirtimeFromCCCard =() => 
{
   
    var hash   = md5(data.newCardPin);
    var passW1 = String(loginData.card_pin); 
    var passW2 = String(hash); 

     var vcard_bal_int =parseInt(loginData.vcard_bal);
     var amount_int    =parseInt(data.amount);
 
    setActivateLoader(true);  
    setIsLoading2(true); 



    if(loginData.card_lock == 'locked'  )
    {
    setActivateLoader(false);  
    setIsLoading2(false); 


    setMessage('Your credit card has been temporary locked due to unusual transaction recently detected. Plesase send a mail to '+ AbuseEmail +' and request for your credit card to be unlock. Thank you');
    setMessageSwitch(true)
    


      }
      else
      {
 
                var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }
                    var userData = {
                        countryCode     :data.countryCode,    
                        operatorID      :data.operatorID,  
                        amount          :data.amountProcess,  
                        recipientPhone  :data.recipientPhone,  
                        sender_phone    :loginData.username, 
                        recharge_charges:loginData.recharge_charges, 
                        passkey         :authUserToken 
                    };

                
                    if(  data.recipientPhone.length == 0 || data.operatorID.length == 0 || data.amount.length == 0 ) 
                    {
                
                    
                        setActivateLoader(false);  
                        setIsLoading2(false); 
                        setMessageSwitch(true) 
                        setMessage("OOPS!. All fields are required");
                
                        
                    }
                    else
                    {

                            if(passW1  === passW2 )
                            {

                                if(amount_int  >  vcard_bal_int)
                                {
                                    setMessage('You have insufficient balance on your credit card');
                                    setActivateLoader(false);  
                                    setIsLoading2(false); 
                                    setMessageSwitch(true) 
                                }
                                else
                                {
                                    fetch(BaseAction+"SendTopUp",
                                    {
                                    method:'POST',
                                    header:headers,
                                    body: JSON.stringify(userData)
                                    })
                                    .then((response) => response.json())
                                    .then((response) =>{ 
                                        
                                        setActivateLoader(false);  
                                        setIsLoading2(false); 
                                        setConfirmAirtimePurchase(false)
                                        setMessageSwitch(true) 
                                        setMessage(response[0].feedback);
                                    
                                    
                                    })                        
                                    .catch(error => {
                                            
                                        setActivateLoader(true);  
                                        setIsLoading2(false); 
                                    });

                                }
                            }
                            else
                            {

                                setActivateLoader(false);  
                                setIsLoading2(false); 
                                setMessageSwitch(true) 
                                setMessage('Wrong credit card PIN passed');
                            }


                   }  
       }
}



const FetchCountryDataHistory =(val,val2) => {

    if(val == 'MTN'){
          setData({...data, network:'MTN',operatorID:'345',  bundule:'mtnData'})
         
        setUpdateBgColorMTN(MainColor)
        setUpdateBgColorGLO('')
        setUpdateBgColorAIRTEL('')
        setUpdateBgColor9MOBILE('')

    }else if(val == '9MOBILE'){
        setData({...data, network:'9MOBILE',operatorID:'645', bundule:'ninemobileData'})

        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE(MainColor)
        setUpdateBgColorGLO('')
        setUpdateBgColorAIRTEL('')


        
    }else if(val == 'AIRTEL'){
        setData({...data, network:'AIRTEL',operatorID:'646', bundule:'airtelData'}) 

        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE('')
        setUpdateBgColorAIRTEL(MainColor)
        setUpdateBgColorGLO('')


    }else if(val == 'GLO'){
        setData({...data, network:'GLO',operatorID:'647', bundule:'gloData'})
        
        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE('')
        setUpdateBgColorAIRTEL('')
        setUpdateBgColorGLO(MainColor)
    }
   
    setNetworkAirtimeSelect(false)
    setNetworkDataSelect(true)
 

    setActivateLoader(true);  
    setIsLoading2(true); 


    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }

        //fetch(ReloadBaseAction+"ReloadlyOperatorID&opID="+val2,
        fetch(BaseAction+"OperatorID&opID="+val,
        {
        method:'GET',
        header:headers
        })
        .then((response) => response.json())
        .then((response) =>{ 

           
           // console.log(response.fixedAmounts.toString());
            setGetAllCountry(response);
             
            setCountryModalFetch(true); 
            setActivateLoader(false);  
            setIsLoading2(false); 
        })
        
        .catch(error => {
                  
            setActivateLoader(true);  
            setIsLoading2(false); 
        });
 
}

const DataCategoryPicked =(amount) => {

    setData({
        ...data,
        amount:parseInt(amount),
        amountProcess:parseInt(amount) + parseInt(loginData.recharge_charges),
        })
        setCountryModalFetch(false);
 

 
}
const SelectAirtimeNetwork =(val) => {

    if(val == 'MTN'){
        setData({...data, network:'MTN',operatorID:'346',  bundule:'mtnData',})  
        
        setUpdateBgColorMTN(MainColor)
        setUpdateBgColorGLO('')
        setUpdateBgColorAIRTEL('')
        setUpdateBgColor9MOBILE('')

    }else if(val == '9MOBILE'){
        setData({...data, network:'9MOBILE',operatorID:'340', bundule:'ninemobileData',})
      
        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE(MainColor)
        setUpdateBgColorGLO('')
        setUpdateBgColorAIRTEL('')

    }else if(val == 'AIRTEL'){
        setData({...data, network:'AIRTEL',operatorID:'342', bundule:'airtelData',})
      
        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE('')
        setUpdateBgColorAIRTEL(MainColor)
        setUpdateBgColorGLO('')

    }else if(val == 'GLO'){
        setData({...data, network:'GLO',operatorID:'344',bundule:'gloData',})
         
        setUpdateBgColorMTN('')
        setUpdateBgColor9MOBILE('')
        setUpdateBgColorAIRTEL('')
        setUpdateBgColorGLO(MainColor)

    }
   
    setNetworkDataSelect(false);
    setNetworkAirtimeSelect(true);
 

 
}


 

   

const LoginOptionUpdate =() => 
{

const value =  localStorage.getItem('loginSession');
const data = JSON.parse(value);  
setLoginData(data);
  
}

useEffect(() => {
      



setTimeout(async() => {

      LoginOptionUpdate();  
      

}, 5000);


                  
});

useLayoutEffect(() => {

      LoginOptionUpdate();
      
},[]); 


 


 //var authUserToken =  localStorage.getItem('authUserToken');;
 const NumberFomat = (val) =>{

    if( val > 0){
          var value =  val* 1; 
          var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return  Naira+result;  
    }else{

            return  Naira+0.00;  
    }
                                                     
     
    
}
 
  
 

const showHandler =()=>{
    setMessage('');
    setActivateLoader(false);
    setIsLoading2(false);
    
  
  }
    
 
if(activateLoader)
{


    if(isLoading2)
    {


      return (     
    
            <div> 
              <Loading1     /> 
            </div>
          );


    }
    else
    {
        return (         
            <div> 
                <Loading2   Show={showHandler} >
                    <small style={{color: 'black',fontSize:13}}>{message} </small>
                </Loading2> 
            </div>
        );
    }
 



    
}
else
{

  return (
    <div>
    <div>

  


        <Row style={{padding:20,justifyContent:'space-between'}}>

                <Col  onClick={() => setPurchaseSelect(true)}    style={{width:'30%'}}>
                <div className="button">
                <div style={{color:'white',textAlign:'center',fontSize:11}} >BUY DATA </div>
                </div>            
                </Col>

                <Col   onClick={() => setPurchaseSelect(false)}   style={{width:'30%',}}>
                <div  className="button" style={{backgroundColor:'#8f5674'}}>
                <div style={{color:'white',textAlign:'center',fontSize:11}} >BUY AIRTIME </div>
                </div>            
                </Col>


                <Col       style={{width:'30%'}}>
                <div  className="button">
                <div style={{color:'white',textAlign:'center',fontSize:11}} > UTILITY PAYMENT </div>
                </div>            
                </Col>
        </Row>

 



        {
            purchaseSelect?

                <div>

                <div>
                <div style={{color:MainColor,textAlign:'center', fontWeight:'bold',}}> SELECT DATA NETWORK</div>
                </div>

              
                <div  className="Wrapper" style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> 

                    <div    onClick={() => FetchCountryDataHistory('MTN','345')} style={{width:'25%'}}>
                    <div className="button_1" style={{backgroundColor:updateBgColorMtn}} >
                    <img style={{ width: '100%', height: '100%',borderRadius:10}}  src='images/mtn.jpg' />   
                    </div>            
                    </div>

                    <div   onClick={() => FetchCountryDataHistory('9MOBILE','645')}  style={{width:'25%'}}>
                    <div className="button_1" style={{backgroundColor:updateBgColor9MOBILE}}>
                    <img style={{ width: '100%', height: '100%',borderRadius:10}}  src='images/9mobile.jpg' />  
                    </div>            
                    </div>

                    <div    onClick={() => FetchCountryDataHistory('AIRTEL','646')} style={{width:'25%'}}>
                    <div className="button_1" style={{backgroundColor:updateBgColorAIREL}}>
                    <img style={{ width: '100%', height: '100%',borderRadius:10}}  src='images/airtel.jpg' />  
                    </div>            
                    </div>

                    <div    onClick={() => FetchCountryDataHistory('GLO','647')} style={{width:'25%'}}>
                    <div className="button_1" style={{backgroundColor:updateBgColorGLO}}>
                    <img style={{ width: '100%', height: '100%',borderRadius:10}}  src='images/glo.jpg' />  
                    </div>            
                    </div>
 
                </div>

                <div>
                            {networkDataSelect?
                            
                            
                                <div className="Wrapper">
                                        
                                        <div style={{marginTop:50,}}>
                                         
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <div style={{textAlign:'left',fontSize:16}}> Data Network Selected</div>
                                                <Form.Control type="text" 
                                                value={data.network}
                                                disabled      
                                                />
                                            </Form.Group>


                                             
                                        </div>


                                        <div style={{marginTop:10,}}>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <div style={{textAlign:'left',fontSize:16}}>Recipient Phone Number </div>
                                                <Form.Control type="text" 
                                                onChange={e => setData({ ...data, recipientPhone:e.target.value})} 
                                                        
                                                />
                                            </Form.Group>
  
                                        </div>


                                     <div style={{marginTop:10,}}> 

 
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <div style={{textAlign:'left',fontSize:16}}>Transaction Charges </div>
                                            <Form.Control type="text" 
                                            value={NumberFomat(loginData.recharge_charges)}
                                            disabled      
                                            />
                                        </Form.Group>
                                        
                                    </div>



                                        <div style={{marginTop:10,}}>
                                            <div style={{alignItems:'flex-start'}}>
                                                    <div style={{textAlign:'left',fontSize:16}}>
                                                Select Data Bundle 
                                                    </div>                           
                                            </div>


                                            <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,}}>

                                            
                                            <div style={{width:'55%',textAlign:'left',}}>
                                                <div   onClick={() =>  setCountryModalFetch(true)}  style={{width:'100%',}}>
                                                    <div  className="button2" style={{width: '100%',fontSize:15,color:'black',textAlign:'left',backgroundColor:'#f2f2f2'}} > 
                                                   {NumberFomat(data.amount)}
                                                    </div>       
                                                </div>                       
                                            </div>

                                            </div>

                                            
                                        </div>

                 

                                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:50}}>

                                    

                                        <div   onClick={() => setConfirmDataPurchase(true)}  style={{width:'100%',}}>
                                        <div className="button">
                                        <div style={{textAlign:'center',color:'white',fontSize:16,}}> Next 
                                        </div>
                                        </div>
                                        </div>



                                        </div>
                        
                                </div>
                            :
                            null

                                
                            }        

            
                 </div>

                </div>

                :


                <div>
                 
                    <div >
                    <div style={{color:'#8f5674',textAlign:'center', fontWeight:'bold'}}> SELECT AIRTIME NETWORK</div>
                    </div>

                    <div className="Wrapper" style={{display:'flex',flexDirection:'row',justifyContent:'space-around'}}>

                            <div    onClick={() => SelectAirtimeNetwork('MTN')} style={{width:'25%'}}>
                            <div className="button_1" style={{backgroundColor:updateBgColorMtn}}>
                            <img style={{ width: '100%', height: '100%',borderRadius:10}}  src='images/mtn.jpg' />  
                            </div>            
                            </div>

                            <div   onClick={() => SelectAirtimeNetwork('9MOBILE')}  style={{width:'25%'}}>
                            <div className="button_1" style={{backgroundColor:updateBgColor9MOBILE}}>
                            <img style={{ width: '100%', height: '100%',borderRadius:10}}   src='images/9mobile.jpg'/>  
                            </div>            
                            </div>

                            <div    onClick={() =>  SelectAirtimeNetwork('AIRTEL')} style={{width:'25%'}}>
                            <div className="button_1" style={{backgroundColor:updateBgColorAIREL}}>
                            <img style={{ width: '100%', height: '100%',borderRadius:10}}   src='images/airtel.jpg' />  
                            </div>            
                            </div>

                            <div    onClick={() =>  SelectAirtimeNetwork('GLO')} style={{width:'25%'}}>
                            <div className="button_1" style={{backgroundColor:updateBgColorGLO}}>
                            <img style={{ width: '100%', height: '100%',borderRadius:10}}   src='images/glo.jpg' />  
                            </div>            
                            </div>
                    </div>

                    <div>
                            {networkAirtimeSelect?
                            
                            
                                <div className="Wrapper">
                                        
                                     <div style={{marginTop:50,}}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div style={{textAlign:'left',fontSize:16}}>Data Network Selected </div>
                                        <Form.Control type="text" 
                                        value={data.network} 
                                        disabled      
                                        />
                                        </Form.Group> 
                                    </div>


                                       
                                    <div style={{marginTop:10,}}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div style={{textAlign:'left',fontSize:16}}> Recipient Phone Number </div>
                                        <Form.Control type="text" 
                                        onChange={e => setData({ ...data, recipientPhone:e.target.value})} 
                                               
                                        />
                                        </Form.Group> 
                                    </div>
                                       
                                    <div style={{marginTop:10,}}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div style={{textAlign:'left',fontSize:16}}>Enter Airtime Amount </div>
                                        <Form.Control type="text" 
                                        onChange={e => 
                                            setData({ 
                                            ...data, 
                                            amount:e.target.value,
                                            amountProcess:parseInt(e.target.value) + parseInt(loginData.recharge_charges)
                                        })}
                                        />
                                        </Form.Group> 
                                    </div>
 

                                    

                                    <div style={{marginTop:10,}}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <div style={{textAlign:'left',fontSize:16}}>Transaction Charges  </div>
                                        <Form.Control type="text" 
                                        disabled
                                        value={NumberFomat(loginData.recharge_charges)} 
                                        />
                                        </Form.Group> 
                                    </div>
 


                                    
                                 

                                        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:50}}>

                                    

                                        <div   onClick={() => setConfirmAirtimePurchase(true)}  style={{width:'100%',}}>
                                        <div className="button" style={{backgroundColor:'#8f5674'}}>
                                        <div style={{textAlign:'center',color:'white',fontSize:16,}}> Next 
                                        </div>
                                        </div>
                                        </div>



                                        </div>
                        
                                </div>
                            :
                            null

                                
                            }        

            
                    </div>


                </div>
        }


    </div>



                        {/// CREDIT CARD  TRANSFER 
                            confirmDataPurchase ?


                            <Modal    show={true}  >
                                <div >
        
                                <div style={{width: '47%',marginLeft:'3%'}}>
                                <div   onClick={() => setConfirmDataPurchase(false)} style={{marginTop: 20}}>
                                <IoIosCloseCircle  size={30}  color={'black'} />
                                </div>
                                </div>
        
                                <div  className="Wrapper" >

                                <div  >
                                        
                                        <div>
                                        <div  style={{textAlign:'left',}}> Send Data to</div>
                                        </div>
                                            


                                        <div className="ContainerBox box_1"  style={{marginTop:2}}>

                                        <div>
                                                <div style={{marginBottom:30,fontSize:12,}}>
                                                    <div style={{textAlign:'left',color:'white',}}>Recipient Phone </div>
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white',fontWeight:'bold'}}>{data.recipientPhone}</div>                             
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>Sender Phone </div>
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white',fontWeight:'bold'}}>{loginData.username}</div>                             
                                                </div>
                                        

                                                <div>                       
                                                <MdOutlinePhoneAndroid   size={40}  color={'white'} />                                        
                                                </div>
                                        </div>


                                        <div>

                                                <div style={{marginBottom:30,fontSize:12,}}>                       
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',}}>Network</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',fontWeight:'bold'}}>{data.network}</div>                                        
                                                </div>


                                                <div>                       
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',fontSize:14}}>Data Cost</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',fontWeight:'bold',fontSize:14}}>{NumberFomat(data.amount)}</div>                                        
                                                </div>

                                                <div>                       
                                                <div className="lineFonts2" style={{textAlign:'left',color:'white'}}>Charges</div>
                                                <div className="lineFonts2" style={{textAlign:'left',color:'white',fontWeight:'bold'}}>{NumberFomat(loginData.recharge_charges)}</div>                                         
                                                </div>
                                        </div>

                                        </div>
                                                                            

                                </div>


                                <div style={{marginTop:10}}>
                                        
                                        <div style={{marginTop:2}}>
                                        <div className="lineFonts2" style={{textAlign:'left',}}>Virtual credit card to debit</div>
                                        </div>
                                            


                                        <div className="ContainerBox box_2"  style={{marginTop:2}}>

                                        <div >
                                                <div style={{marginBottom:30,fontSize:12,}}>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Balance</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontWeight:'bold'}}>{NumberFomat(loginData.vcard_bal)}</div>                             
                                                </div>
                                        

                                                <div>                       
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Credit card number </div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>{loginData.card_digit}</div>                                        
                                                </div>
                                        </div>


                                        <div>

                                                <div style={{marginBottom:30}}>                       
                                                <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Status</div>
                                                <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>Active</div>                                        
                                                </div>


                                                <div>                       
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Card expires</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>{loginData.card_expires}</div>                                        
                                                </div>

                                        
                                        </div>

                                        </div>
                                                                            

                                </div>

                                <div style={{marginTop:20}}>
                                        
                                        <div style={{marginTop:2}}>
                                        <div className="lineFonts2" style={{textAlign:'left',}}>Total Purchase</div>
                                        </div>
                                            


                                        <div className="ContainerBox box_2"  style={{marginTop:2}}>

                                      
                                                <div style={{marginBottom:5}}>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Checkout</div>                             
                                                </div>


                                                <div style={{marginBottom:5}}> 
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:18,fontWeight:'bold'}}>{NumberFomat(data.amountProcess)}</div>                             
                                                </div>
                                        

                                               
                                        </div>
                                                                            

                                </div>


                                <div style={{marginTop:30,}}>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                <div style={{textAlign:'left'}}> Enter Credit Card PIN  </div>
                                  <Form.Control type="password" 
                                   maxLength={4}   
                                   onChange={e => setData({ ...data, newCardPin:e.target.value})}
                                   />
                                </Form.Group> 
                                
                            </div>
        
        
                            <div style={{display:'flex',flexDirection:'row',width:'50%',marginTop:20,paddingBottom:20}}>
        
                    
        
                            <div onClick={PurchaseDataFromCCCard} style={{width:'100%',}}>
                            <div  className="button">
                            <div style={{textAlign:'center',color:'white',fontSize:16,}}>Buy Data </div>
                            </div>
                            </div>
        
        
        
                            </div>
        
                                        
        
                                </div>

                                </div>
                            </Modal>
                            :  
                            null
        
                        }

                            
                        {/// CREDIT CARD  TRANSFER setConfirmAirtimePurchase <FaEnvelopeOpenText  size={25}  color={MainColor} /> 
                            confirmAirtimePurchase ?


                            <Modal    show={true}  >
                                <div  >
        
                                <div style={{width: '47%',marginLeft:'3%'}}>
                                <div   onClick={() => setConfirmAirtimePurchase(false)} style={{marginTop: 20}}>
                                <IoIosCloseCircle   size={30}  color={'black'} />
                                </div>
                                </div>
        
                                    <div className="Wrapper">

                                            <div >

                                            <div>
                                            <div style={{textAlign:'left',}}> Send Airtime to</div>
                                            </div>



                                            <div className="ContainerBox box_1"  style={{marginTop:2,backgroundColor:'#8f5674'}}>

                                            <div>
                                                    <div style={{marginBottom:30}}>
                                                    <div style={{textAlign:'left',color:'white'}}>Recipient Phone </div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',fontSize:14,fontWeight:'bold'}}>{data.recipientPhone}</div>                             
                                                    
                                                    <div style={{textAlign:'left',color:'white'}}>Sender Phone </div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'white',fontSize:14,fontWeight:'bold'}}>{loginData.username}</div>                           
                                                    </div>


                                                    <div>                       
                                                    <MdOutlinePhoneAndroid  size={40}  color={'white'} />                                        
                                                    </div>
                                            </div>


                                            <div>

                                                    <div style={{marginBottom:30}}>                       
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>Network</div>
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white',fontSize:16,fontWeight:'bold',fontSize:14,}}>{data.network}</div>                                        
                                                    </div>


                                                    <div>                       
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>Airtime amount</div>
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>{NumberFomat(data.amount)}</div>                                         
                                                    </div>


                                                    <div>                       
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>Charges</div>
                                                    <div className="lineFonts2"  style={{textAlign:'left',color:'white'}}>{NumberFomat(loginData.recharge_charges)}</div>                                         
                                                    </div>


                                            </div>

                                            </div>


                                            </div>


                                            <div style={{marginTop:20}}>

                                            <div style={{marginTop:2}}>
                                            <div className="lineFonts2"  style={{textAlign:'left',}}>Virtual credit card to debit</div>
                                            </div>



                                            <div className="ContainerBox box_2"   style={{marginTop:2}}>

                                            <div >
                                            <div style={{marginBottom:30}}>
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Balance</div>
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>{NumberFomat(loginData.vcard_bal)}</div>                             
                                            </div>


                                            <div>                       
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Credit card number </div>
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>{loginData.card_digit}</div>                                        
                                            </div>
                                            </div>


                                            <div>

                                            <div style={{marginBottom:30}}>                       
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Status</div>
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>Active</div>                                        
                                            </div>


                                            <div>                       
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Card expires</div>
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:14,fontWeight:'bold'}}>{loginData.card_expires}</div>                                        
                                            </div>


                                            </div>

                                            </div>


                                            </div>



                                            <div style={{marginTop:20}}>

                                            <div style={{marginTop:2}}>
                                            <div className="lineFonts2" style={{textAlign:'left',}}>Total Purchase</div>
                                            </div>



                                            <div className="ContainerBox box_2"  style={{marginTop:2}}>


                                            <div style={{marginBottom:5}}>
                                            <div className="lineFonts2"  style={{textAlign:'left',color:'black'}}>Checkout</div>                             
                                            </div>


                                            <div style={{marginBottom:5}}> 
                                            <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:15,fontWeight:'bold'}}>{NumberFomat(data.amountProcess)}</div>                             
                                            </div>



                                            </div>


                                            </div>


                                            <div style={{marginTop:20,}}>

                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <div style={{textAlign:'left'}}> Enter Credit Card PIN  </div>
                                              <Form.Control type="password" 
                                               maxLength={4}   
                                               value={data.newCardPin}  
                                               onChange={e => setData({ ...data, newCardPin:e.target.value})}
                                               />
                                            </Form.Group>  


                                            </div>


                                            <div style={{display:'flex',flexDirection:'row',width:'50%',marginTop:20,paddingBottom:20}}>



                                            <div onClick={()=>PurchaseAirtimeFromCCCard()} style={{width:'100%',}}>
                                            <div  className="button">
                                            <div style={{textAlign:'center',color:'white',fontSize:16,}}>Buy Airtime  </div>
                                            </div>
                                            </div>



                                            </div>

                                            
            
                                    </div>
                                </div>
                            </Modal>
                            :  
                            null
        
                         }


                        {///FETCH ALL COUNTRY  
                            countryModalFetch ?


                            <Modal  show={true}  >
                                <div> 
                                    <div onClick={() => setCountryModalFetch(false)}>
                                    <IoIosCloseCircle  size={25}  color={MainColor} />
                                    </div>  


                                <div className="Wrapper">
                             

                                    <div > 
                                    <div style={{width:'100%',fontSize:20,textAlign:'center',color:MainColor, }}> 
                                    <div style={{ width: '100%',padding:5,alignItems:'center'}}> 
                                    <div style={{fontWeight:'bold',color:MainColor}}> {data.network} Nigeria Data </div> 
                                    </div>   


                                    </div> 
                                    </div>

                                    <div style={{ display:'flex',width: '100%', flexDirection: 'row', paddingBottom: 5, padding: 10, backgroundColor: LightBlack }}>




                                    <div style={{ width: '25%' }}>
                                    <div style={{ color: TopTabColor,  fontSize: 12, fontWeight: 'bold', textAlign: 'left' }} >Amount </div> 
                                    </div>


                                    <div style={{ width: '75%' }}>
                                    <div style={{ color: TopTabColor,  fontSize: 12, fontWeight: 'bold', textAlign: 'left' }} >Select Data Bundle </div> 
                                    </div> 

                                    </div> 

                                    {                  
                                    getAllCountry.map(item => {
                                    return (         
                                    <div className="dataList"  style={{ textAlign: 'left',}}> 
                                    <div 
                                    onClick={ () =>  DataCategoryPicked(item.amount) }
                                    style={{marginTop:5 }}>
                                    <div style={{display:'flex',flexDirection:'row',width: '100%',alignItems:'flex-start',borderBottomWidth:1,padding:5,borderBottomColor:'#f2f2f2'}}>


                                    <div  style={{width:'25%',color:'black',fontSize:12}}>{NumberFomat(item.amount)}   </div> 
                                    <div  style={{width:'70%',color:'black',paddingLeft:'5%',fontSize:12}}>{item.data_text}   </div> 

                                    </div>  
                                    </div>           
                                    </div>

                                    );

                                    })}      
                                    </div>
    
                            </div>
    
                            </Modal>
                            :  
                            null
    
                            }



                                                    {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?
                  
                        
                        <Modal  show={true}   >
                      
                        <div  onClick={() => setMessageSwitch(false)}   > 
                          <AiFillCloseCircle  size={25}  color={MainColor} /> 
                        <div>
                  


                        
                        
                              
                    
                                     
                        
            
                              <div   style={{marginTop: 20,paddingBottom:20}} >
                              
                                    <div  style={{fontSize: 14}} >{message}</div>
                              </div>

                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null

                        }
    </div>


  )

 }





}
 
export default memo(DataAirtimeTransaction);  
 