
import React, { useState, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate,useLocation,} from 'react-router-dom'; 
import {FloatingLabel,Card, Table, Form,Button,Modal} from 'react-bootstrap';
import {  FaEnvelopeOpenText, } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdKeyboardBackspace } from "react-icons/md";
import { FcEmptyBattery } from "react-icons/fc";   
import './Signup.css'; 
 

import   Loading          from "../Loading";
import   Loading2         from "../Loading2";
import { BasePageAction,BaseAction,MainColor,BaseReg} from '../../BaseUrl';
import md5                from 'md5-hash';

function Signup() { 
 
    const[activateLoader, setActivateLoader]   = useState(false); 
    const[isLoading2, setIsLoading2]   = useState(false);  
    const [message2, setMessage2] = useState('');
    const [message, setMessage] = useState('');
    const [messageSwitch, setMessageSwitch] = useState(''); 
    const [guarantorCode, setGuarantorCode] = useState('');  
    const [openQuestSwitch, setOpenQuestSwitch] = useState(true);
    const [onAircliqueCode, setOnAircliqueCode] = useState('');
    const [coachCode, setCoachCode] = useState('');
    const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 
    const[messageSwitchTopUp, setMessageSwitchTopUp]   = useState(true); 
    const[data, setData] = useState({
    
      email:"",
      pass1:"", 
      pass2:"", 
      fullname:"", 
      gender:"", 
      address:"", 
      city:"", 
      state:"", 
      country:"", 
      phoneno:"", 
      questCode:"", 
      bankName:"", 
      accountName:"", 
      accountNo:"", 
  
  })
   
  const search = useLocation().search;
  
    
  useLayoutEffect(() => 
  {       

    const tradeCode     = new URLSearchParams(search).get('trade_code');
    setCoachCode(tradeCode); 
    
 
 
    if(!tradeCode == ''){ 
      CheckCligueCoach(tradeCode);

 
    } 
    
    
  },[]);
  



    const navigate = useNavigate();    
    
  const showHandler =()=>{

    setActivateLoader(false);  
    setIsLoading2(false); 
  
 }
 
 

 const FetchTopUpHistory = () => {
  
  setActivateLoader(true);  
  setIsLoading2(true);  



          var headers = {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  }
    
         fetch(BaseAction+"FetchTopUpHistory",
              {
              method:'GET',
              header:headers,
      
              })
              .then((res) => res.json())
              .then((response) => {

            

                    if(response[0].success == "0")
                    {
                    
                          setActivateLoader(false);  
                          setIsLoading2(false);  

                          
                          setMessage(response[0].feedback);  
                          setMessageSwitch(true) 
                    }
                    else 
                    {

                      setTopUpAgentHistoryData(response); 
                      setMessageSwitchTopUp(true);
                      
                      setActivateLoader(false);  
                      setIsLoading2(false);  
                    }



              })  
              .catch(error => {
                
                  setActivateLoader(true);  
                  setIsLoading2(false); 
              });
      
          

  //  Keyboard.dismiss();



  
}

const CheckCligueCoach = (val) => {
 

 

  setActivateLoader(true); 
  setIsLoading2(true);

  if(val.length == 0)
  {

    setMessage(' Trading Code field is required');         
    setMessageSwitch(true);

    setActivateLoader(false); 
    setIsLoading2(false);

    
  }
  else
  {
      
    fetch(BaseAction+"CliqueUserCheck&questCode="+val,
    {
    method:'GET',
    headers:{
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    })
    .then((response) => response.json())
    .then((response) => {

     
        setActivateLoader(false);  
        setIsLoading2(false); 

            if(response[0].success == "success")
            {
     
                setOpenQuestSwitch(false);
                setMessage(response[0].feedback);
                setOnAircliqueCode(response[0].level1);
                

                setActivateLoader(false); 
                setIsLoading2(false);

            }
            else
            {

             
                setMessageSwitch(true);
                setMessage(response[0].feedback);
                setOpenQuestSwitch(true)
            }
    
  
    
           
        }) 
        .catch((error) => {
        setActivateLoader(true);  
        setIsLoading2(false);
       
        });


  }


}

const CheckCligue = (e) => {
 e.preventDefault();

 

  setActivateLoader(true); 
  setIsLoading2(true);

  if(guarantorCode.length == 0 || guarantorCode == '')
  {

    setMessage(' Referer Trader Code field is required');         
    setMessageSwitch(true);

    setActivateLoader(false); 
    setIsLoading2(false);

    
  }
  else
  {
      
    fetch(BaseAction+"CliqueUserCheck&questCode="+guarantorCode,
    {
    method:'GET',
    headers:{
        'Accept': 'application/json',
        'Content-type': 'application/json'
    }

    })
    .then((response) => response.json())
    .then((response) => {

     
        setActivateLoader(false);  
        setIsLoading2(false); 

            if(response[0].success == "success")
            {
     
                setOpenQuestSwitch(false);
                setMessage(response[0].feedback);
                setOnAircliqueCode(response[0].level1);
                

                setActivateLoader(false); 
                setIsLoading2(false);

            }
            else
            {

             
                setMessageSwitch(true);
                setMessage(response[0].feedback);
            }
    
  
    
           
        }) 
        .catch((error) => {
        setActivateLoader(true);  
        setIsLoading2(false);
       
        });


  }


}
 
const InserRecord = () => {

  setActivateLoader(true);
  setIsLoading2(true);

  var email     = data.email;    
  var phoneno   = data.phoneno; 
  var fullname  = data.fullname; 
  var pass1     = data.pass1;  
  var pass2     = data.pass2;   
  var gender    = data.gender; 
  var address   = data.address; 
  var bankName   = data.bankName; 
  var accountName   = data.accountName; 
  var accountNo   = data.accountNo; 
  var city      = data.city; 
  var state     = data.state; 
  var country   = data.country;   


   
  if(
      fullname.length == 0 || phoneno.length == 0 ||  email.length == 0 || pass1.length == 0 ||  bankName.length == 0 ||  accountName.length == 0 ||  accountNo.length == 0 || 
      pass2.length == 0 
   )
  {
 
     
      setActivateLoader(false);  
      setIsLoading2(false); 
      setMessageSwitch(true) 
      setMessage("OOPS!. All fields are required");

      
  }
  else
  {

      if( pass1 != pass2  )
      {
     
         
          setActivateLoader(false);  
          setIsLoading2(false); 
          setMessageSwitch(true) 
          setMessage("Your password didn't match");
  
          
      }
      else
      {
  
                  
          var headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          }
          var userData = {
              email     :data.email,    
              phoneno   :data.phoneno, 
              fullname  :data.fullname, 
          password      :md5(data.pass1),  
              pass1     :data.pass1,  
              pass2     :data.pass2,   
              gender    :data.gender, 
              address   :data.address, 
              bankName   :data.bankName, 
              accountName :data.accountName, 
              accountNo   :data.accountNo, 
              city      :data.city, 
              state     :data.state, 
              country   :data.country, 
              level3_code    :onAircliqueCode, 
          };

          fetch(BaseAction+"SignupRegister",
          {
          method:'POST',
          header:headers,
          body: JSON.stringify(userData)

          })
          .then((response) => response.json())
          .then((response) => 
          {
               
                  setActivateLoader(false); 
                  setIsLoading2(false);
                  
                  if(response[0].success == "success")
                  {
                      
                    
                      setMessage(response[0].feedback);
                       setMessageSwitch(true)
                       setOpenQuestSwitch(false); 
                  }else{
                      
                      setMessageSwitch(true) 
                      setMessage(response[0].feedback);
                  }
          
      

              
          })                 
          .catch((error) => {
              setActivateLoader(true);  
              setIsLoading2(false); 
              
          });


          
       }


  }




}

const JoinWithoutTradeCoach = (val) => {

CheckCligueCoach(val);

}
 
 
if(activateLoader)
{ 


        if(isLoading2)
        {
    
    
          return (     
    
                <div> 
                  <Loading  /> 
                </div>
              );
    
    
        }
        else
        {
            return (         
                <div> 
                    <Loading2   Show={showHandler} >
                        <small style={{color: 'black',fontSize:13}}>{message} </small>
                    </Loading2> 
                </div>
            );
        }

  }
  else
  {
    

    if(openQuestSwitch)
    {
   

            return (
              <div className="App">
              <div className="App-header">

                <div className="ContainerS" >  

              

 
                  
                     <div  onClick={() => {navigate('/')}}  >  
                      <MdKeyboardBackspace   style={{fontSize: 40,color: 'black'}} /> 
                     </div>  

                     <img src="images/favicon.png"   className="imgLogoL"/>          

                    <div className="TextHeader" style={{color:'black',marginBottom:20,}}>RSB DIGITAL SHARES </div> 


                  
                 

                          <Form onSubmit={CheckCligue} >  
                                <div className='form-inputs'>
                                  <label htmlFor='create-password' className='form-label' style={{color:'black', marginLeft:20}}>Referer Trader's Code<span style={{color: 'red'}}>*</span></label>
                                  <Form.Control 
                                  type="number" 
                                  name="number"
                                   placeholder="Enter Trader code"
                                  onChange={e=> setGuarantorCode(e.target.value)}
                                  
                                   style={{marginBottom:20,width: '95%', marginLeft:20 }}
                                  />
                                
                                </div>



                              
                                <button   type="submit" className="btntab--active2" > Join Via Referer </button>
                                <div> OR</div>
                                <button   onClick={()=>JoinWithoutTradeCoach('100')} className="btntab--active2" > Join Without Referer</button>
                                
                                <button  onClick={() => {navigate('/')}} className="btntab--active2" > Goto Login</button>
                                <div style={{fontSize:12,textAlign:'left', marginLeft:20}}>
                                <div>Join RSB Digital Shares to earn profit sharing on your shares and even buy/sell shares anytime</div>
                                
                                </div>
                          </Form>

                    </div>
         
            </div> 

                                {///SINGLR BUTTON MODAL (PERSONAL MESSAGE) 1111className="ContainerS"
                                  messageSwitch?
                         
                               
                                  <Modal  show={true}     >
                                  <div >
                
                                              <div   onClick={() => setMessageSwitch(false)}  >
                                              <IoCloseCircleOutline  size={30}  color="black" style={{margin:20}}/>
                                              </div>  
                
                                  <div >
                                  
                                  <FaEnvelopeOpenText   size={20}  color={MainColor} /> 
                                        <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>  NOTIFICATION</div>
                                        
                
                
                                        <div   style={{marginTop: 20}} >
                                        
                                              <div  style={{fontSize: 14}} >{message}</div>
                                        </div>
                                        
                                        </div>
                                        </div>
                            
                                  </Modal>
                                  :  
                                  null
                
                                  }



            </div> 

            );

        

    }
    else 
    {

            return (
            <div className="App">
            <div className="App-header">
          
            <div className="ContainerS" >

            <div  onClick={() => {setOpenQuestSwitch(true)}}  >  
            <MdKeyboardBackspace   style={{fontSize: 40,color: 'black'}} /> 
           </div>  




            <div style={{textAlign: 'center',color: MainColor,fontSize:20,marginTop: 20}}>
            TRADING ACCOUNT 
            </div> 
            <div style={{textAlign: 'center',color: MainColor,fontSize:20,marginBottom: 20}}>
            SIGNUP  
            </div> 


                <hr />
            <div style={{color:'#777777',fontSize:14 ,textAlign: 'left',}}>{message}   </div>
            
              

              <div style={{textAlign:'left'}}>
              <hr />
              <span style={{fontSize:11,marginTop:0}}>Trader Personal Data </span>


              <div style={{marginTop:10}}>Fullname </div>
              <Form.Control   

              onChange={e => setData({ ...data, fullname:e.target.value}) }                            

              value={data.fullname}
              /> 



              <div style={{marginTop:10}}>Email </div>
              <Form.Control   
              
              onChange={e => setData({ ...data, email:e.target.value}) }                            
              
              value={data.email}
              />  


              <hr />
              <span style={{fontSize:11,marginTop:0}}>Trader Account Login Data </span>

            <div  style={{marginTop:10}} >Phone (Username) </div>
            <Form.Control   
            keyboardType="phone-pad" 
            maxLength={11}
            onChange={e => setData({ ...data, phoneno:e.target.value}) }                            
            
            value={data.phoneno}
            /> 
            
            
            <div style={{marginTop:10}}>Create password </div>
            <Form.Control   
            secureTextEntry
            onChange={e => setData({ ...data, pass1:e.target.value}) }                            
            
            value={data.pass1}
            />                 
            <div style={{marginTop:10}}>Confirm password </div>
            <Form.Control   
            secureTextEntry
            onChange={e => setData({ ...data, pass2:e.target.value}) }                            
            
            value={data.pass2}
            />  
            



            
           
           <hr />
            <span style={{fontSize:11,marginTop:0}}>Trader Bank Account Details For Fast Cashout</span>



            <div style={{marginTop:10}}>Bank Name </div>
            <Form.Control    
            onChange={e => setData({ ...data, bankName:e.target.value}) }   
            value={data.bankName}
            />      
            
            <div style={{marginTop:10}}>Account Name  </div>
            <Form.Control    
            onChange={e => setData({ ...data, accountName:e.target.value}) }    
            value={data.accountName}
            />      
            
            <div style={{marginTop:10}}>Account Number  </div>
            <Form.Control   
            keyboardType="phone-pad" 
            onChange={e => setData({ ...data, accountNo:e.target.value}) }   
            value={data.accountNo}
            />      
            
            
     </div>

            <div className="button" style={{marginTop:20}}>
            <div onClick={InserRecord}>
            <div>Sign Up </div>
            </div> 
            </div>    
            
            <div>
            <button  onClick={() => {navigate('/')}} className="button" style={{marginTop:5}} > Goto Login</button>
            </div>

            <div onClick={() => {navigate('/Trade-Policy')}}   >
            <div style={{marginBottom: 50,width:'80%'}}>
            <div style={{fontSize:12,textAlign:'left'}}>
            <div></div>
            <div  >Join RSB Digital Shares to trade, earn daily and bi-weekly profit sharing.Click Here For Trade Policy</div>
             
            </div>
            </div>
            </div> 

            </div>                               


            </div>



                    {///SINGLR BUTTON MODAL (PERSONAL MESSAGE) 1111
                      messageSwitch?
                         
                               
                      <Modal  show={true}     >
                      <div >
    
                                  <div   onClick={() => setMessageSwitch(false)}  >
                                  <IoCloseCircleOutline  size={30}  color="black" style={{margin:20}}/>
                                  </div>  
    
                      <div >
                      
                      <FaEnvelopeOpenText   size={20}  color={MainColor} /> 
                            <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>  NOTIFICATION</div>
                            
    
    
                            <div   style={{marginTop: 20,marginBottom:10}} >
                                  <div  style={{fontSize: 13}} >{message}</div>
                            </div>
                            
                            </div>
                            </div>
                
                      </Modal>
                      :  
                      null
    
                      }

                      {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                        messageSwitchTopUp?


                        <Modal  show={true}  >
                                <div  onClick={() => setMessageSwitchTopUp(false)}  style={{padding: 20}}  > 
                                <IoCloseCircleSharp    size={30}  color={MainColor} /> 
                                </div>
                                <div>
                                <div style={{fontSize:14,textAlign:'center',fontWeight:'bold'}}> RSB DIGITAL SHARES <br /> TRADE POLICY</div>
                                <div style={{fontSize:12,textAlign:'center',padding:5}}>Please kindly read our trade term and conditions no shenanigans. Thanks  </div>
                                </div><br />
    
                                <div>
    
                            
            
                                <div className="button" style={{marginTop:20,width:'70%',marginBottom:20,marginLeft:'15%'}}>
                                <div  >
                                <div  onClick={() => {navigate('/Trade-Policy')}} >  Read Terms & Conditions </div>
                                </div> 
                                </div> 
                                
                            
                                </div>
                    
                        </Modal>
                        :  
                        null
            
                        } 


            </div>

            )


    }


  }





}

export default Signup
 

/*



            <div style={{marginTop:10}}>Email </div>
            <Form.Control   
            
            onChange={e => setData({ ...data, email:e.target.value}) }                            
            
            value={data.email}
            />   




            <div style={{marginTop:10}}>Gender </div>
            <Form.Select    onChange={e => setData({...data, gender:e.target.value})} >
            <option></option> 
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
            </Form.Select>              

            <div style={{marginTop:10}}>Address </div>
            <Form.Control   
            onChange={e => setData({ ...data, address:e.target.value}) }  
            value={data.address}
            />      
            

            <div style={{marginTop:10}}>City </div>
            <Form.Control    
            onChange={e => setData({ ...data, city:e.target.value}) }  
            value={data.city}
            />             

            <div style={{marginTop:10}}>State </div>
            <Form.Control    
            onChange={e => setData({ ...data, state:e.target.value}) }   
            value={data.state}
            />                 


            <div style={{marginTop:10}}>Country </div>
            <Form.Control    
            onChange={e => setData({ ...data, country:e.target.value}) }    
            value={data.country}
            />  
*/