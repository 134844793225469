
import React, { useState, useEffect,useLayoutEffect } from "react";
import { Link, useNavigate,useLocation,} from 'react-router-dom'; 
import {FloatingLabel,Card, Table, Form,Button,Modal} from 'react-bootstrap';
import {  FaEnvelopeOpenText, } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdKeyboardBackspace } from "react-icons/md";
import { FcEmptyBattery } from "react-icons/fc";   
import './Signup.css'; 
 

import   Loading          from "../Loading";
import   Loading2         from "../Loading2";
import { BasePageAction,BaseAction,MainColor,BaseReg} from '../../BaseUrl';
import md5                from 'md5-hash';

function Signup() { 
 
    const[activateLoader, setActivateLoader]   = useState(false); 
    const[isLoading2, setIsLoading2]   = useState(false);  
    const [message2, setMessage2] = useState('');
    const [message, setMessage] = useState('');
    const [messageSwitch, setMessageSwitch] = useState(''); 
    const [guarantorCode, setGuarantorCode] = useState('');  
    const [openQuestSwitch, setOpenQuestSwitch] = useState(true);
    const [onAircliqueCode, setOnAircliqueCode] = useState('');
    const [coachCode, setCoachCode] = useState('');
    const[topUpAgentHistoryData, setTopUpAgentHistoryData] = useState([]); 
    const[messageSwitchTopUp, setMessageSwitchTopUp]   = useState(true); 
 
  const search = useLocation().search;
  
    
  useLayoutEffect(() => 
  {       

 
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
  }

fetch(BaseAction+"TradePolicy",
{
method:'GET',
header:headers,

})
.then((res) => res.json())
.then((response) => {



    if(response[0].success == "0")
    {
    
          setActivateLoader(false);  
          setIsLoading2(false);  

          
          setMessage(response[0].feedback);  
          setMessageSwitch(true) 
    }
    else 
    {

      setTopUpAgentHistoryData(response); 
      setMessageSwitchTopUp(true);
      
      setActivateLoader(false);  
      setIsLoading2(false);  
    }



})  
.catch(error => {

  setActivateLoader(true);  
  setIsLoading2(false); 
});
    
  },[]);
  



    const navigate = useNavigate();    
    
  const showHandler =()=>{

    setActivateLoader(false);  
    setIsLoading2(false); 
  
 }
 
 

 
 
 
 
 
if(activateLoader)
{ 


        if(isLoading2)
        {
    
    
          return (     
    
                <div> 
                  <Loading  /> 
                </div>
              );
    
    
        }
        else
        {
            return (         
                <div> 
                    <Loading2   Show={showHandler} >
                        <small style={{color: 'black',fontSize:13}}>{message} </small>
                    </Loading2> 
                </div>
            );
        }

  }
  else
  {
    

 
        return (
        <div className="App">
        <div className="App-header">
      
        <div className="ContainerS" >

        <div   onClick={() => {navigate('/account-setup')}}  >  
        <MdKeyboardBackspace   style={{fontSize: 40,color: 'black'}} /> 
        </div>  


        <img src="images/logo1.png"   className="imgLogoL"/>        

        <div style={{textAlign: 'center',color: MainColor,fontSize:20,marginTop: 20}}>
        REFERERSELLBLOG DIGITAL SHARES
        </div> 
        <div style={{textAlign: 'center',color: MainColor,fontSize:20,marginBottom: 20}}>
        TRADE POLICY
        </div> <hr />


  

        <div>

    

        {
        topUpAgentHistoryData.map((item) => (
          
            <div style={{marginBottom:20,textAlign:'left'}}>
               <h6>{item.caption}</h6>
               <p style={{fontSize:14,}} >{item.body1}</p>
               <p style={{fontSize:14,}} >{item.body2}</p>              
            </div> 

            ))
        }  
        
        
    
        </div>
          

  





        </div>                               


        </div>





                

        </div>

        )
 

  }





}

export default Signup
 
  