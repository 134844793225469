import React, { useState, useEffect,memo,useLayoutEffect ,useRef} from 'react';                 
import { AuthContext } from '../../components/context';
import { AiFillStar } from "react-icons/ai";  
import { BsArrowLeft,BsChevronDoubleRight } from "react-icons/bs";  
import {FaEnvelopeOpenText } from "react-icons/fa"; 
import {GiMoneyStack } from "react-icons/gi"; 
import {AiFillCloseCircle,} from "react-icons/ai";    
import Loading from '../Loading';
import Loading2 from '../Loading2';
import { Modal, Table } from 'react-bootstrap';
import './GetInstantLoan.css'; 
import { Naira, TopTabColor, LightBlack,BaseAction,divColor2, MainColor,} from '../../BaseUrl'; 

function GetInstantLoan(props) {
const {  signOut, } = React.useContext(AuthContext);
const[activateLoader, setActivateLoader]  = useState(false); 
const[isLoading2, setIsLoading2] = useState(false);
const[messageSwitch, setMessageSwitch] = useState(false);
const[messageSwitch_2, setMessageSwitch_2] = useState(false);
const[message, setMessage] = useState('');  
const[loanGategoriesSection, setLoanGategoriesSection] = useState(true);
const[loanRequestSection, setLoanRequestSection]       = useState(false);
const[loanHistorySection, setLoanHistorySection]       = useState(false);
const[confirmDataPurchase, setConfirmDataPurchase] = useState(false);
const[loanProcessSwitch, setLoanProcessSwitch]     = useState(false);
 const[loginData, setLoginData] = useState([]); 
const[filterGetAllCountry, setFilterGetAllCountry] = useState([]); 
const[getAllCountry, setGetAllCountry] = useState([]); 
const searchRef = useRef();
const [search, setSearch]                  = useState();
////////////////////////////////////////////////////////////// 
  
const LoginOptionUpdate =() => 
{

const value =  localStorage.getItem('loginSession');
const data = JSON.parse(value);  
setLoginData(data);




 
}

useEffect(() => {
      



setTimeout(async() => {

      LoginOptionUpdate();  
      

}, 5000);


                  
});

useLayoutEffect(() => {

      LoginOptionUpdate();
      
},[]); 


  const[loanAmt, setLoanAmt] = useState({
    loan_1: 2500,      
    loan_2: 5000,      
    loan_3: 10000,      
    loan_4: 15000,      
    loanRate: loginData.loan_rate,      
    loanPrincipal: '',      
    loanInterest: '',      
    loanRepay: '',      
    loanDuration: '',      
  })
 
 
  const requestLoanButton = (loanP, coinAmt, loanMonth,loanDays) => 
  {

      var value  =  loanP / 100 *  loginData.loan_rate; //1 daily rate
      var loanIN =  value * loanDays;     //loan  Interest  by dailys     
      var loanRE =  loanP + loanIN;     //Total loan Repay

        var result =  loanP.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           


 setLoanAmt({
    ...loanAmt, 
    loanPrincipal: loanP,      
    loanInterest: loanIN,      
    loanRepay: loanRE,      
    loanDuration: loanMonth 
     
    });

   

 
        if (loginData.golden_coin_earn >= coinAmt) {
          
            LoanSubmitHandler();
    
        } 
        else  
        {
    
         
           
            setMessage('Loan request declined. You need a minimum of '+coinAmt+' Shares(SH) to aquire this '+ Naira+result+' non-cash refundable loan and you need to maintain good account loan rate to 4 stars' )
            setMessageSwitch(true);
        } 
    
    
      }
    
    const changeTopTabs = (val) => {


        if (val == 'history') {
          
             FetchCountryDataHistory()
    
        } 
        else  if (val == 'loan') 
        {
    
            setLoanHistorySection(false)
            setLoanRequestSection(true)
            setLoanGategoriesSection(false)
      
        }
        else  if (val == 'categories') 
        {

            setLoanHistorySection(false)
            setLoanRequestSection(false)
            setLoanGategoriesSection(true)
        }
    
    
      }
    
 
const LoanSubmitHandler =() => 
{
   
    var authUserToken =  localStorage.getItem('authUserToken');
    setActivateLoader(true);  
    setIsLoading2(true); 
 
    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        var userData = {
            loanRate       :loginData.loan_rate,    
            loanPrincipal  :loanAmt.loanPrincipal,    
            loanInterest   :loanAmt.loanInterest,  
            loanRepay      :loanAmt.loanRepay,  
            loanDuration   :loanAmt.loanDuration,  
            token          :authUserToken  
        };

     
 
           
                fetch(BaseAction+"LoanSubmitHandler",
                        {
                        method:'POST',
                        header:headers,
                        body: JSON.stringify(userData)
                        })
                        .then((response) => response.json())
                        .then((response) =>{ 
                            

                            setLoanProcessSwitch(false)
                            setActivateLoader(false);  
                            setIsLoading2(false);  
                            
                            setMessageSwitch(true) 
                            setMessage(response[0].feedback);
                          
                        
                        })                        
                        .catch(error => {
                                
                            setActivateLoader(true);  
                            setIsLoading2(false); 
                        });

           

      
 
}



const FetchCountryDataHistory =() => 
{
    setActivateLoader(true);  
    setIsLoading2(true); 


    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        }
        var authUserToken =  localStorage.getItem('authUserToken')
        fetch(BaseAction+"LoanHistory&token="+authUserToken,
        {
        method:'GET',
        header:headers
        })
        .then((response) => response.json())
        .then((response) =>{ 


           
           if(response[0].success == 'failed'){

            setActivateLoader(false);  
            setIsLoading2(false); 


            setLoanHistorySection(false)
            setLoanRequestSection(true)
            setLoanGategoriesSection(false) 


            
            setMessage(response[0].feedback)
            setMessageSwitch(true)

           }else{

             
            setGetAllCountry(response); 
            setActivateLoader(false);  
            setIsLoading2(false); 

            setLoanRequestSection(false)
            setLoanGategoriesSection(false)
            setLoanHistorySection(true)
            
           }



        })
        
        .catch(error => {
                  
            setActivateLoader(true);  
            setIsLoading2(false); 
        });
 
}


 



 



 const closeModalTransaction =() => 
 {
  
    props.CloseSetLoanMenuSwitch(false);
  
 }

 const NumberFomat_2 = (val) =>{

 
     
    var value = val * 1; 
    var result =  value.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return  result;  


}

 const NumberFomat = (val) =>{
    
    if( val > 0){
          var value = val* 1; 
          var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return  Naira+result;  
    }else{

            return  Naira+0.00;  
    }
                                                     
     
    
}
 


const LoanAccountRate = () =>{
//Entypo
    var quest = 0;
    if( loginData.card_main_kpi == 'good'){
            quest =<div><AiFillStar    size={15}       /><AiFillStar    size={20}        /><AiFillStar    size={25}        /><AiFillStar    size={30}        /></div>;
    }else  if( loginData.card_main_kpi == 'bad'){
             quest = <div><AiFillStar    size={24}       /><AiFillStar    size={24}        /></div>;
    }if( loginData.card_main_kpi == 'scam'){
        quest = <div><AiFillStar    size={24}       /></div>;;

    }
    
                                                     
  return quest;   
    
}
 


const LoanInterestFomat = (AmtLoan,loanDays) =>{

    

    if( AmtLoan > 0){
          var value =  AmtLoan / 100 *  loginData.loan_rate; //daily rate
          var NewRate =  value * loanDays;     //2Monthly rate

          var result =  NewRate.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return  Naira+result;  
    }else{

            return  Naira+0.00;  
    }
                                                     
     
    
}
 
  
const onSearchLoan = (text) => {

//setFilterGetAllCountry getAllCountry
    if (text == '') {
      
        setFilterGetAllCountry('');
  
    } 
    else
    {
  
      let tempList = getAllCountry.filter(item => {
        return item.id.toLowerCase().indexOf(text.toLowerCase()) > -1;
      });
      setFilterGetAllCountry(tempList);

      setMessageSwitch_2(true)
    }
  
  
  
  }
   

const  ReLoadHanduler =() =>{

 
    setActivateLoader(false);  
    setIsLoading2(false);
  
 
} 

 
if(activateLoader)
{


        if(isLoading2)
        {

              return(
                  <Modal  ReLoadHanduler={ReLoadHanduler}  show={true}   > 
                     <Loading  />
                  </Modal>
       
              )
              
        }
        else 
        {
              return(
                  <Modal  show={true}   > 
                  <Loading2  ReLoadHanduler={ReLoadHanduler}  />
                  </Modal>
              )

        }  



    
}
else
{

  return (
    <div>
    <div>



                <div className="ContainerBox"  style={{ justifyContent: 'space-around',display:'flex',flexDirection:'row',width:'100%',padding:20}}>

                        <div  onClick={() => changeTopTabs('categories')}   style={{width:'40%' }}>
                        <div className="button" style={{paddingBottom:5,fontSize:12}}>
                        <div style={{color:'white',textAlign:'center',}}>LOAN</div>
                        <div style={{color:'white',textAlign:'center', }}>CATEGORIES</div>
                        </div>            
                        </div>



                        <div  onClick={() => changeTopTabs('history')}     style={{width:'40%',}}>
                        <div className="button" style={{paddingBottom:5,fontSize:12}}>
                        <div style={{color:'white',textAlign:'center',}}>CHECK  </div>
                        <div style={{color:'white',textAlign:'center', }}>LOAN STATUS  </div>
                        </div>            
                        </div>

        
                </div>

 
                <div style={{paddingLeft:20,}}>
                <div>
                  <div  style={{color:'black',fontSize:16,divTransform:'uppercase'}}>Loan Recipient </div>
                  <div style={{color:MainColor,fontWeight:'bold',fontSize:16}} >{loginData.fullname}</div>               
                </div>
                <div>
                  <div  style={{color:'black',fontSize:16,divTransform:'uppercase'}}>Loan Recipient Phone</div>
                  <div style={{color:MainColor,fontWeight:'bold',fontSize:16}} >{loginData.username}</div>               
                </div>

                <div>
                    <div  style={{color:'black',fontSize:16,divTransform:'uppercase'}}>Account Shares(SH) </div>
                    <div style={{color:MainColor,fontWeight:'bold',fontSize:16}} >{NumberFomat_2(loginData.golden_coin_earn)}SH </div>
                </div>

                <div>
                    <div  style={{color:'black',}}>Account Loan Rating </div>
                    <div style={{color:'gold',fontWeight:'bold',}} >{LoanAccountRate()}</div>
                </div>

                </div>


        {loanGategoriesSection?

                <div style={{padding:10}}>

                <div>
                <div style={{color:'#777777',textAlign:'center',fontWeight:'bold',marginTop:5,marginBottom:10}}> NON-CASH REFUNDABLE LOAN </div>
                <div style={{color:'#777777',textAlign:'center',fontWeight:'bold',marginTop:5,marginBottom:10}}> CATEGORIES </div>
                </div>

                    <div style={{display:'flex',flexDirection:'row',width:'100%',}}>

                                                

                        <div style={{width:'30%', }}> 
                        <div style={{color:'black',textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                        Available Loans
                        </div>    
                        </div>

                    
                        <div style={{width:'30%',marginLeft:'2.5%'}}>
                        <div style={{color:'black',textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                        Loan Requirement 
                        </div>                           
                        </div>  


                        <div style={{width:'30%',marginLeft:'2.5%' }}>
                        <div style={{color:'black',textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                        Repayment Plan
                        </div>                           
                        </div>  


                    </div>

                    <div> 
{//////////////////////////  2,500
}                  
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,}}>



                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                        {NumberFomat(loanAmt.loan_1)} <BsChevronDoubleRight    size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div    >
                                        <div style={{textAlign:'center',color:'black',fontSize:16,}}>
                                        <img  style={{width: 20, height: 20, }}   src='../images/gcoin.png' />  10SH
                                        </div>
                                        </div>

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div   >
                                        <div style={{textAlign:'center',color:'black',fontSize:16,}}>
                                        1 Month
                                        </div>
                                        </div>

                                        </div>



                                </div>
{//////////////////////////  5,000
}  
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>


                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                        {NumberFomat(loanAmt.loan_2)} <BsChevronDoubleRight    size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div >
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                        <img  style={{width: 20, height: 20, }}   src='../images/gcoin.png' />  12SH
                                        </div>
                                        </div>

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div   >
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                         1 Month
                                        </div>
                                        </div>

                                        </div>
            
                                </div>
{//////////////////////////  10,000
} 
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>


                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                        {NumberFomat(loanAmt.loan_3)}  <BsChevronDoubleRight      size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div   >
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                        <img   src='../images/gcoin.png' style={{width: 20, height: 20, }}   />  15SH
                                        </div>
                                        </div>

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div  >
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                         2 Months
                                        </div>
                                        </div>

                                        </div>
            
                                </div>
{//////////////////////////  15,000
} 
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>


                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>   
                                        {NumberFomat(loanAmt.loan_4)}  <BsChevronDoubleRight    size={20} color={'black'}      />
                                        </div>
                                                                                                     
                                        </div>

                                        <div style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div >
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                        <img  style={{width: 20, height: 20, }}   src='../images/gcoin.png' />  25SH
                                        </div>
                                        </div>

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%',backgroundColor:'#f2f2f2'}}>

                                        <div>
                                        <div style={{textAlign:'center',color:'black',fontSize:15,}}>
                                         2 Months
                                        </div>
                                        </div>

                                        </div>
            
                                </div>


                    </div>

                    <div  onClick={() => changeTopTabs('loan')}   style={{width:'100%',marginTop:50}}>
                    <div className="button" >
                    <div style={{color:'white',textAlign:'center',}}>NEXT</div> 
                    </div>            
                    </div>
                </div>

                :
        loanHistorySection ?
 
                <div className="DataModalView">
                
               
 

                <div > 
                 
                <div style={{ width: '100%',padding:5,alignItems:'center'}}>

                  
            
                <div style={{width:'100%',fontSize:20,textAlign:'center',color:MainColor,fontFamily:'Poppins-Regular'}}> 
                LOAN HISTORY 
                </div>
                
                </div>   
                
                 
                </div>

   
 
    
                <div style={{textAlign:'left'}}>
 
                 
                {getAllCountry == ''? ( 
                        <div> 
                        Data Loading.. 
                        </div>
                ):(
                <span>
                
                    {getAllCountry.map((item) => (
                  
                            <div onClick={()=> onSearchLoan(item.id)} key={item.id} style={{backgroundColor: 'white',width: '100%', }}>


                            <div  className="grantStages">  
                            <div className="tabBlock1" style={{fontSize: 12,color:'black',backgroundColor:'#e9d1de'}}>
                                <p>Check Loan </p>  
                               <div className="indicatorLeft Loan-Status-bar1" style={{textAlign:'left',fontSize:18}}>  
                               {NumberFomat(item.loan_repay)}   
                                </div>  
                                 <div>
                                  Loan Refrence no:  :<b> {item.loan_id}   </b><br />
                                  Loan running Status :<b> {item.loan_status}</b><br />  
                                  Loan Requested Date :<b> {item.date}</b>
                                   
                                 </div>

             
                            
                               
            
                            </div>
                      </div> 
                            </div>
                            

                    ))}
                                        
                </span>

                )}


                   
                </div>    
                </div> 
 


                :
        loanRequestSection ?

                <div style={{padding:10}}>

                <div>
                <div style={{color:'#777777',textAlign:'center',fontWeight:'bold',marginTop:5,marginBottom:10}}> LOAN REQUEST</div>
                </div>

                    <div style={{display:'flex',flexDirection:'row',width:'100%',}}>

                                                

                        <div style={{width:'30%', }}> 
                        <div style={{color:'black',textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                        Available  <br/> Loans
                        </div>    
                        </div>

                    
                        <div style={{width:'30%',marginLeft:'2.5%'}}>
                        <div style={{color:'black',textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                        Loan <br/> Interest 
                        </div>                           
                        </div>  


                        <div style={{width:'30%',marginLeft:'2.5%' }}>
                        <div style={{textAlign:'center',fontSize:14,fontWeight:'bold'}}>
                       
                        </div>                           
                        </div>  


                    </div>




                    <div> 
        {//////////////////////////  2,500
        }                  
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,}}>



                                       <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                        {NumberFomat(loanAmt.loan_1)} <BsChevronDoubleRight      size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2',marginLeft:'2.5%'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}> 
                                         {LoanInterestFomat(loanAmt.loan_1, 30)}
                                        </div> 

                                        </div>


                                        <div   onClick={() => requestLoanButton(loanAmt.loan_1, 10,  1, 30)} style={{width:'30%',textAlign:'left',marginLeft:'2.5%'}}>

                                            <div   >
                                            <div className="button" style={{backgroundColor:'green',marginTop:-2}}>
                                                <div style={{textAlign:'center',color:'white',fontSize:16,}}>
                                                Request
                                                </div>
                                            </div>
                                           </div>

                                        </div>



                                </div>
        {//////////////////////////  5,000
        }  
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>


                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}> 
                                        {NumberFomat(loanAmt.loan_2)} <BsChevronDoubleRight    size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2',marginLeft:'2.5%'}}> 
                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                         {LoanInterestFomat(loanAmt.loan_2, 30 )}
                                        </div>  
                                        </div>


                                        <div onClick={() => requestLoanButton(loanAmt.loan_2, 12,  1, 30)}  style={{width:'30%',textAlign:'left',marginLeft:'2.5%'}}>

                                            <div    >
                                            <div className="button" style={{backgroundColor:'green',marginTop:-3}}>
                                                <div style={{textAlign:'center',color:'white',fontSize:16,}}>
                                                Request
                                                </div>
                                            </div>
                                           </div>

                                        </div>
            
                                </div>
        {//////////////////////////  10,000
        } 
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>



                                <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}>

                                <div  style={{textAlign:'center',color:'black',fontSize:16,}}>   
                                        {NumberFomat(loanAmt.loan_3)} <BsChevronDoubleRight      size={20} color={'black'}      />
                                        </div>

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2',marginLeft:'2.5%'}}> 
                                        <div  style={{textAlign:'center',color:'black',fontSize:16,}}> 
                                         {LoanInterestFomat(loanAmt.loan_3, 60 )} 
                                        </div> 
                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%'}}>

                                            <div   onClick={() => requestLoanButton(loanAmt.loan_3, 15, 2, 60)} >
                                            <div className="button" style={{backgroundColor:'green',marginTop:-3}}>
                                                <div style={{textAlign:'center',color:'white',fontSize:16,}}>
                                                Request
                                                </div>
                                            </div>
                                           </div>

                                        </div>
            
                                </div>
        {//////////////////////////  15,000
        } 
                                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:5}}>


                                       <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2'}}> 
                                       <div  style={{textAlign:'center',color:'black',fontSize:16,}}>  
                                        {NumberFomat(loanAmt.loan_4)} <BsChevronDoubleRight     size={20} color={'black'}      />
                                        </div>

                                        </div>

                                        <div  style={{width:'30%',textAlign:'left',backgroundColor:'#f2f2f2',marginLeft:'2.5%'}}> 
                                        <div  style={{textAlign:'center',color:'black',fontSize:16,marginLeft:'2.5%'}}> 
                                         {LoanInterestFomat(loanAmt.loan_4, 60 )}
                                        </div> 

                                        </div>


                                        <div  style={{width:'30%',textAlign:'left',marginLeft:'2.5%'}}>
                                        <div   onClick={() => requestLoanButton(loanAmt.loan_4, 25, 2, 60)} >
                                                <div className="button" style={{backgroundColor:'green',marginTop:-3}}>
                                                    <div style={{textAlign:'center',color:'white',fontSize:16,}}>
                                                    Request
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
            
                                </div>


                                </div>

                                <div  onClick={() => changeTopTabs('categories')}   style={{width:'100%',marginTop:50}}>
                                <div className="button" style={{backgroundColor:'green'}}>
                                <div style={{color:'white',textAlign:'center',}}>PREVIOUS</div> 
                                </div>            
                                </div>
                </div>

                :  
                null
        }


    </div>



           
                            
                        {/// LOAN PROCESS MODAL BOX  
                            loanProcessSwitch ?


                            <Modal    animationType='none'  >
                                <div  >
        
                                <div style={{width: '47%',marginLeft:'3%'}}>
                                <div   onClick={() => setLoanProcessSwitch(false)} style={{marginTop: 20}}>
                                <BsChevronDoubleRight     size={30}  color={'black'} />
                                </div>
                                </div>
        
                                <div className="Modaldiv_3">

 


                                <div style={{marginTop:20}}>
                                        
                                        <div style={{marginTop:2}}>
                                        <div className="lineFonts2"  style={{textAlign:'left',}}></div>
                                        </div>
                                            

                                        <div style={{alignItems:'flex-start'}}>
                                        <div style={{fontSize:20,fontWeight:'bold',fontFamily:'Poppins-bold',color:'#777777'}}> LOAN SUMMARY</div>
                                        </div>

                                        <div className="ContainerBox box_2" style={{marginTop:2}}>

                                        <div >
                                                <div style={{marginBottom:30}}>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Loan Principal</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:18,fontWeight:'bold'}}>{NumberFomat(loanAmt.loanPrincipal)}</div>                             
                                                </div>
                                        

                                                <div>                       
                                                    <div  className="lineFonts2" style={{textAlign:'left',color:'black'}}>Loan Repayment </div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:18,fontWeight:'bold'}}>{NumberFomat(loanAmt.loanRepay)}</div>                                        
                                                </div>
                                        </div>

                                                                                                                     
                                        <div>

                                                <div style={{marginBottom:30}}>                       
                                                <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Loan Intrest</div>
                                                <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:16,fontWeight:'bold'}}>{NumberFomat(loanAmt.loanInterest)}</div>                                        
                                                </div>


                                                <div>                       
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black'}}>Loan Duration</div>
                                                    <div className="lineFonts2" style={{textAlign:'left',color:'black',fontSize:16,fontWeight:'bold'}}>{loanAmt.loanDuration} Month(s)</div>                                        
                                                </div>

                                        
                                        </div>

                                        </div>
                                                                            

                                </div>

                            <div>
                                <div style={{fontSize:14,color:'#777777',fontFamily: 'Poppins-Bold',marginBottom:10,marginTop:10}}>
                                Please do note that your shares(SH) profit sharing  will be on hold for a period 
                                of {loanAmt.loanDuration} Month(s) provided that your profit sharing  are available to service your loan repayment 
                                </div>

                                <div style={{fontSize:14,color:'#777777',fontFamily: 'Poppins-Bold',marginBottom:10,marginTop:5}}>
                                However your shares(SH) profit sharing  will be unhold as soon as all loans are settled
                                </div>


                                <div style={{fontSize:14,color:'red',fontFamily: 'Poppins-Bold',marginBottom:10,marginTop:5}}>
                                Note: Payment of channel / maintenance fee must be paid duly during your loan repayment duration, failure to pay 
                                 will reflect negatively on your account loan rating
                                </div>
                            </div>

        
        
                            <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:20}}>
        
                    
        
                            <div onClick={LoanSubmitHandler} style={{width:'100%',}}>
                            <div className="button">
                            <div style={{textAlign:'center',color:'white',fontSize:16,}}>Submit loan for processing </div>
                            </div>
                            </div>
        
        
        
                            </div>
        
                                        
        
                                </div>
                                </div>
                            </Modal>
                            :  
                            null
        
                         }


      



                     {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?
                  
                        
                        <Modal  show={true}    > 
                        <div style={{flex:1,backgroundColor: MainColor}} onClick={() => setMessageSwitch(false)}   >
                        <div>
                  
                              <AiFillCloseCircle    size={25}  color={'white'} />                         
            
                              <div   style={{marginTop: 20,padding:20}} >
                               
                                    <div  style={{fontSize: 14,color:'white'}} >{message}</div>
                              </div>

                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null

                        }


                     {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch_2?
                  
                        
                        <Modal  show={true}    > 
                        <div style={{flex:1,backgroundColor: 'white'}} onClick={() => setMessageSwitch_2(false)}   >
                        <div>
                  
                                            
            
                              {filterGetAllCountry.map((item) => (
                                
                                <div  key={item.id} style={{backgroundColor: '#f2f2f2',width: '100%',textAlign:'left', }}>
                             
    
                                <div  className="grantStages">  
                                <div className="tabBlock1" style={{fontSize: 15,color:'black',}}>
                                <AiFillCloseCircle    size={25}  color={MainColor} />
                                
                                       <center>
                                           <div className="indicatorLeft Loan-Status-bar1" style={{textAlign:'left',fontSize:20 }}>  
                                           <GiMoneyStack  style={{color:MainColor,fontSize:50,}}   /> <br />
                                            {NumberFomat(item.loan_repay)}   
                                            </div>                                     
                                       </center>

    
    
                                      
                                     
                                       <p>Loan Amount  :<b> {NumberFomat(item.loan_amt)}   </b>    </p>
                                       <p>Loan Interest  :<b> {NumberFomat(item.loan_interest)}   </b>    </p>
                                       <p>Loan running Status <b> {item.loan_status}</b></p> 
                                       <p>Loan Payout Status <b> {item.payout_status}</b></p> 
                                       <p>Loan Requested Date <b> {item.date}</b></p>
                                       <p>Loan Repayment Date <b> {item.loan_repay_date}</b></p> 
                                       <p>Loan Refrence no:<b> { item.loan_id}  </b>    </p> 
                                 {item.loan_repay == '0'?
    
                                    <button     className="btntab--active font_b" style={{width: '100%',height:70,backgroundColor:'green',color:'white' }}>
                                    Loan repayment Completed <br />
                                   
                                    </button>  
    
                                    :
    
                                    <button     className="btntab--active font_b" style={{width: '100%',height:70,backgroundColor:'red',color:'white'}}>
                                    Loan repayment running..
                                    </button>  
    
                                 }
                                 
                                
                                   
                
                                </div>
                          </div> 
                                </div>
                                
    
                        ))}
                              
                        </div>
                        </div>
                  
                        </Modal>
                        :  
                        null

                        }
    </div>


  )

 }





}
 
export default memo(GetInstantLoan);  
 