import logo from './logo.svg';
import Home from './components/Home/Home';  
import './App.css';

function App() {
  return (
    <div className="App">
    <Home   /> 
    </div>
  );
}

export default App;
