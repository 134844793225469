 const BaseUrl ="https://rsbdigitalshares.com.ng";   
 const Url     ="https://rsbdigitalshares.com.ng/serverfiles/core_files/";   
 const UrlImg  ="https://rsbdigitalshares.com.ng/serverfiles/core_files/";   
 const UrlCall ="https://rsbdigitalshares.com.ng/serverfiles/core_files/call_api.php?action=";   
//  const BaseUrl    ="http://127.0.0.1/rsbdigitalshares.com.ng";   
//  const Url        ="http://127.0.0.1/rsbdigitalshares.com.ng/serverfiles/core_files/";   
//  const UrlImg     ="http://127.0.0.1/rsbdigitalshares.com.ng/serverfiles/core_files/";   
//  const UrlCall    ="http://127.0.0.1/rsbdigitalshares.com.ng/serverfiles/core_files/call_api.php?action=";   
 //--------------------------------------------------------------------------------
export const BasePageAction      = UrlCall;
export const BaseAction          = UrlCall; 
//--------------------------------------------------------------------------------

export const InviteLink          = ''; //new 
export const LoanData            = Url+'rsb_grant/call_api.php?action=LoanData'; 
export const LoanMyInvitee       = Url+'rsb_grant/call_api.php?action=MyTeamDownline'; 
export const BaseReg             = Url+'rsb_grant/call_api.php?action=SignupRegister'; 

export const PaymentApi          = Url+'rsb_grant/call_api.php?action=virtualCard_payment';
export const CardPinBack         = Url+'rsb_grant/public_banner/customer_card_back/';
export const CardPin             = Url+'rsb_grant/public_banner/customer_card/'; 
export const ReloadBaseAction    = UrlCall+"reloadDataApi.php?action="; 
//--------------------------------------------------------------------------------
export const TelegramLink        ="https://chat.whatsapp.com/H4YtYTRUNbF8esG1eGc7xo";   
export const WhatAppCommunity    ="https://whatsapp.com/channel/0029VaEnllS5K3zRmWG1ZJ1A";
//-------------------------------------------------------------------------------- 
export const AccountUpdate       = Url+'rsb_grant/call_api.php?action=accountUpdate'; 
export const AccountRecovery     = Url+'rsb_grant/call_api.php?action=accountRecovery';  
export const SubscriptionUrl     =BaseUrl+"?fullname=";
export const CardFrontUrl        =UrlImg+"public_banner/customer_card/";  
export const CardBacktUrl        =UrlImg+"public_banner/customer_card_back/"; 
export const AbuseEmail          = "abuse@rsb.com.ng";
export const ProfileImgLink      = UrlImg+'profile_img/'; 
export const BasePlaceholder     =UrlImg+'images/';
 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////   
  
export const publicKey            = 'pk_live_6bf63c5e119926617abf628f963775c5deb029fe';

export const Naira                = "₦";
export const TopColor             = "#eee";
export const BodyColor            = "#ca1f7b";
export const BgColor              = "#eee";
export const TabBodyColor         = "#fff";

export const TextWhite            = "white";
export const TextBlack            = "black"; // 
export const LightBlack           = "#d0cfcf"; // 
export const LighterBlack         = "#eee"; // 
export const TextColor2           = "#777777";

export const MainColor            = "#ca1f7b"; //TOP AND BOTTOM BARS 0175be
export const MainColor2           = "#e89ec6"; //TOP AND BOTTOM BARS 0175be

 

export const TopBottomTextColor        = "#fff"; 
export const TopTabColor               = "#777777"; 
//////////////////////////////////////////////////////////////////////////////////////////////////////
export const CardMaintSuccess   = Url+'call_api.php?action=CardMaintSuccess';
export const CardTopUpUrl       = Url+'call_api.php?action=CardTopUpUrl';
export const SubscriberPayment  = Url+'call_api.php?action=AppRenew';
 
 
 

//////////////////////////////////////////////////////////////////////////////////////////////////////
 /*
 
 



*/

 
//EH27539   10-12-1996  FINNIAN CHIMAOBI  ADINNUEME
 // publicKey: 'pk_live_6bf63c5e119926617abf628f963775c5deb029fe', 
 //sk_test_7513c3e133ab3a204c146030e400f4e5e0afbd3b
 //pk_test_e71cc81bac23486cee2d272af354ef96f79ba336