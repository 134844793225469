import React  from 'react';
import './Modal.css'; 
import Backdrop from '../Backdrop/Backdrop';
import { Modal, Button } from 'react-bootstrap'

const MyModal = (props) => (
    props.show ?  
     
    <Modal Show={true}>
  
        <div className="ModalBlock">
          <span> {props.children}  </span>
        </div>
        
        
    </Modal> 
    : 
    null
);

 
export default MyModal;
// <Backdrop  show={props.show}  clicked={props.clicked} />
 //show={this.props.show} clicked={this.props.modalClosed}
//  <div>
//   <Backdrop  show={this.props.show}  clicked={this.props.clicked} />
//      <div
//          className={classes.Modal}
//              style={{
//              transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
//              opacity: this.props.show ? '1' : '0'
//              }}>
//          {this.props.children}
//      </div>
//  </div>