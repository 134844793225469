import React, { useState, useEffect } from "react";
import  { useNavigate } from 'react-router-dom' 
 
 
 

const ErrorPage = (props) => {  
  const navigate = useNavigate();   
const[loginData, setLoginData] = useState([]); 
 
 
  const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
    if(data.card_main_status == 'passive'){
      navigate('/')
 
    }
    
  }
  
  useEffect(() => { 
  setTimeout(async() => { 
        LoginOptionUpdate();   
  }, 5000);
  
  
                    
  });
  
  useEffect(() => { 
    LoginOptionUpdate(); 
  },[]); 
 
 
 //fund-credit-card
 
        return (

        <div  style={{textAlign:"center",padding: 20,height:'100vh'}} > 

          <div style={{marginTop: 100 }}>
          <h1> Error !!404!!</h1>

          </div>
          <div> Page not available</div>



          <div   style={{marginTop: 100,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >




              <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => navigate('/')}  > 
              <div style={{color:'white'}}>Go Home </div>
             </div>  
          </div>
        </div>
      )
   
  

}
export default ErrorPage; 

  