import React, { useState,useEffect, useRef,useLayoutEffect,memo} from 'react';
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom';
import { GoUnverified, GoVerified   } from "react-icons/go";   
import { TbHandStop,TbReload   } from "react-icons/tb";   
import { GiTrade} from "react-icons/gi";  
import { FiUsers} from "react-icons/fi";  
import { FaArrowLeft,FaUserTie} from "react-icons/fa";  
import { IoIosCloseCircle,IoIosOptions} from "react-icons/io";  
import {BsFillBellFill,BsSearch,BsThreeDotsVertical} from "react-icons/bs";   
import {Naira, BaseAction, MainColor, BasePlaceholder} from '../../BaseUrl';
import {Card, Modal} from 'react-bootstrap';
import { AuthContext } from '../../components/context';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";
import './BuyGoldenCoin.css'; 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import paginationFactory from 'react-bootstrap-table2-paginator';


//Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

 
const BuyGoldenCoin = (props) => {
  const[activateLoader, setActivateLoader]   = useState(true); 
  const[isLoading2, setIsLoading2]   = useState(true);
  const[tradeCoinData, setTradeCoinData] = useState([]);
  const[loginData, setLoginData] = useState([]); 
  const[shortMenuModal, setShortMenuModal]   = useState(false); 
  const[coinseller, setCoinseller] = useState('');   
  const[message, setMessage] = useState(''); 
  const[messageSwitch, setMessageSwitch] = useState(false);
  const[messageNote, setMessageNote] = useState(false);
  const[contactSeller, setContactSeller] = useState(''); 
  const[searchBar, setSearchBar] = useState(false);
  const searchRef = useRef();
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [search, setSearch] = useState();
  const [page, setPage] = useState('')
    const navigate = useNavigate();   

    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
    setLoginData(data);
      
    }
    
    useEffect(() => {
          
        setTimeout(async() => {
        
            LoginOptionUpdate();  
        
        }, 5000);
                        
    });
    
    useLayoutEffect(() => {
    
          LoginOptionUpdate();


    },[]); 

 // $(document).ready(function () {
  $('#example').DataTable();
  //});

  
    
      

 
 
  const callAllData = () =>{
     
    setActivateLoader(true);
    setIsLoading2(true);
  
   var authUserToken =  localStorage.getItem('authUserToken');;
  
    var headers = { 'Accept': 'application/json',  'Content-type': 'application/json'  }
    fetch(BaseAction+"GoldenCoinSeller&passkey="+authUserToken,
       {
       method:'GET',
       header:headers
       })
              
             .then((response) => response.json())
             .then((responseJson) =>
             {  
                
            
                 
                 setOldData(responseJson);
                 setData(responseJson);
       
                 setActivateLoader(false);
                 setIsLoading2(false);
       
           
           
  
           })
           .catch((error) => { 
               setIsLoading2(false);   
               
             });
   
  
  
  
  
  }
  
  useEffect(() => {

    let runNow = true
   
      
        if(runNow){ 
  
        callAllData();
  
        }
        
      
  
  
      return () => {
        runNow = false;
      }; 
   
   
   
    },[tradeCoinData]);



  const  ChallengUser =(val,val2) =>{
 

    var value =  loginData.golden_coin_value * 1; 
    var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       



    if(loginData.vcard_bal >= loginData.golden_coin_value)
  {
            if(loginData.userkey === val )
            {
              setMessageNote(true)
              setMessage('Sorry you can not send a trade request to your self');
              setMessageSwitch(true); 
            

            }else{
              setMessageNote(false)
              setCoinseller(val); 
              setMessage('Hi '+loginData.fullname+', are you sure you want to buy 1 Shares(SH)  from   '+val2+'  ?, if Yes '+Naira+result+' will be deducted from your credit card as soon as  '+val2+' approves your trade request. Thanks');
              setMessageSwitch(true); 

              
            }

  }
  else
  {
    setMessageNote(true)
    setMessage('Hi '+loginData.fullname+', you need minimum of '+Naira+result+' to buy or send Shares(SH) trade request to seller');
    setMessageSwitch(true); 

  }

}   

//BUYER - TO -SELLER 
const seamlessBuyerToSeller = () =>{

 
  setMessage('');
  setMessageSwitch(false);


        var headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json'
        }
        var userPasskey =  localStorage.getItem('authUserToken');
        fetch(BaseAction+"SeamlessBuyGoldenCoin&BuyerPassKeyToken="+userPasskey+"&SellerUserKeyToken="+contactSeller+'&TradeFrom=buyer',
        {
        method:'GET',
        header:headers, 
        })
        .then((response) => response.json())
        .then((response) => { 
              
   

              setMessageNote(true);
              setMessageSwitch(true);
              setMessage(response[0].feedback);
              
              
        }) 
        .catch(error => {
               
        });
  

}

 

const showHandler =()=>{
  setMessage('');
  setActivateLoader(false);
  setIsLoading2(false);
  

}
        
  


const OnSearch = (text) => {
  
  
  if (text == ''){ 
    setData(oldData); 
  }else{
      let tempList = data.filter(item => {
    return item.fullname.toLowerCase().indexOf(text.toLowerCase()) > -1; 
  });
  
  setData(tempList);
 // currentTableData=tempList;
  
  }
}
 
const ContactHandler = (val1, val2) => {

  setContactSeller(val1);
  ChallengUser(val1,val2);
  

}
  
if(activateLoader){
  
      if(isLoading2)
      {
  
  
        return (     
      
              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );
  
  
      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{message} </small>
                  </Loading2> 
              </div>
          );
      }
  
  
  
  

    }
else
{ 

    return (
  
          <div class="table-responsive">
  
    
               <Row style={{paddingTop:20,}}>
               <Col style={{fontSize:20,marginBottom:10,}}>
                SHARES SELLER 
               </Col> 
             </Row>
                    

          <table id="example"   class="table-responsive table  table-bordered table-striped display"  style={{padding:15,width:'100%',marginTop:50}}>

          
          <thead  style={{}} >
            <tr>
             
              <th  style={{ width: '20%',padding:15 }}>Shares(SH) Sellers</th>
              <th  style={{ width: '20%',padding:15  }}>Shares </th> 
              <th  style={{ width: '80%',padding:15  }}> Message </th> 
            </tr>
          </thead>

          <tbody  > 
            {data.map(item => {
              return (
                <tr   onClick={() => ContactHandler(item.username,item.fullname)}   >

                  <td   style={{display:'flex',flexDirection:'row'}} >
                   <div > <img src="images/placeholder.png"   height={40} width={40}  />  </div>
                   <div style={{marginLeft:5,fontSize:14}}> {item.fullname}</div> 
                   
                  </td>

                  <td    style={{ fontSize:14    }} >
                  <img src='images/1gcoin.png'  style={{width: 30, height: 30, }} />  {item.golden_coin_earn}Shares
                  </td>

                  <td > 
                  <div style={{ color:'black', fontSize:14  }}  >  Hi, i have Shares to sell. Click  to buy</div>
                 
                  </td> 
                </tr>
              );

              
            })}
          </tbody>

 
          </table>
 
  
 


                     {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)  
                      messageSwitch?
      
            
                      <Modal  show={true}  >
                      <div  onClick={() => setMessageSwitch(false)}  style={{padding: 20}}  >
  
          

          
                             <div>

                      
                            <GiTrade    size={30}  color={MainColor} /> 
                            <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}> SHARES(SH) TRADE REQUEST</div>  
                      
                            
          
          
                            <div   style={{marginTop: 20}} >
                            
                                  <div  style={{fontSize: 14,color:'black',}} >{message}</div>
                            </div>


                           
                            

                              { messageNote?
                                <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                                    <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                    <div style={{color:'white'}}>Close</div>
                                    </div>  
                                </div>
                                :
                                <div   style={{marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >

                                  <div  className="buttonBusy"  style={{padding:10,fontSize:14,width:'45%',}}  onClick={() => setMessageSwitch(false)}  >
                                  <div style={{color:'white'}}>Cancel</div>
                                  </div>  

                                  <div  className="buttonBusy"  style={{padding:10,marginLeft:20,fontSize:14,width:'45%',backgroundColor:'green'}} onClick={() => seamlessBuyerToSeller()}  > 
                                  <div style={{color:'white'}}>Yes</div>
                                  </div>

                                </div>
                              }



                            
                            
                            </div>
                      </div>
                
                      </Modal>
                      :  
                      null
          
                      }   
                      
                      {/// 
                      shortMenuModal?


                      <Modal  show={true}        >
                            <div   onClick={() => setShortMenuModal(false)}   >
                                                   
                                  
                                 <div style={{backgroundColor: 'white',borderTopLeftRadius:5,borderTopRightRadius:5,marginTop:80,paddingBottom:50}}>
                                         
                                  
                                     
                                     <div  style={{ marginTop: 15, alignItems:'center'}}>
 
                                          <div style={{fontFamily: 'Poppins-Regular',fontSize: 15,textAlign:'center',color:'black',textDecorationLine:'underline' }}> 
                                          Search Golden Coins
                                          </div>      

                                     </div>


                                     <div style={{width: '100%',marginLeft:10,alignItems:'flex-start'}}>
             
                                   
          
                                     <div>
                                          <div  style={{marginTop:15}}
                                           onClick={() => { setData(data.filter((a) => a.golden_coin_earn == 1)) }}>

                                          <div style={{fontSize:16,color:'black'}}> <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} /> 1GC</div> 
                                          </div>
                                     </div>           

                                     <div>
                                          <div    style={{marginTop:15}}
                                          onClick={() => { setData(data.filter((a) => a.golden_coin_earn == 2)) }}
                                          >
                                          <div style={{fontSize:16,color:'black'}}>
                                           <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} /> 
                                           <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} /> 
                                           2GC</div> 
                                          </div>
                                     </div>           
                                     <div>
                                     <div    style={{marginTop:15}}
                                     onClick={() => { setData(data.filter((a) => a.golden_coin_earn == 3)) }}
                                     >
                                          <div style={{fontSize:16,color:'black'}}> 
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                           3GC</div> 
                                          </div>
                                     </div>           

                                     <div>
                                          <div    style={{marginTop:15}}
                                          onClick={() => { setData(data.filter((a) => a.golden_coin_earn == 4)) }}
                                          >
                                          <div style={{fontSize:16,color:'black'}}> 
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                          <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} />
                                           4GC</div> 
                                          </div>
                                     </div>    

                                     <div>
                                          <div   
                                          onClick={() => { setData(data.filter((a) => a.golden_coin_earn > 4)) }}
                                          >
                                          <div style={{fontSize:16,color:'black'}}> <img src='images/gcoin_img.png'  style={{width: 50, height: 50, }} /> 5GC+</div> 
                                          </div>
                                     </div>  

                                     <div>
                                     <div    style={{marginTop:15}}    >
                                     <div style={{fontSize:14,color:'black'}}><FiUsers size={20} color={'#777777'} /> Whatsapp Community</div> 
                                     </div>
                                     </div> 



                                     <div>
                                     <div    style={{marginTop:15}}   >
                                     <div style={{fontSize:14,color:'black'}}><TbReload size={20} color={'#777777'} /> Reload Data</div> 

                                     </div>
                                     </div> 


                                </div>       
                   
                   
                     
 
    
                                  
                                  </div>
                           
    
                            </div>
                      </Modal>
                      :  
                      null
    
                      } 
            </div>
      )
  
 }
     
};
  


 
export default memo(BuyGoldenCoin);  



        