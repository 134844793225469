import React, { useState, useEffect,useLayoutEffect} from "react";
import  { Navigate ,NavLink,Link,useNavigate} from 'react-router-dom' 
import {BsArrowRight,BsChevronRight, BsFillEyeSlashFill,BsFillEyeFill,BsEye,BsEyeSlash} from "react-icons/bs";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {AiFillCloseCircle} from "react-icons/ai";    
import { MdVerified} from "react-icons/md";      
import {GiPadlock,GiPadlockOpen,GiCheckMark} from "react-icons/gi";  
import { Modal, Button,Form } from 'react-bootstrap'  
import { MdOutlineGppGood, MdGppGood,MdGroups} from "react-icons/md";   
import {FaRegCopy,FaHourglassHalf,FaCoins,FaGamepad,FaUsers,FaEnvelopeOpenText,FaRegMoneyBillAlt,FaUnlock,FaLock,FaLockOpen,FaRegTrashAlt,FaHistory} from "react-icons/fa";  
import { BsCheck2Circle } from "react-icons/bs";  
import { AiOutlineReload} from "react-icons/ai";  
import { AuthContext }    from '../../components/context';
import md5                from 'md5-hash';
import './Dashboard.css'; 
import '../../App.css'; 
import Loading1           from '../Loading';
import Loading2           from '../Loading2';
import GetInstantLoan     from '../GetInstantLoan';
import { Naira, AbuseEmail, CardFrontUrl,BaseAction,TextColor2, MainColor,} from '../../BaseUrl';  
import AdSense from 'react-adsense'; 


 //[loginData, setLoginData] 

const Dashboard = (props) => {
const { signOut,reloadUserData,reloadUserData_2} = React.useContext(AuthContext);
const[activateLoader, setActivateLoader]   = useState(false); 
const[isLoading2, setIsLoading2]   = useState(false); 
const[loanMenuSwitch, setLoanMenuSwitch] = useState(false);  
const[goldenCoinMenuSwitch, setGoldenCoinMenuSwitch] = useState(false);  
const[inviteLinkModal, setInviteLinkModal] = useState(false);     
const[bonusMiningMsg, setBonusMiningMsg] = useState(false);      
const[ramadanMsg, setRamadanMsg] = useState(true);      
const[loginData, setLoginData] = useState([]); 
const[message, setMessage] = useState('');     
const[messageSwitch, setMessageSwitch] = useState(true) 
const[tradeCoinModal, setTradeCoinModal] = useState(false); 
const [viewBalance, setViewBalance] = useState(false);
const[voucherCode, setVoucherCode]   = useState(''); 
 const[data, setData] = useState({
      message: '',
      password: '',
      coinUnit: '',
      coinValue: '',  
      coinAmt: '',  
      receiverCode: '',  
      receiverName: '',  
      check_textInputChange: false,
      secureTextEntry: true 
   })
  
 
   const updateSecureTextEntry = () => {
      setData({
          ...data, 
          secureTextEntry: !data.secureTextEntry
          });
      
      }

 
  const navigate = useNavigate();    

  const LoginOptionUpdate =() => 
  {
  
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
    
      if(data == null){
            signOut();
      }
      

  }
  
  useEffect(() => {
        
  
      setTimeout(async() => {
      
            LoginOptionUpdate();   
   

      }, 5000);



  



                    
  });
  
  useLayoutEffect(() => {
  
        LoginOptionUpdate();
        
      
  

      // setTimeout(async() => {
            if(loginData.golden_coin_earn >= loginData.shares_min){
            setBonusMiningMsg(false);
            }
      // }, 25000);

 
            if(loginData.RamadaAwoof >= 1){
               setRamadanMsg(false);
            }
       

      
  },[]); 


  useEffect(() => {
  
      if(loginData.public_msg == ''){
            setMessageSwitch(false)
      }


      // setTimeout(() => {
 
     

      // }, 5000);
        
        
  },[]); 



  

///  ANNUAL CASHOUT TO CARD
const biMonthlyCashout = () => {

  let currentDate = new Date(); 
        setActivateLoader(true);  
        setIsLoading2(true); 

 
if(  loginData.bimothly1_pay_date <=  currentDate.toISOString().split('T')[0] )
{


      if(  loginData.user_bank >  0 )
      {



  
          
          var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
            var authUserToken =  localStorage.getItem('authUserToken');
            fetch(BaseAction+"BiMonthlyCashout&token="+authUserToken,
            {
            method:'GET',
            header:headers
            })
            .then((response) => response.json())
            .then((response) =>{
                               
              setMessage(response[0].feedback)
              setMessageSwitch(true);
              setActivateLoader(false);  
              setIsLoading2(false); 

            }); 
        
      }
      else
      {

            setMessage('You have N0.00 in your bi-weekly profit sharing');
            setMessageSwitch(true); 
            
            setActivateLoader(false);  
            setIsLoading2(false);  
            
      

      }
}
else
{

  setMessage('Please you will be able to load your credit card with your bi-weekly profit sharing dividend on or after '+loginData.bimothly1_pay_date)
  setMessageSwitch(true);
  setActivateLoader(false);  
  setIsLoading2(false); 
    
} 




        
}


 

const monthlyBonus1Cal =() => {
  var result = 0;
  
  var val = loginData.user_bank;

  return result = NumberFomat(val);
    
};




  const QuestDone = () =>{
 var quest = 0;
var rate = loginData.shares_min;



if(rate == 2){

          if( loginData.level3_row_paid == 0){
            quest = 0
    }else  if( loginData.level3_row_paid == 1){
             quest = 50;
    }else  if(loginData.level3_row_paid == 2 || loginData.level3_row_paid > 2){
             quest = 100;
    }

}else if(rate == 3){

      if( loginData.level3_row_paid == 1){
            quest = 30
    }else  if( loginData.level3_row_paid == 2){
             quest = 70;                             
    }else  if( loginData.level3_row_paid == 3 || loginData.level3_row_paid > 3){
             quest = 100;
    }


}else if(rate == 4){

      if( loginData.level3_row_paid == 1){
            quest = 25
    }else  if( loginData.level3_row_paid == 2){
             quest = 50;                             
    }else  if( loginData.level3_row_paid == 3){
             quest = 75;                             
    }else  if( loginData.level3_row_paid == 4 || loginData.level3_row_paid > 4){
             quest = 100;
    }


}else if(rate == 5){

      if( loginData.level3_row_paid == 1){
            quest = 20
    }else  if( loginData.level3_row_paid == 2){
             quest = 40;
    }else  if( loginData.level3_row_paid == 3){
             quest = 60;
    }else  if( loginData.level3_row_paid == 4){ 
             quest = 80;                             
    }else  if( loginData.level3_row_paid == 5 || loginData.level3_row_paid > 5){
             quest = 100;
    }

}

                                                     
  return quest;   
    
}

 
 

const GoldenCoinConvert = (val) => {
  
      var NewVal = val / 100 ;
      
      return NewVal;
      
}

 
const coinUnitCalc = (val) =>{
     
  var valModify =  parseFloat(val.replace(/,/g,/./, ''));

    var coinValueRaw =  valModify * 1; 
    var rawValue =  coinValueRaw  * 1000; 
 
    var value= rawValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(value  == ''){

          setData({ 
          ...data,  
          coinValue: '0',  
          coinAmt: '0',  

          })

    }
    else
    {

                if(value == 'NaN' || value.length == 0)
                {
                  setData({ 
                  ...data,  
                  coinUnit: '0',  
                  coinValue: '0',  
                  coinAmt: '0',  

                  })
                }
                else
                {
                  setData({ 
                  ...data,  
                  coinUnit: val, 
                  coinValue: coinValueRaw,  
                  coinAmt: rawValue,  

                  })           
                }


    }
      
     
}




    const NumberFomat = (val) =>{

      var value =  val* 1; 

      if(val >= 1){
 
       var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
       return   Naira+result;  
       
      }else{
 
        return   Naira+'0.00'; 
 
      }
         
       
      
  }

  const NumberFomat_2 = (val) =>{

     
    var value =  val * 1; 
    var result =  value.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
      if(result = NaN){
 
            var result =   0;
            return   result;  
            
           }else{
      
             return   result;
      
           }
}


 
const copyToClipboard =(val) => {

   if(val == 1){
      setMessage('Your invite link  copied to clipboard ');         
      setMessageSwitch(true);
   }else{
      setMessage('Your trading code copied  to clipboard ');         
      setMessageSwitch(true);
   }

 
  };       
 


 
 
 const SellGoldenCoinHandler =()=>{

  setGoldenCoinMenuSwitch(false);
  setTradeCoinModal(true);

 }
 
 
 

 

 
  
 
  const coinTradeRequesToReceiver = () =>{

      setActivateLoader(true);  
      setIsLoading2(true); 
    
      if(data.receiverCode == loginData.level_1)
      {
            setActivateLoader(false);  
            setIsLoading2(false); 
    
            setMessage('You have entered your trading code, Please enter receiver trading code')
            setMessageSwitch(true);
    
      }
      else
      {
    
         
    
            var headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json'
            }
            var authUserToken =  localStorage.getItem('authUserToken');
            fetch(BaseAction+"CoinTradeRequesToReceiver&token="+authUserToken+"&receiverCode="+data.receiverCode+"&coinAmt="+data.coinAmt+"&seller_level_1="+loginData.level_1,
            {
            method:'GET',
            header:headers, 
            })
            .then((response) => response.json())
            .then((response) => { 
                  
    
                  setData({ 
                        ...data,  
                        coinUnit: '', 
                        coinValue: '',  
                        coinAmt: '',  
      
                        })  
    
                        reloadUserData_2();   
                        setActivateLoader(false);  
                        setIsLoading2(false); 
                        setTradeCoinModal(false) 
                        setMessageSwitch(true);
                  
                  
            }) 
            .catch(error => {
                  setActivateLoader(true);  
                  setIsLoading2(false);    
            });
      }
    
    
    }
    
 const showHandler =()=>{
      setMessage('');
      setActivateLoader(false);
      setIsLoading2(false);
      
   
   }

 
   const SubmitVoucherCode = () => {
      var authUserToken =  localStorage.getItem('authUserToken');
    
      setRamadanMsg(false)
        setActivateLoader(true);  
        setIsLoading2(true);  
      
      
      if(voucherCode.length == 0){
     
        setMessage('Voucher code is required');  
        setMessageSwitch(true);
        
        
        setActivateLoader(false);  
        setIsLoading2(false); 
      }
      else
      {
         var headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                        }
          
               fetch(BaseAction+"RamadanAwoof&userToken="+authUserToken+'&VoucherCode='+voucherCode,
                    {
                    method:'GET',
                    header:headers,
            
                    })
                    .then((res) => res.json())
                    .then((response) => {
    
                        
                        setActivateLoader(false);  
                        setIsLoading2(false);       
                                setMessage(response[0].feedback);  
                                setMessageSwitch(true) 
                 
                               
    
                    })  
                    .catch(error => {
                      
                        setActivateLoader(true);  
                        setIsLoading2(false); 
                    });
            
      }
        
    }

   if(activateLoader){

    if(isLoading2)
    {


      return (     
    
            <div> 
              <Loading1  Show={showHandler}   /> 
            </div>
          );


    }
    else
    {
        return (         
            <div> 
                <Loading2   Show={showHandler} >
                    <small style={{color: 'black',fontSize:13}}>{message} </small>
                </Loading2> 
            </div>
        );
    }




}
  else
  { 

    if( loginData.game_status == 'in-game')
    {

      return (
      <Modal  transparent={true}  animationType='slide'  >
            <div style={{flex:1,backgroundColor: 'rgba(0, 0, 0, 0.7)', }}>



            <div className="ModaldivQuest"> 

    

            </div>





            </div>
      </Modal>
      );


    }
    else
    {
      
      return (
    
            <div  style={{textAlign:"center", backgroundColor:MainColor,}} >

                  
                {
                //////////////////////////// MAIN PAGE START HERE ///////////// 
                //////////////////////////////////////////TOP  HEADER 222
                }

                <div className="DasDividend1">


                

                                  <div  className="TopContainerRight"> 

                                  <div style={{marginTop:20}}  > 
                                  <MdVerified  style={{fontSize:15,}}/>  
                                  </div>
                                
                                  </div>



                                  <div  className="TopContainerLeft">

                                      
                                    
                                    <div className="" style={{color: '#fff'}}>  
                                            {loginData.mem_fee == 'paid'?
                                                    viewBalance?

                                                        <div onClick={() =>setViewBalance(false)}> 
                                                              <div className="CreditCardFont" style={{color:'#fff', }}> **** <BsEyeSlash  style={{fontSize:25,paddingLeft:5}}  color={'#fff'} /> </div>
                                                        </div>
                                                    :
                                                              <div onClick={() =>setViewBalance(true)}> 
                                                        <div className="CreditCardFont"  style={{color:'#fff',}}>{ NumberFomat(loginData.vcard_bal) } <BsEye   style={{fontSize:25,paddingLeft:5}}  color={'#fff'} /> </div>
                                                        </div>
                                            :
                                            <div style={{fontSize:30,color:'#fff'}}>****</div>
                                            }     

                                          <div style={{color:'#fff',fontSize:12}}>Credit Card Bal</div>
                                    </div>


                                    <div  className="TopContainerPrice"> 

                                        <div style={{flexDirection:'row',display:'flex',alignItems:'flex-start',backgroundColor:'#fff',borderRadius:800,padding:5}}>

                                        <img src="images/1gcoin.png"  style={{width: 20, height: 20, }} /> 

                                        <div style={{color:MainColor,fontSize:11,}}>1 Shares(SH) Selling Price = {NumberFomat(loginData.golden_coin_value)}</div>

                                    
                                    </div>
                                  
                              </div>   

                              
                                <div >
                          
                                    
                                      </div>
                                        
                                  </div>

                
                                  <div className="TopMiddleContainer">

                                        
                      
                                        <div className="huge_font_Top" style={{color: '#fff',marginTop:40}}> 

                                        <div style={{alignItems:'center'}}>
                                              <div className="AutoBigerFont" style={{color:'#fff'}}>RSB DIGITAL SHARES  </div>
                                              <div style={{color:'#fff',fontSize:10,fontWeight:300}}>(Buy Once Continue Earning)  </div>
                                        </div>
                                      
                                      </div>
                                        
                                      




                                  </div> 



                



                </div>


                

                <div   style={{backgroundColor:'#f2f2f2',borderTopLeftRadius: 30,borderTopRightRadius: 30,paddingLeft:15,paddingRight:10,paddingBottom:50}}>
                        
                {
                  /////////////////  CIRCLE SECTION BOXES   333
                }                          
                                      
                                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',width:'100%',marginLeft:'0%',marginTop:40,marginBottom:30,paddingTop:50 }}>
                  
                                                <div style={{width:'20%',}}>  
                                                      <div  onClick={() => setBonusMiningMsg(true)}>
                                                            <div  className="borderstyle" style={ {backgroundColor:'#fff',borderRadius:800,alignItems:'center',padding:15}}>
                                                              <div className="AutoBigerFont" style={{color:MainColor,fontWeight:'bold'}}>{QuestDone()}% </div> 
                                                            </div>                                  
                                                      </div>
                    
                  
                                                            <div className="huge_font_sub_text" style={{color:'#777777',textAlign:'center',fontSize:9}}>Profit Sharing  </div>
                                                            <div className="huge_font_sub_text" style={{color:'#777777',textAlign:'center'}}> Setout</div>

                                                </div>
                  
                                                <div onClick={() => setInviteLinkModal(true)} style={{width:'20%',marginLeft:'5%'}} >   
                                                      
                                                            <div  className="borderstyle" style={ {backgroundColor:'#fff',borderRadius:800,alignItems:'center',padding:15}}>
                                                            <div className="AutoBigerFont"  style={{color:MainColor,fontWeight:'bold'}}>{loginData.level_1} </div> 
                                                            </div>                                     
                                                            <div  className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}>Invite Link</div>
                                                            <div className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}> Trade Code</div>
                                                            
                                                </div>
                                    
                                                <div  onClick={() => navigate('/buy-airtime-data')}  style={{width:'20%',marginLeft:'5%'}}>   
                  
                                                            <div    className="borderstyle" style={ {backgroundColor:'#fff',borderRadius:800,alignItems:'center',padding:15}} >
                                                            <FaRegMoneyBillAlt   className="AutoBigerFont"  style={{color: MainColor,textAlign: 'center', }} /> 
                                                            </div>
                                                            <div className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}>Buy Airtime </div>       
                                                            <div className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}>& Data</div>       
                                                            
                                                </div>
                  
                  
                                                <div  onClick={() => navigate('/InstantLoan')} style={{width:'20%',marginLeft:'5%'}}>   
                                                      
                                                            <div   className="borderstyle" style={ {backgroundColor:'#fff',borderRadius:800,alignItems:'center',padding:15}}>
                                                            <FaRegMoneyBillAlt  className="AutoBigerFont"  style={{color: MainColor,textAlign: 'center', }}      />
                                                            </div>                                     
                                                            <div className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}>Get Instant</div>
                                                            <div className="huge_font_sub_text" style={{ color:'#777777', textAlign:'center'}}>Loan</div>
                                                </div>
                                    
                  
                  
                  
                                                
                                        </div>
                {
                /////////////////  SELL SHARES  SECTION 444  navigate('/my-shares')}
                }                     

                                        <div>

                                              <div onClick={() => navigate('/my-shares')}>

                                                    <div style={{alignItems: 'flex-end',marginTop:10}}>
                                                    <div style={{fontSize:15,color:'#777777',fontWeight:'bold',textAlign:'right' }}>My Shares(SH)</div>
                                                    </div>

                                                    <div  className="borderstyleRadius" >

                                                    <div  style={{width: '30%',alignItems:'center',paddingTop:20}}>
                                                    <div>
                                                    <img src="images/gcoin_img.png" className="CoinWallet"   />
                                                    </div>

                                                    </div>


                                                    <div  style={{width: '60%',textAlign:'left'}}>
                                                    <div style={{fontWeight: 'bold',color:TextColor2,marginBottom: 5,fontSize:14}}><span className="AutoBigerFont">{loginData.golden_coin_earn}SH   </span> <span className="huge_font_sub_text" style={{fontWeight: 'bold',color:TextColor2,marginBottom: 5,}}>( Shares(SH) Wallet)</span>  </div>
                                                    <div className="huge_font_sub_text" style={{fontWeight: 'bold',color:TextColor2,marginBottom: 5,}}> {NumberFomat(loginData.golden_coin_earn *loginData.golden_coin_value)} <span style={{fontWeight: 'bold',color:TextColor2,marginBottom: 5}}>Shares(SH) Value</span></div>
                                                    <div className="huge_font_sub_text">Click here to view / sell Shares(SH)</div>     
                                                    </div>



                                                    <div style={{width:'10%',paddingTop:20}}>
                                                    <BsChevronRight    style={{color: TextColor2,fontSize:16}} />
                                                    </div>


                                                    </div>
                                              </div>  

                                        </div>

                                        
                                      <div style={{marginTop:10}} onClick={() => navigate('/buy-airtime-data')}>
                                      <img src="https://rsbdigitalshares.com.ng/advert_banner/banner1.png" className="ads_banner"   />
                                      </div>

{
/////////////////  BOX GAME  SECTION 555
}                             
                                            
                                      <div>

                                    {////      user shares     >=  shares minimum
                                    loginData.level3_row_paid  >= loginData.shares_min?

                                    <div>

                                                      {
                                                      /////////////////////////////////////// ACCOUNT LOAN CHECKED
                                                      loginData.cashout_status == 'off_loan'?


                                                      <div> 

                                                            <div style={{alignItems: 'flex-end',marginTop:20}}>
                                                            <div style={{fontSize:14,color:'#777777',fontWeight:'bold',textAlign:'right' }}>Shares(SH) Profit Sharing Setout </div>
                                                            </div>


 
                                                                        <div  onClick={()=>biMonthlyCashout()} >
                                                                        
                                                                              <div  className="borderstyleRadius"  style={{flexDirection:'row',width: '100%'}}>

                                                                              <div  style={{width: '20%',paddingTop:20}}>
                                                                              <div>
                                                                              <FaUnlock    size={40} color={MainColor}      />
                                                                              </div>

                                                                              </div>


                                                                              <div  style={{width: '70%',textAlign:'left'}}>
                                                                              <div className="AutoBigerFont"  style={{color:TextColor2,}}>{monthlyBonus1Cal()}</div> 
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,fontWeight:'bold'}}>Shares(SH) Profit Sharing  </div>
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Push profit sharing to your credit card </div>       
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Current Cashout Date: {loginData.bimothly1_pay_date} </div>       
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Next Cashout Date: {loginData.next_cashout_date} </div>       
                                                                              </div>


                                                                              <div style={{width:'10%',paddingTop:20}}>
                                                                              <BsChevronRight    style={{color: TextColor2,fontSize:20}} />
                                                                              </div>


                                                                              </div>


                                                                        </div>


                                                         
                                                      </div>   
                                                            
                                                      :


                                                      <div>

                                                            <div style={{alignItems: 'flex-end',marginTop:20}}>
                                                            <div style={{ fontSize:14,color:'red',fontWeight:'bold',textAlign:'right' }}>Profit Sharing on hold for loan repayment   </div>
                                                            </div>
                                                      
                                                         
                                                                        <div>
                                                                        
                                                                              <div  className="borderstyleRadius"  style={{flexDirection:'row',width: '100%',backgroundColor:'#eee'}}>

                                                                              <div  style={{width: '20%',paddingTop:20}}>
                                                                              <div>
                                                                              <FaLock     size={40} color={'red'}      />
                                                                              </div>

                                                                              </div>


                                                                              <div  style={{width: '70%',textAlign:'left'}}>
                                                                              <div className="AutoBigerFont" style={{color:TextColor2,}}>{monthlyBonus1Cal()}</div> 
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,fontWeight:'bold'}}>Shares(SH) Profit Sharing  </div>
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>You are on loan repayment </div>       
                                                                              <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Next loan repayment date: {loginData.bimothly1_pay_date} </div>       
                                                                              </div>


                                                                              <div style={{width:'10%',padding: 10}}>
                                                                              <BsChevronRight    style={{color: TextColor2,fontSize:20}} />
                                                                              </div>


                                                                              </div>


                                                                        </div>


                                                

                                                      </div>  
                                                      /////////////////////////////////////// ACCOUNT LOAN CHECKED
                                                      } 




                                    </div>
                                    :

                                    <div> 

                  
                                          <div style={{alignItems: 'flex-end',marginTop:20}}>
                                          <div style={{fontSize:13,color:'#777777',fontWeight:'bold',textAlign:'right' }}>Shares(SH) Profit Sharing Not Setout   </div>
                                          </div>


                                          <div    onClick={() => setBonusMiningMsg(true)} >
                                          <div  className="borderstyleRadius"   style={{flexDirection:'row',width: '100%',backgroundColor:'#eee'}}>

                                          <div  style={{width: '30%',paddingTop:20}}>
                                          <div>
                                          <FaLock     size={40} color={MainColor}      />
                                          </div>

                                          </div>


                                          <div  style={{width: '65%',textAlign:'left'}}>
                                          <div className="AutoBigerFont" style={{color:TextColor2,fontSize:20}}>₦****</div> 
                                          <div className="huge_font_sub_text" style={{color:TextColor2,fontWeight:'bold'}}>Shares(SH) Profit Sharing   </div>
                                          <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Click here to setout your profit sharing</div>       
                                          <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Matured profit sharing date: {'00:00:00'} </div>       
                                          </div>


                                          <div style={{width:'10%',paddingTop: 20}}>
                                          <BsChevronRight    style={{color: TextColor2,fontSize:20}} />
                                          </div>


                                          </div>


                                          </div>
                
                                    </div>

                                    }

                                      </div>


                                      <div style={{marginTop:10}}  onClick={() => setRamadanMsg(true)}>
                                      <img src="https://rsbdigitalshares.com.ng/advert_banner/banner4.jpeg" className="ads_banner"   />
                                      </div>


{
/////////////////   BOX GAME  SECTION    666 setBonusMiningMsg
/////////////////   MODAL START  SECTION   
/////////////////   MODAL START  SECTION   
/////////////////   MODAL START  SECTION   
/////////////////   MODAL START  SECTION   
/////////////////   MODAL START  SECTION   


    
}                             
                                                                              
                                      <div>
                                            

                                            <div style={{alignItems: 'flex-end',marginTop:20}}>
                                            <div style={{fontSize:15,color:'#777777',fontWeight:'bold',textAlign:'right' }}>Shares(SH) Trading  </div>
                                            </div>



                                            <div style={{display:'flex',flexDirection:'row',width: '100%'}}>

                                                  <div style={{width: '47%',}}>
                                                  <div >
                                                        <div className="borderstyleRadius" onClick={() => navigate('/buy-shares')}>

                                                              

                                                                  <div style={{width: '100%',textAlign:'left'}}>
                                                                    <div> <FaCoins     size={35} color={MainColor}      /> </div> 
                                                                    <div className="huge_font_sub_text" style={{color:TextColor2,fontWeight:'bold'}}>Buy Shares(SH)  </div>
                                                                    <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5,}}>Increase your  profit sharing</div>  
                                                                    </div>


                                                                    


                                                        </div>
                                                  </div>
                                                  </div>               

                                                  <div style={{width: '47%',marginLeft:'5%'}}>
                                                  <div onClick={() => navigate('/sell-shares')}>
                                                        <div   className="borderstyleRadius">

                                                              

                                                                    <div style={{width: '100%',textAlign:'left'}}>
                                                                    <div> <FaRegMoneyBillAlt    size={35} color={MainColor}      /> </div>  
                                                                    <div className="huge_font_sub_text" style={{color:TextColor2,fontWeight:'bold'}}>Sell Shares(SH)</div>
                                                                    <div className="huge_font_sub_text" style={{color:TextColor2,marginBottom: 5}}>Make money selling your Shares(SH) </div>
                                                              </div>


                                                                    


                                                        </div>
                                                  </div>
                                                  </div>

                                            </div>
                                      </div>  

                
                

 


                </div>


                        {/// MODAL DATA AIRTEL PURCHASE
                              loanMenuSwitch?

                              <Modal   show={true}  >
                              <GetInstantLoan   
                              CloseSetLoanMenuSwitch={setLoanMenuSwitch}
                              />
                              </Modal>
                              :  
                              null

                        }



                        {/// SHARES(SH) TRADING MODAL 252525
                          tradeCoinModal?


                          <Modal show={true}>
                          
                        
  
                          <div onClick={()=> setTradeCoinModal(false)}> 
                          <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> 
                          </div>
  
                          <div style={{color:'black'}}>
  
  
  
                                <div>
                                <div style={{alignItems:'center' }}>
                                <img src='images/gcoin_img.png'  style={{width: 80, height: 80, }} />
                                </div>
                                
  
                          
                                <div style={{textAlign:'center',fontSize:20,fontWeight:'bold'}}>SHARES(SH) TRADING</div> 
  
                                <div style={{fontSize:30,color:MainColor,textAlign:'center'}}>{loginData.golden_coin_earn}SH 
                                <span style={{fontSize:15,color:'#777777',textAlign:'center'}}> {NumberFomat(loginData.golden_coin_earn *loginData.golden_coin_value)} </span>
                                </div>
              
                                
                                <div style={{marginTop:16,textAlign:'left',paddingLeft:15,paddingRight:15}}>

                                <div>
                                <div className="text" >Enter Coin Unit to Trade <span style={{fontSize:11,fontWeight:'bold'}}> (1 Unit coin = 1GC)</span></div>
                                <input 
                            
                                onChange={e=> coinUnitCalc(e.target.value)}
                                className="input"
                                style={{paddingLeft:20}}
                                />
                                </div>
                                      
                    
                    
                                <div>
                                <div className="text">Coin Value</div>
                                <div   style={{color:'black'}} > </div>
                                <input  
                                className="input"  
                                readOnly
                                value={data.coinValue}
                                style={{paddingLeft:20}} 
                                /> SH
                                </div>
                    
                                <div>
                                <div className="text">Coin Amount   </div>
                                
                                <input  
                                className="input"  
                                readOnly
                                value={NumberFomat(data.coinAmt)}
                                style={{paddingLeft:20}} 
                                /> 

                                </div>
                    
                                <div>
                                <div className="text">Receiver Trading Code</div>
                                <input  
                                className="input" 
                                onChange={e=> setData({ ...data,  receiverCode:e.target.value})}
                                type="numeric"
                                style={{paddingLeft:20}}
                                /> 
                                <div style={{textAlign:'center',fontSize:14,color:'red'}}>{message}</div> 
                                </div>
  
   
  
                                
              
  
                                      {//
                                            data.coinUnit <=  loginData.golden_coin_earn?
                                                  data.coinUnit === '1' && data.receiverCode.length != 0 ?
  
                                                        <div  onClick={() => coinTradeRequesToReceiver()} >
                                                        <div className="button">
                                                        <div style={{textAlign:'center',color:'white',fontSize:16,}}> Send Trade Request to Receiver</div>
                                                        </div>
                                                        </div>
  
                                                        :
  
                                                        <div  className="button" style={{backgroundColor:'green'}}>
                                                        <div style={{textAlign:'center',color:'white',fontSize:16,}}> Enter 1 Unit of Coin</div>
                                                        </div>
  
                                      :
                                      
                                                        <div  className="button" style={{backgroundColor:'red'}}>
                                                        <div style={{textAlign:'center',color:'white',fontSize:16, }}> Insufficient SH</div>
                                                        </div>
  
  
  
                                      }
                          
              
                                <div style={{textAlign:'center',fontSize:14}}>
                                Note: Your shares is your asset. shares is  used to calculate your profit sharing dividend
                                </div>
                                
                                </div>
                                </div>
                          </div>
              
                          
                          </Modal>
                          :  
                          null
              
                          }

                          



                  {////  MY SHARES AUTHENTICITY ///////////// 
                    goldenCoinMenuSwitch?   

                    <Modal  show={true}  >
                    <div style={{ paddingBottom:100}}> 

                    <div     onClick={() => setGoldenCoinMenuSwitch(false)}
                    style={{padding: 10,borderRadius: 5,marginTop: 50,marginLeft:'40%'}}  >
                   <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> 
                    </div>

                    <div style={{borderTopLeftRadius:5,borderTopRightRadius:5,marginTop:10,width:'50%',marginLeft:'25%',marginTop:'10%'}}>
                                 

                      


                        <div  onClick={() => navigate('/my-golden-Coin')} style={{backgroundColor: MainColor, padding: 20,borderRadius: 5,marginTop: 20}}  >
                          <div style={{fontSize: 15,color: 'white',textAlign:'center'}}> 
                          CHECK MY SHARES(SH)
                          </div>  
                          <div style={{textAlign:'center'}}> 
                            <div>
                            <img src="images/gcoin.png"  style={{width: 80, height: 80, }} />
                            </div> 
                            </div> 
                        </div>

                          <div   onClick={() => SellGoldenCoinHandler()}style={{backgroundColor: '#e89ec6', padding: 20,borderRadius: 5,marginTop: 20}}  >
                          
                            <div style={{fontSize: 15,color: MainColor,textAlign:'center'}}>
                            SELL SHARES
                            </div> 

                            <div style={{textAlign:'center'}}> 
                              <div>
                              <img src="images/1gcoin.png"  style={{width: 80, height: 80, }} />
                              </div> 
                            </div>
                        </div>
      

      

                    </div>

                       


                    </div>
                   
                     </Modal>
                     :
                     null
                 }





                         {/// SHARES NOT SETOUT MESSAGE
                           ramadanMsg ?


                          <Modal  show={true}  > 
                                        <div onClick={()=> setRamadanMsg(false)}  > <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> </div>
                                      <div className="ModalView1">
                                   
                           
                                      <div>
                                            
                                      <Link style={{ fontSize: 12,color: 'white', }}   to="/https://t.me/rsbdigitalshares"  className="button">
                                      Click here to goto TelegramLink
                                      </Link>


                                      <div style={{marginTop:10}} onClick={() => navigate('/buy-airtime-data')}>
                                      <img src="https://rsbdigitalshares.com.ng/advert_banner/banner3.jpeg" style={{ width:'100%'}}   />
                                      </div>

                                      <Form style={{textAlign:"left"}}>
                              
                                       
                                           <br /><br />
                                           <Form.Group className="mb-3" controlId="formBasicPassword"> 
                                              <div>Enter Ramadan Voucher </div>
                                            <Form.Control type="text"     onChange={e=>  setVoucherCode(e.target.value)}      />
                                           </Form.Group>
                                  
                                    
                                            <div     onClick={()=> SubmitVoucherCode()}    className="button" type="submit">
                                            Confirm Voucher
                                            </div> 
                               
                                       </Form>


 

                                      </div>
                                
                                                   
  
                                      
   
                                </div>
                          </Modal>
                          :  
                          null
  
                          }



                          {/// SHARES NOT SETOUT MESSAGE
                          bonusMiningMsg ?


                          <Modal  show={true}  onClick={()=> setBonusMiningMsg(false)} > 
                                        <div> <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> </div>
                                      <div className="ModalView1">
                                   
                          
                                      <FaHourglassHalf style={{textAlign:'center'}}    size={30}  color={MainColor} /> 
                                      
                                      <div style={{ fontSize: 20,color: MainColor,divAlign:'center'}}>SHARES(SH) PROFIT SHARING </div>
                                      <div style={{ fontSize: 20,color: MainColor,marginBottom: 15,textAlign:'center'}}>SETOUT</div>
  
  
  
                                      <div>
                                            <div style={{ fontSize: 12,color: 'black',textAlign:'left',marginBottom:10,fontWeight:'bold'}}>
                                           OPTIONS TO SETOUT YOUR  PROFIT SHARING
                                             </div>
                                                <div style={{ fontSize: 11,textAlign:'left'}}>
                                                * 1 Shares(SH) = {NumberFomat(loginData.golden_coin_value)}<br />
                                                * 1 Invite gets a reward of 1shares(SH) 
                                                </div> 

                                            <div style={{ fontSize: 14,color: MainColor,textAlign:'left',marginBottom:3,fontWeight:'bold'}}>1st Option    </div>
                                            <div style={{ fontSize: 12,color: 'black',textAlign:'left',marginBottom:10}}>                                           
                                             
                                            Buy {loginData.shares_min} Shares(SH) for {NumberFomat(loginData.golden_coin_value*loginData.shares_min)} 
                                            </div>
                                            <div style={{ fontSize: 14,color: MainColor,textAlign:'left',marginBottom:3,fontWeight:'bold'}}>2nd Option  </div>

                                            <div style={{ fontSize: 12,color: 'black',textAlign:'left',marginBottom:10}}>
                                              
                                             Invite minimum of {loginData.shares_min}persons get rewarded with {loginData.shares_min}shares worth ({NumberFomat(loginData.golden_coin_value*loginData.shares_min)})<br />                                            
                                            </div>
        
                                            <div style={{ fontSize: 12,color: 'black',textAlign:'left',marginBottom:10,fontWeight:'bold'}}>
                                            You need minimum of {loginData.shares_min} Shares to activate your profit sharing
                                            </div>



                                            <div style={{ fontSize: 12,color: 'white', }}   onClick={()=> navigate('/fund-credit-card')}  className="button">
                                            Click here to fund credit card
                                            </div>
                                      </div>
                                
                                                   
  
                                      
   
                                </div>
                          </Modal>
                          :  
                          null
  
                          }



                          {/// SHARES NOT SETOUT MESSAGE
                           inviteLinkModal ?


                          <Modal  show={true}  onClick={()=> setInviteLinkModal(false)} > 
                                        <div> <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> </div>
                                      <div className="ModalView1">
                                    
                                      
                                      <div style={{ fontSize: 20,color: MainColor,divAlign:'center'}}>My Invite Link </div> 
  
                                      <div style={{ fontSize: 12,color: 'black',textAlign:'center',marginBottom:10,fontWeight:'bold'}}>
                                      Copy your invite link to clipboard
                                      </div>
  
                                      <div>
                                          
                                      <div onClick={()=> copyToClipboard(1)}  >  
                                      <CopyToClipboard   text={'https://rsbdigitalshares.com.ng/account-setup?trade_code='+loginData.level_1}> 
                                      <div style={{color:MainColor,fontSize:12}}>
                                      <FaRegCopy    size={20} style={{ color: MainColor,textAlign:'center'  }}  /> Copy Invite Link</div>  
                                      </CopyToClipboard>  
                                      </div>

                                    <div style={{ fontSize: 12,color: 'black',textAlign:'left',marginBottom:10}}>                                           
                                    https://rsbdigitalshares.com.ng/account-setup?trade_code={loginData.level_1} 
                                    </div>
                                             
 
                                    <div style={{ fontSize: 20,color: MainColor,divAlign:'center'}}>My Trading Code</div>  
                                    <div style={{ fontSize: 12,color: 'black',textAlign:'center',marginBottom:10,fontWeight:'bold'}}>
                                    Copy your trading code to clipboard
                                    </div>
         
             
                                    <div onClick={()=> copyToClipboard(2)}  >  
                                    <CopyToClipboard   text={loginData.level_1}> 
                                    <div style={{color:MainColor,fontSize:12}}>
                                    <FaRegCopy    size={20} style={{ color: MainColor,textAlign:'center'  }}  /> Copy Trading Code</div>  
                                    </CopyToClipboard>  
                                    </div>
                                                       
                                             
                                    <div style={{ fontSize: 16,color: 'black',textAlign:'center',marginBottom:10}}>                                           
                                     {loginData.level_1} 
                                    </div>



                                      </div>
                                
                                                   
  
                                      
   
                                </div>
                          </Modal>
                          :  
                          null
  
                          }


                        {/// Shares(SH) TRADING ALERT REQUEST
                        loginData.weekly_point == '1'?

                        <Modal    show={true}  >
                              
                        <div>
                        
                  

                              

                                    <div>{message}</div>


                              

                                    <div>
                                    <div style={{alignItems:'center',marginBottom:70}}>  
                                    <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>TRADE ALERT</div>
                                    <div style={{fontWeight:'bold',fontSize:18,color:MainColor}}>SHARES(SH) TRADE REQUEST</div>

                                    <div style={{alignItems:'center',marginTop:50}}>
                                    <img src='images/1gcoin.png'  style={{width: 80, height: 80, }} />
                                    <div style={{fontWeight:'bold',fontSize:15,color:'black',textDecorationLine:'underline'}}>1 Shares(SH)  <div style={{fontSize:15,}}>({NumberFomat(loginData.coin_selling_amt)})</div></div>
                                    
                                    {
                                          loginData.coin_strength == 'good'? 

                                                <div style={{color:'green'}}>
                                                      <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}> Shares(SH) status   </div>
                                                      [ Healthy ] 
                                                      <BsCheck2Circle color={'green'}    size={20}       />
                                                </div> 

                                          : loginData.coin_strength == 'bad'?
                                                <div style={{color:MainColor}}>
                                                [ Unhealthy ]   
                                                <FaRegTrashAlt  color={'red'}    size={20}       /> 
                                                </div> 
                                          : loginData.coin_strength == 'scam'?
                                                <div style={{color:MainColor}}>
                                                [ Bad ]   
                                                <FaRegTrashAlt  color={'red'}    size={20}       /> 
                                                </div> 
                                          :null
                                          }
                                    </div>


                                    <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) Purchased date
                                    <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [ {loginData.reg_date} ] </div>
                                    </div> 

                                    <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) no
                                    <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}> [{loginData.coin_selling_id}]  </div> 
                                    </div>  

                                    </div>           

                                    <div style={{fontSize:14,color:TextColor2,marginBottom:5}}>
                                    Hi, {loginData.fullname} you have a "Shares(SH)" trade request from 
                                    <span style={{color:'red',fontSize:18}}> {loginData.coin_trade_sender} </span>
                                          <div style={{textAlign:'center'}}> PLEASE WAIT... </div>
                                    </div>


                              

                                    </div>

                                    



                        

                        </div>
                        </Modal>
                        :
                        null
      
                  

                        }



      

                  {///  (MESSAGE NOTIFICATIONS)
                    messageSwitch?


                    <Modal  show={true}  style={{width:'100%'}} > 

                    <div   onClick={() => setMessageSwitch(false)}   >
                  <AiFillCloseCircle   style={{marginLeft:'50%',paddingTop:2}} size={25}  color={'black'} /> 
                    </div>

                    <div  style={{textAlign:'center'}} > 
                  <FaEnvelopeOpenText className="blink_me" color={'black'}  />
                    </div>
                    


                      
                          
                        {message == ''?

                     
                                <div    style={{padding:5}}>
                                
                                <div  style={{fontSize: 12,color:'black'}} >{loginData.public_msg}</div>  
                                <div  style={{fontSize: 12,color:MainColor,marginTop:10}} >{loginData.private_msg}</div>  

                          
                                </div> 
                          :
                                <div>
                                       
                                <div  style={{fontSize: 14,color:'black',padding:5}} >{message}</div> 

                                </div>  
                          
                           }  
                          



                 

                          
                          
                    
              
                    </Modal>
                    :  
                    null

                    }



                              

            </div>


  
        );
    
    }

} 
};

export default Dashboard; 
 

 /*
email:projectofficer@schematicspenslimited.com
password:t7gDxRHdch;m


email: projectofficer@schematicspenslimited.com
password:t7gDxRHdch;m
  

      <AdSense.Google
      client='ca-pub-1125467972774097'
      slot='7806394673'
      style={{ display: 'block' }}
      format='auto'
      responsive='true'
      data-adtest="on"
      layoutKey='-gw-1+2a-9x+5c'
/>

*/