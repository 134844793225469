import React from 'react'; 
import '../App.css';   


 
export default function Loading(props) {
  return (
  
    <div className="Backdrop "   onClick={props.Show} >
      <center>
              <div className="modalLoading">
                <img src="images/loading.gif"  style={{height: '50px'}}   />
                <br  />  
                <small style={{color: '#fff',fontSize:13}}>Please Wait..</small>
            </div>
      </center>


    </div>
    

  );
}
 