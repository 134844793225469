import React, { useState, useEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom' 
import { MdTipsAndUpdates } from "react-icons/md";
import { GiPadlock, GiCheckMark  } from "react-icons/gi";   
import { FaTasks,FaFacebook,FaCoins,FaUserTie,FaUsers,FaHourglassHalf } from "react-icons/fa";  
import { GiMoneyStack, GiPayMoney ,GiReceiveMoney,GiArcheryTarget} from "react-icons/gi";   
import '../Dashboard.css'; 
import '../../../App.css'; 
import Modal from "../../UI/Modal/Modal";  
import {Naira, BasePageAction, BodyColor, PickTopSeller} from '../../../BaseUrl';
import {FloatingLabel,Card, Table, Form,Button} from 'react-bootstrap';
import { AuthContext } from '../../context';


 

const CashoutHistory = (props) => {
  const [message, setMessage] = useState('');
  const [authUser, setAuthUser] = useState('');
  const [payHistory, setPayHistory] = useState([]);
  const [effectrunNow, seteffectrunNow] = useState(true);
  const [loginToken, setLoginToken] = useState([]);
  const [weeklyReward, setWeeklyReward] = useState({});
  const [weeklyTopSeller, setWeeklyTopSeller] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getAccountUpdate ,signOut, reloadUserData} = React.useContext(AuthContext);
  const navigate = useNavigate();    
   const [gender, setGender] = useState('');
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [bankName, setBankName] = useState('');
   const [accountName, setAccountName] = useState('');
   const [accountNo, setAccountNo] = useState('');
    
     
  
 

  
   const AllDataFetch=async() => {
  
         const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value); 
    setLoginToken(data);



    const userData =  localStorage.getItem('authUser');
    setAuthUser(userData);
   
  }
   
  



 
    useEffect(() => {
    
      AllDataFetch();
    }, []);

 
 
    

useEffect(() => {
  let runNow = true
 
 setShow(true);
   
    setTimeout(async() => {
      
   const dateVerifyData =  localStorage.getItem('authUser');   
      const data = {  
        user:dateVerifyData
     }
     var headers = {
       'Accept': 'application/json',
       'Content-Type': 'application/json'
       }
     
       fetch(BasePageAction+'cashoutHistory',
       {
       method:'POST',
       header:headers,
       body: JSON.stringify(data)
       })
       .then((response) => response.json())
       .then((response) => {
   
        if(runNow){  
          if(response[0].success == '0'){
            setMessage(response[0].feedback); 
             
          }else{
            setShow(false);
            setPayHistory(response);
          }
    
         } 
 }) 


}, 5000);




// //FETCH GAME DATA HISTORY
// const FetchGameDataHistory = () => {

//   setActivateLoader(true);  
//   setIsLoading2(true);  



//           var headers = {
//                       'Accept': 'application/json',
//                       'Content-Type': 'application/json'
//                   }
    
//          fetch(BaseAction+"FetchGameDataHistory&user="+loginData.username,
//               {
//               method:'GET',
//               header:headers,
      
//               })
//               .then((res) => res.json())
//               .then((response) => {

                     

//                     if(response[0].success == "0")
//                     {
                    


                          
//                           setMessage(response[0].feedback); 
//                           setActivateLoader(false);  
//                           setIsLoading2(false);  
//                           setMessageSwitch(true) 
//                     }
//                     else 
//                     {
//                     setMessageSwitch(false) 
                      
                      
//                       setGameHistoryData(response); 
//                       setActivateLoader(false);  
//                       setIsLoading2(false);  

//                       setGameHistoryModal(true);  
//                     }



//               })  
//               .catch(error => {
              
//                   setActivateLoader(true);  
//                   setIsLoading2(false); 
//               });
      
          


  
// }

 return () => {
  runNow = false;
}; 

    
},[]);



  const NumberFomat = (val) =>{

     
    var value =  val* 1; 
    var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return   Naira+result;  
                                         


}
  const showHandler =()=>{

    setShow(false);
    
 }

 const noDataFech =()=>{
  navigate('/')
      
   }
 
  if(show){ 
    return (
      
            
               
      <div>
      {
      
        message == ''?
          <center>
          
            <Modal show={show}  >    
            <img src="images/loading.gif"  style={{height: '100px'}}   /> 
            <br  /> <small style={{color: 'white'}}>Please Wait..</small>
            </Modal> 
          </center>
          :
  
         <center>
          
         <Modal clicked={noDataFech} show={show} > 
                      
         <div  className="rackdropDiv" style={{color: 'white'}}  > 
         <MdTipsAndUpdates className="blink_me" style={{fontSize: 60,color: '#0c65e8'}} /><br/>
           {message} 
         </div>
  
          </Modal> 
  
        </center>
  
    
      }
  
  </div>
              
   
  
       );
  }
  

 

  return (

        <div  style={{textAlign:"left",padding: 20,height:'100vh'}} >

 
         

                <div  className="taskContainer ">

                  <h5 style={{marginBottom: '14px'}}>   <br />  </h5>

                
                    
                          <div  className="container">
                            
                              <Card.Title  style={{marginBottom: 20,textAlign: 'start',textDecoration: 'underline'}}>
                              
                              <div    > <FaTasks  /> CASHOUT HISTORY </div>
                              </Card.Title> 
                              

                                <div  >
                                    
                                    
                                {
                                  payHistory.map((post) => (
                                        <div key={post.id} style={{backgroundColor: '#f2f2f2',width: '100%', }}>


                                        <div  className="grantStages">  
                                        <div className="tabBlock1" style={{fontSize: 15,color:'black',}}>
                        
                                           <div className="indicatorLeft Loan-Status-bar1" style={{textAlign:'left',fontSize:20 }}>  
                                                <GiMoneyStack  style={{color:BodyColor,fontSize:50,}}   />  {NumberFomat(post.amt_paid)} 
                                                
                                                </div>  

                                      <div className="font_b">
                                          <p>Amount Cashout:<b> {NumberFomat(post.amt_paid)}   </b>    </p>
                                          <p>Transaction Charges <b>   {NumberFomat(post.admin_fee)}</b></p> 
                                        
                                          <p>Bank Name <b> {post.bank_name}</b></p>
                                          <p>Account Name  <b> {post.acct_name}</b></p>
                                          <p>Account Number <b> {post.acct_no}</b></p>
                                          <p>Transaction Date <b> {post.date}</b></p> 
                                          <p>Payment Reference no:<b> { post.cashout_id}  </b>    </p> 
                                          {post.pay_status == 'Processing'?

                                          <button     className="btntab--active font_b" style={{width: '100%',height:40,backgroundColor:'red',color:'white' }}>
                                          Payment Status: {post.pay_status} <FaHourglassHalf  />
                                          </button>  

                                          :
                                          <button     className="btntab--active font_b" style={{width: '100%',height:40,backgroundColor:'green',color:'white'  }}>
                                          Payment Status: {post.pay_status}  <GiCheckMark  />
                                          </button>  

                                          }
                                                                              
                                      </div>                                       
                                           
                        
                                        </div>
                                  </div> 
                                        </div>

                                    ))
                                  }                
                                  
                                </div>
                              
                            
                            <br />
                          </div>
                           

                </div>


          </div>
    )

 
   
};

export default CashoutHistory; 
 
// <Table responsive>
                                    
// <thread  >

//         <tr style={{width: '20%',}}>
//               <th style={{width: '5%',fontWeight:400,textAlign:"left"}}>Loan Amount </th>
//               <th style={{width: '5%',fontWeight:400,textAlign:"left"}}>Loan Level </th>
//               <th style={{width: '5%',fontWeight:400,textAlign:"left"}}>Loan Status</th> 
//               <th style={{width: '5%',fontWeight:400,textAlign:"left"}}>Repayment</th>
//               <th style={{width: '5%',fontWeight:400,textAlign:"left"}}>Loan Date</th> 
//           </tr>                    
// </thread>

// <tbody>
//   {payHistory == ''? ( 
//         <tr>
//           <td colSpan={5}>
//          Data Loading..
//           </td>
//         </tr>
//   ):(
//   <div>
  
//       {payHistory.map((post) => (
//           <tr key={post.id} style={{backgroundColor: '#f2f2f2',width: '20%', }}>
//               <td style={{width: '5%'}}>{NumberFomat(post.loan_amt)}</td>
//               <td style={{width: '5%'}}>{post.loan_level}</td>
//               <td style={{width: '5%'}}>{post.loan_status}</td>
//               <td style={{width: '5%'}}>{post.loan_repayment}% Completed</td>
//               <td style={{width: '5%'}}>{post.date}</td> 
//           </tr>

//       ))}
                        
//   </div>

//   )}


// </tbody>
// </Table>