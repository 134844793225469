
import React, { useState, useEffect } from "react";
import { Link, Navigate } from 'react-router-dom';
import {FloatingLabel,Card, Table, Form,Button,Modal} from 'react-bootstrap';
import { FaUserCheck,FaUserLock,FaEnvelopeOpenText,FaLock,FaRegEyeSlash,FaRegEye} from "react-icons/fa";
import { MdTipsAndUpdates } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AuthContext }  from '../../context'; 
import { BasePageAction,BaseAction,MainColor} from '../../../BaseUrl';
import md5                from 'md5-hash';
import '../../Login/Login.css';
import   Loading          from "../../Loading";
import   Loading2         from "../../Loading2";

function ForgotPassword() { 
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);  
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [messageSwitch, setMessageSwitch] = useState('');
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState('');
  const [navigate, setNavigate] = useState(false);


  const { signIn } = React.useContext(AuthContext);
  const[data, setData] = useState({ 
    otpCode: '',
    message: '',
    password2: '',
    password: '',  
    username: '',
    check_inputChange: false,
    secureTextEntry: true,
    passwordText: 'password',
  })
   


  // function refreshPage() {
  //   setTimeout(()=>{
  //       window.location.reload(false);
  //        setShow(true);
  //   }, 5000);
     
  // }


  const SendOTPCode = () => {

    setActivateLoader(true);  
    setIsLoading2(true); 
    
    var newUse = Number(data.username) + Number(191);
    var newPass = newUse.toString();
    var authUserToken= md5(newPass);

    
    if( data.username >= 1 )
    {
                var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                }
                fetch(BaseAction+"sendRecoveryOTPCode&passKey="+authUserToken,
                {
                method:'GET',
                header:headers
                })
                .then((response) => response.json())
                .then((response) =>{
                      
                setActivateLoader(false);  
                setIsLoading2(false); 
                      
                      if(response[0].success == 'ok'){
                        
                      setMessage(response[0].feedback); 
                      setMessageSwitch(true);
                      }else{
    
                      setMessage(response[0].feedback);
                      setMessageSwitch(true);

                      setData({  ...data,  username:''})
                      }
                      
                })
                .catch(error => {
                  setActivateLoader(true);  
                  setIsLoading2(false); 
                  });
  
    }
    else
    {
            setActivateLoader(false);  
            setIsLoading2(false); 



            setMessage('You need to enter your username to be able to send OTP');
            setMessageSwitch(true)
    }  
    
          
    }

  const accountPasswordUpdate = () => {
         
      setActivateLoader(true);  
      setIsLoading2(true); 
      
      var newUse = Number(data.username) + Number(191);
      var newPass = newUse.toString();
      var authUserToken= md5(newPass);
      
            const payloadData = {   
            userToken: authUserToken, 
            password : md5(data.password),
            password2: md5(data.password2),  
            otpCode  : data.otpCode,  
            };
      
     
                  if(data.password.length == 0 || data.password2.length == 0 || data.otpCode.length == 0 || data.username == 0)
                  {
                  setActivateLoader(false);  
                  setIsLoading2(false); 
      
      
                  setMessage('All form fields are required. Please enter valid username(phone). Thanks');
                  setMessageSwitch(true)
                  }
                  else
                  {
                   
                        if(data.password  === data.password2 )
                        {
            
                              var headers = {
                              'Accept': 'application/json',
                              'Content-Type': 'application/json'
                              }
                  
                              fetch(BaseAction+"AccountPasswordUpdate",
                              {
                              method:'POST',
                              header:headers,
                              body: JSON.stringify(payloadData)
                              })
                              .then((response) => response.json())
                              .then((response) => {
                  
                              setActivateLoader(false);  
                              setIsLoading2(false);  
                  
                  
                              if(response[0].success == 1){
                  
                              setMessage(response[0].feedback);
                              setMessageSwitch(true)  
                              setData({  ...data, 
                                password2:'',
                                password:'',
                                username:'',
                                otpCode:''
                              })
                  
                              }else{
                  
                              setMessage(response[0].feedback);
                              setMessageSwitch(true)
                              }
                  
                                }); 
                        }
                        else
                        {
                            setActivateLoader(false);  
                            setIsLoading2(false); 
                
                
                
                            setMessage('Your new password do not match');
                            setMessageSwitch(true)
                        }   
    
                  }
      
  
          
  }


  const showHandler =()=>{

    setShow(false);
    setMessage('');
    setPhone('');
 }
 
 const updateSecureTextEntry = (val) => {

  if(val == 'hide'){

    setData({  ...data, 
      secureTextEntry: false,
      passwordText:'text'
      });

  }else if(val == 'open'){

    setData({  ...data, 
      secureTextEntry: val,
      passwordText:'password'
      });

  }

  
  }

  const handleSubmit = e =>  {
  e.preventDefault();

  setShow(true);
  
            if(phone == '')
            {
              setMessage('Phone field is required');
            }
            else
            {

                const data = {   
                    user:    phone
                
                  };
                  var headers = {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                  }
            


                  fetch(BasePageAction+'AccountRecovery',
                  {
                  method:'POST',
                  header:headers,
                  body: JSON.stringify(data)
                  })
                  .then((response) => response.json())
                  .then((response) => {
                  
                        if(response[0].success == 'ok'){
                          
                            setMessage(response[0].feedback);
                        }
                        else   
                        {
                          
                          setMessage(response[0].feedback);
                        }
                      


                
                
                })
                .catch(error => {
                  setMessage('Oops! network error, please check your data and try again');
                });

          }
}

  if(navigate){
    return <Navigate  to='/'  />
  }



  if(activateLoader)
  { 
  
  
          if(isLoading2)
          {
      
      
            return (     
      
                  <div> 
                    <Loading  /> 
                  </div>
                );
      
      
          }
          else
          {
              return (         
                  <div> 
                      <Loading2   Show={showHandler} >
                          <small style={{color: 'black',fontSize:13}}>{message} </small>
                      </Loading2> 
                  </div>
              );
          }
  
    }
    else
    {

      const newLocal = 'green';
        return (
          <div className="App">
          <div className="App-header">

          <div className="ContainerS">  
      
        <img src="images/favicon.png"   className="imgLogoL"/> 
        <div className="TextHeader" style={{marginTop:20}} >RSB DIGITAL SHARES </div> 
        <hr  className='homeHr'/>
        <p>ACCOUNT RECOVERY  </p>


        <div  >
             
                                 
        <div >
        
        
 
  

        
              <div>
                  <div style={{marginTop:50,alignItems:'flex-start'}}>
                    <div style={{textAlign:'left'}}>
                    <FaUserCheck color={MainColor} size={20} /> Username (phone)
                    </div>                           
                  </div>

                  <div style={{width:'80%',textAlign:'left',}}>
                  <Form.Control   
                  onChange={e => setData({ ...data, username:e.target.value})}
                  value={data.username} 
                  type='text'
                 style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}} 

                  />                               
                  </div>



                  
                  <div style={{marginTop:10,alignItems:'flex-start'}}>
                    <div style={{textAlign:'left'}}>
                    <FaLock color={MainColor} size={20} /> Create new passsword
                    </div>                           
                  </div>


                  <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,marginTop:10,}}>



                  <div style={{width:'80%',textAlign:'left',}}>
                  <Form.Control   
                  onChange={e => setData({ ...data, password:e.target.value})}
                  value={data.password} 
                  type={data.passwordText}
                 style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}} 

                  />                               
                  </div>



                  </div>
              </div>


              <div style={{marginTop:10,}}>
                  <div style={{alignItems:'flex-start'}}>
                        <div style={{textAlign:'left'}}>
                        <FaLock color={MainColor} size={20} />Confirm new passsword 
                        </div>                           
                  </div>


                  <div style={{display:'flex',flexDirection:'row',width:'80%',height:50,}}>

                  

                      <div style={{width:'90%',textAlign:'left',}}>
                            <Form.Control   
                            onChange={e => setData({ ...data, password2:e.target.value})}
                            value={data.password2}   
                            type={data.passwordText}
                            style={{width: '100%',fontSize:15,color:'black',textAlign:'left'}}  

                            />                               
                      </div>

                      <Button   style={{width:'20%',textAlign:'center',height:35,marginLeft:5}}>
                            <div     >
                                  {data.secureTextEntry ?

                                  <FaRegEyeSlash  className="passwordHide" style={{color:'white',width: '100%',height:18}}                                            
                                  size={20}
                                  onClick={()=>updateSecureTextEntry('hide')} 
                                  /> 
                                  :
                                  <FaRegEye  className="passwordHide" style={{color:'white',width: '100%',height:18}}  
                                  size={20}
                                  onClick={()=>updateSecureTextEntry('open')} 
                                  /> 
                                  }
                            </div>   
                      </Button>

                  </div>
              </div>


              <div style={{marginTop:10,}}>

                <div style={{alignItems:'flex-start'}}>
                <div style={{textAlign:'left'}}>
                <FaLock color={MainColor} size={20} />Enter OTP from your email 
                </div>                           
                </div>


                <div style={{display:'flex',flexDirection:'row',width:'100%',height:50,marginTop:10,}}>



                <div>
                <Form.Control   
                onChange={e => setData({ ...data, otpCode:e.target.value})}
                value={data.otpCode}  
                maxLength={4}

                style={{width: 150,fontSize:15,color:'black',}} 
                
                />                               
                </div>

                <Button  style={{width:'30%',textAlign:'left',height:35,marginLeft:5}}>
                 <div    onClick={() => SendOTPCode(false)}  > 
                 <div style={{textAlign:'center',color:'white',fontSize:13,}}>Send OTP </div> 
                 </div>   
                </Button>

                </div>

             </div>

 

              <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:5}}>

       


              <div  onClick={accountPasswordUpdate} style={{width:'45%',marginLeft:'2.5%'}}>
              <div className="button">
              <div style={{textAlign:'center',color:'white',fontSize:16,}}> Update  </div>
              </div>
              </div>

              <Link  to='/' style={{width:'45%',marginLeft:'2.5%'}}>  
              <button type="submit"   className="button" style={{width: '100%', backgroundColor:newLocal}}> GOTO HOME</button>
              </Link>

              </div>



        
        </div>
        
        
        </div>
        </div>
  
        </div>

                           
        
        
        
        
        
        {///SINGLR BUTTON MODAL (PERSONAL MESSAGE) 1111
                                messageSwitch?
                         
                               
                                <Modal  show={true}     >
                                <div >
              
                                            <div   onClick={() => setMessageSwitch(false)}  >
                                            <IoCloseCircleOutline  size={30}  color="black" style={{margin:20}}/>
                                            </div>  
              
                                <div >
                                
                                <FaEnvelopeOpenText   size={20}  color={MainColor} /> 
                                      <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>  NOTIFICATION</div>
                                      
              
              
                                      <div   style={{marginTop: 20}} >
                                      
                                            <div  style={{fontSize: 14}} >{message}</div>
                                      </div>
                                      
                                      </div>
                                      </div>
                          
                                </Modal>
                                :  
                                null
              
                                }
        </div>
        )


    }
    
  }
export default ForgotPassword