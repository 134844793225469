
import React, { useState, useEffect,useLayoutEffect } from "react";
import { BrowserRouter as  Router, Route, Routes,  } from "react-router-dom";  
import Login            from '../Login/Login';
import Dashboard        from '../Dashboard/Dashboard';


import Logout            from "../Dashboard/pages/Logout"; 
import FundCreditCard    from "../Dashboard/pages/FundCreditCard"; 
import ForgotPassword    from "../Dashboard/pages/ForgotPassword";  
import AccountRecovery   from "../Login/AccountRecovery"; 
//import Cashout           from "../Dashboard/pages/Cashout"; 
import CashoutToAgent    from "../Dashboard/pages/CashoutToAgent"; 
import CashoutHistory    from "../Dashboard/pages/CashoutHistory"; 
import TransferHistory   from "../Dashboard/pages/TransferHistory"; 
import Transfer          from "../Dashboard/pages/Transfer"; 

import InstantLoan       from "../GetInstantLoan/index";
import VirtualCard       from "../Dashboard/pages/VirtualCard";
import PinChange         from "../Dashboard/pages/PinChange";
import Profile           from "../Dashboard/pages/Profile";
//import md5               from 'md5-hash';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";
import AccountSetup      from "../Dashboard/AccountSetup/index";

import PaymentLink       from "../PaymentLink/index"; 
import SellGoldenCoin    from "../SellGoldenCoin/index";
import BuyGoldenCoin     from "../BuyGoldenCoin/index";
import DataAirtimeTransaction     from "../DataAirtimeTransaction";
import FetchGoldenCoin   from "../FetchGoldenCoin/index";
import TradeAlert        from "../TradeAlert/index"; 
import PayWithCreditCard from "../maintenance_payment/index";
import ErrorPage         from "../ErrorPage";

import TradePolicy       from '../Signup/TradePolicy';
import Signup            from '../Signup/Signup';
import { MdTipsAndUpdates } from "react-icons/md";
import SideBar           from '../Sidebar/SideBar'; 
import { AuthContext }   from '../context'; 
import { AccountUpdate,BaseLoginUpdate,BasePageAction } from '../../BaseUrl';
import Modal           from "../UI/Modal/Modal";   

function Home() {
  const[loginData, setLoginData] = useState([]); 
  const[userToken, setUserToken]   = useState(''); 
  const[authUser, setAuthUser]   = useState(''); 
  const[notification, setNotification] = useState(false);
  const[message, setMessage] = useState('');
  const[show, setShow] = useState(false);
  const[activateLoader, setActivateLoader]   = useState(false); 
  const[isLoading2, setIsLoading2]   = useState(false);   
  const[activateDasboard, setActivateDasboard] = useState(false); 
  
  const authContext = React.useMemo(() => ({
  ////npm install react-bootstrap-table-next --save --legacy-peer-deps
  /// npm install react-bootstrap-table2-paginator --save --legacy-peer-deps
  //npm install react-bootstrap-table2-toolkit --save --legacy-peer-deps

  notMsg : () => {
    setActivateLoader(true)
  },
  
  signIn : () => {
    setShow(true);
     const authlog = localStorage.getItem('authLogin');
      setUserToken(authlog);
      window.location.reload();
      
      setShow(true); 
       // alert('WELCOME! Account Logged In Successfully');
     
  },

  
  signOut: () => {


   
    localStorage.removeItem('loginSession');
    localStorage.removeItem('authLogin');
    localStorage.removeItem('authUserToken');
    localStorage.removeItem('authUser');
 
    setUserToken('');
  
    setShow(false);
 
        
  

  },
  
  
   reloadUserData_2: () => {
    setActivateLoader(true);
    setIsLoading2(true);

    const authuserData =  localStorage.getItem('authUser');
    const authUserToken = localStorage.getItem('authUserToken');
    var headers = { 'Accept': 'application/json', 'Content-type': 'application/json' }

      if(authUserToken != null)
      {

      fetch(BasePageAction+"fingerPrintlogin&Token="+authUserToken,
      {
        method:'GET',
        header:headers
        })
        .then((response) => response.json())
        .then((response) => 
        {
        
  

   //OBJLOCATE 
                             
          var obj = {      
                        
            level_27          : response[0].level_27,
            user_coach        : response[0].user_coach,
            level_9           : response[0].level_9, 
            level_3           : response[0].level_3, 
            level3_row_paid   : response[0].level3_row_paid, 
            level_1           : response[0].level_1,
            photo             : response[0].photo,  
            username          : response[0].username, 
            password          : response[0].password,
            userkey           : response[0].userkey,
            fullname          : response[0].fullname,   
            email             : response[0].email,  
            gender            : response[0].gender,
            address           : response[0].address, 
            city              : response[0].city, 
            state             : response[0].state, 
            country           : response[0].country, 
            bank_name         : response[0].bank_name, 
            account_name      : response[0].account_name,   
            account_no        : response[0].account_no, 
            otp               : response[0].otp,   
            mem_fee           : response[0].mem_fee,   
            coin_selling_id   : response[0].coin_selling_id,     
            reg_date          : response[0].reg_date,
            coin_strength     : response[0].coin_strength,
            seamless_trade    : response[0].seamless_trade,
            private_msg       : response[0].private_msg,
            quest_status      : response[0].quest_status,
            game_status       : response[0].game_status,
            weekly_point      : response[0].weekly_point,
            cashout_status    : response[0].cashout_status,
            coin_trade_sender : response[0].coin_trade_sender,
            coin_selling_amt  : response[0].coin_selling_amt,
            card_main_status  : response[0].card_main_status,
            card_main_coint   : response[0].card_main_coint,
            card_main_kpi     : response[0].card_main_kpi,

            coin_buying_status : response[0].coin_buying_status,
            coin_selling_status: response[0].coin_selling_status,

            
            ///////////////// Wallet /////////////////////////// 

            card_pin         : response[0].card_pin,
            vcard_bal        : response[0].vcard_bal,
            card_img         : response[0].card_img,
            card_expires     : response[0].card_expires,
            card_digit       : response[0].card_digit, 
            game_won_bonus   : response[0].game_won_bonus, 
            user_bank        : response[0].user_bank, 
            card_lock        : response[0].card_lock,
            ///////////////// Coin ///////////////////////////
            
            user_coin            : response[0].user_coin, 
            golden_coin_earn     : response[0].golden_coin_earn, 
            
            ///////////////// Setting ///////////////////////////

            launch_target       : response[0].launch_target,
            launch_status       : response[0].launch_status,
            credit_card_amt     : response[0].credit_card_amt,
            card_main_fee       : response[0].card_main_fee,
            bimothly1_pay_date  : response[0].bimothly1_pay_date,  
            next_cashout_date   : response[0].next_cashout_date,  
            tot_reg             : response[0].tot_reg,
            golden_coin_value   : response[0].golden_coin_value,
            card_maint_date     : response[0].card_maint_date,
            public_msg          : response[0].public_msg, 
            loan_rate           : response[0].loan_rate,
            recharge_charges    : response[0].recharge_charges,
            social_task         : response[0].social_task,
            admin_pec           : response[0].admin_pec,
            user_pec            : response[0].user_pec,
            shares_min          : response[0].shares_min,
            RamadaAwoof         : response[0].RamadaAwoof
            ///////////////// Quest Training ////////////////////////////
            
                  
        };

 
 
                localStorage.setItem('loginSession',JSON.stringify(obj)); 
                setMessage(response[0].private_msg);


                setActivateLoader(true);
                setIsLoading2(false);

      })        
      .catch(error => {
        setIsLoading2(false);
        setMessage('Oops! network error, please check your data and try again');
      });


        
      }
   
  },
 
   reloadUserData: () => {
    
    setActivateLoader(true);
    setIsLoading2(true);
     
    const authUserToken = localStorage.getItem('authUserToken');
    var headers = { 'Accept': 'application/json', 'Content-type': 'application/json' }

      if(authUserToken != null)
      {

      fetch(BasePageAction+"fingerPrintlogin&Token="+authUserToken,
      {
        method:'GET',
        header:headers
        })
        .then((response) => response.json())
        .then((response) => 
        {
        
        
 
  //OBJLOCATE 
                             
          var obj = {      
                                    
            level_27          : response[0].level_27,
            user_coach        : response[0].user_coach,
            level_9           : response[0].level_9, 
            level_3           : response[0].level_3, 
            level3_row_paid   : response[0].level3_row_paid, 
            level_1           : response[0].level_1,
            photo             : response[0].photo,  
            username          : response[0].username, 
            password          : response[0].password,
            userkey           : response[0].userkey,
            fullname          : response[0].fullname,   
            email             : response[0].email,  
            gender            : response[0].gender,
            address           : response[0].address, 
            city              : response[0].city, 
            state             : response[0].state, 
            country           : response[0].country, 
            bank_name         : response[0].bank_name, 
            account_name      : response[0].account_name,   
            account_no        : response[0].account_no, 
            otp               : response[0].otp,   
            mem_fee           : response[0].mem_fee,   
            coin_selling_id   : response[0].coin_selling_id,     
            reg_date          : response[0].reg_date,
            coin_strength     : response[0].coin_strength,
            seamless_trade    : response[0].seamless_trade,
            private_msg       : response[0].private_msg,
            quest_status      : response[0].quest_status,
            game_status       : response[0].game_status,
            weekly_point      : response[0].weekly_point,
            cashout_status    : response[0].cashout_status,
            coin_trade_sender : response[0].coin_trade_sender,
            coin_selling_amt  : response[0].coin_selling_amt,
            card_main_status  : response[0].card_main_status,
            card_main_coint   : response[0].card_main_coint,
            card_main_kpi     : response[0].card_main_kpi,

            coin_buying_status : response[0].coin_buying_status,
            coin_selling_status: response[0].coin_selling_status,

            
            ///////////////// Wallet /////////////////////////// 

            card_pin         : response[0].card_pin,
            vcard_bal        : response[0].vcard_bal,
            card_img         : response[0].card_img,
            card_expires     : response[0].card_expires,
            card_digit       : response[0].card_digit, 
            game_won_bonus   : response[0].game_won_bonus, 
            user_bank        : response[0].user_bank, 
            card_lock        : response[0].card_lock,
            ///////////////// Coin ///////////////////////////
            
            user_coin            : response[0].user_coin, 
            golden_coin_earn     : response[0].golden_coin_earn, 
            
            ///////////////// Setting ///////////////////////////

            launch_target       : response[0].launch_target,
            launch_status       : response[0].launch_status,
            credit_card_amt     : response[0].credit_card_amt,
            card_main_fee       : response[0].card_main_fee,
            bimothly1_pay_date  : response[0].bimothly1_pay_date,  
            next_cashout_date   : response[0].next_cashout_date,  
            tot_reg             : response[0].tot_reg,
            golden_coin_value   : response[0].golden_coin_value,
            card_maint_date     : response[0].card_maint_date,
            public_msg          : response[0].public_msg, 
            loan_rate           : response[0].loan_rate,
            recharge_charges    : response[0].recharge_charges,
            social_task         : response[0].social_task,
            admin_pec           : response[0].admin_pec,
            user_pec            : response[0].user_pec,
            shares_min          : response[0].shares_min,
            RamadaAwoof         : response[0].RamadaAwoof
            
        };
                      
    
 
               localStorage.setItem('loginSession',JSON.stringify(obj));
                setMessage(response[0].private_msg);

 

                setActivateLoader(true);
                setIsLoading2(false);
      })        
      .catch(error => {
        //setMessage('Oops! network error, please check your data and try again');
      });


        
      }
   
  }
 

  }));


 
 
 

 useLayoutEffect(() => {
     
    const authlog = localStorage.getItem('authLogin');
    setUserToken(authlog);

    
    if(userToken == '1'){
      setShow(true)

    }else{

    
      setShow(false);
    }


    const userData =  localStorage.getItem('authUser');
    setAuthUser(userData);

 
   

//    return () => {
//     loginValue.cancel()
// }
 
});
 useLayoutEffect(() => {
     
  LoginOptionUpdate()
 
},[]); 


const LoginOptionUpdate =() => 
{
  const value =  localStorage.getItem('loginSession');
  const data = JSON.parse(value);  
  setLoginData(data);
}   
 


const autoUserDataReload = () =>  {
     
      
      
    const authuserData =  localStorage.getItem('authUser');
    const authUserToken = localStorage.getItem('authUserToken'); 
    var headers = { 'Accept': 'application/json', 'Content-type': 'application/json' }
  if(authUserToken != null){



    fetch(BasePageAction+"fingerPrintlogin&Token="+authUserToken,
    {
    method:'GET',
    header:headers,
  
    })
    .then((response) => response.json())
    .then((response) => {
   
  //OBJLOCATE
      
          if(response[0].feedback	== "Invalid Account")     {
            setLoginData(null);
            localStorage.removeItem('loginSession');
            localStorage.removeItem('authLogin');
            localStorage.removeItem('authUserToken');
            localStorage.removeItem('authUser');
            setUserToken('');
          }
          else
          {              
                  var obj = {      
                            
                    level_27          : response[0].level_27,
                    user_coach        : response[0].user_coach,
                    level_9           : response[0].level_9, 
                    level_3           : response[0].level_3, 
                    level3_row_paid   : response[0].level3_row_paid, 
                    level_1           : response[0].level_1,
                    photo             : response[0].photo,  
                    username          : response[0].username, 
                    password          : response[0].password,
                    userkey           : response[0].userkey,
                    fullname          : response[0].fullname,   
                    email             : response[0].email,  
                    gender            : response[0].gender,
                    address           : response[0].address, 
                    city              : response[0].city, 
                    state             : response[0].state, 
                    country           : response[0].country, 
                    bank_name         : response[0].bank_name, 
                    account_name      : response[0].account_name,   
                    account_no        : response[0].account_no, 
                    otp               : response[0].otp,   
                    mem_fee           : response[0].mem_fee,   
                    coin_selling_id   : response[0].coin_selling_id,     
                    reg_date          : response[0].reg_date,
                    coin_strength     : response[0].coin_strength,
                    seamless_trade    : response[0].seamless_trade,
                    private_msg       : response[0].private_msg,
                    quest_status      : response[0].quest_status,
                    game_status       : response[0].game_status,
                    weekly_point      : response[0].weekly_point,
                    cashout_status    : response[0].cashout_status,
                    coin_trade_sender : response[0].coin_trade_sender,
                    coin_selling_amt  : response[0].coin_selling_amt,
                    card_main_status  : response[0].card_main_status,
                    card_main_coint   : response[0].card_main_coint,
                    card_main_kpi     : response[0].card_main_kpi,

                    coin_buying_status : response[0].coin_buying_status,
                    coin_selling_status: response[0].coin_selling_status,
                
                    
                    ///////////////// Wallet /////////////////////////// 

                    card_pin         : response[0].card_pin,
                    vcard_bal        : response[0].vcard_bal,
                    card_img         : response[0].card_img,
                    card_expires     : response[0].card_expires,
                    card_digit       : response[0].card_digit, 
                    game_won_bonus   : response[0].game_won_bonus, 
                    user_bank        : response[0].user_bank, 
                    card_lock        : response[0].card_lock,
                    ///////////////// Coin ///////////////////////////
                    
                    user_coin            : response[0].user_coin, 
                    golden_coin_earn     : response[0].golden_coin_earn, 
                    
                    ///////////////// Setting ///////////////////////////

                    launch_target       : response[0].launch_target,
                    launch_status       : response[0].launch_status,
                    credit_card_amt     : response[0].credit_card_amt,
                    card_main_fee       : response[0].card_main_fee,
                    bimothly1_pay_date  : response[0].bimothly1_pay_date,  
                    next_cashout_date   : response[0].next_cashout_date,  
                    tot_reg             : response[0].tot_reg,
                    golden_coin_value   : response[0].golden_coin_value,
                    card_maint_date     : response[0].card_maint_date,
                    public_msg          : response[0].public_msg, 
                    loan_rate           : response[0].loan_rate,
                    recharge_charges    : response[0].recharge_charges,
                    social_task         : response[0].social_task,
                    admin_pec           : response[0].admin_pec,
                    user_pec            : response[0].user_pec,
                    shares_min          : response[0].shares_min,
                    RamadaAwoof         : response[0].RamadaAwoof

                    
                };


                localStorage.setItem('loginSession',JSON.stringify(obj)); 
           }
              
      }) 
      .catch((error) => {
                                       
                      
          
      })
  

    }



}
  
 
  useEffect(() => {

 
  setTimeout(async() => {
  
    autoUserDataReload();  
    LoginOptionUpdate();  
    

}, 10000);
 

   
  
  },[loginData]);

  

  useLayoutEffect(() => {
  
   if(loginData == null)
   {

    localStorage.removeItem('loginSession');
    localStorage.removeItem('authLogin');
    localStorage.removeItem('authUserToken');
    localStorage.removeItem('authUser');
    setShow(false)

   }
   else
   {
        if(loginData.game_status == 'in-game' || loginData.game_status == 'signup'){
         
          setActivateDasboard(false)
        } else  if(loginData.game_status == 'no-game'){
      
         
          setActivateDasboard(true)
        }

   }

    
  });


  const  showHandler =() =>{

    setMessage('');
    setActivateLoader(false);  
    setIsLoading2(false);
  
 
}   

 
 //  var authUserToken =  localStorage.getItem('authUserToken');

if(activateLoader)
{

          if(isLoading2)
          {


            return (     
          
                  <div> 
                    <Loading1  Show={showHandler}   /> 
                  </div>
                );


          }
          else
          {
              return (         
                  <div> 
                      <Loading2   Show={showHandler} >
                          <small style={{color: 'black',fontSize:13}}>{message} </small>
                      </Loading2> 
                  </div>
              );
          }


    
 
  }
else
{
  
///LOGIN SECTION
      if( show == false){

            return ( 
            <AuthContext.Provider value={authContext}>
            <Router>
                <Routes>
                    <Route path=''                  element={<Login                 />} />     
                    <Route path='/'                 element={<Login                 />} />     
                    <Route path='/Home'             element={<Login                 />} />     
                    <Route path='/Login'            element={<Login                 />} />     
                    <Route path='/account-recovery' element={<AccountRecovery       />} />     
                    <Route path='/account-setup'    element={<Signup                />} /> 
                    <Route path='/Forgot-Password'  element={<ForgotPassword        />} />  
                    <Route path='/Trade-Policy'     element={<TradePolicy           />} />   
                    <Route path='/payment-link'     element={<PaymentLink           />} />   
                    <Route path='*'                 element={<ErrorPage              /> } />   
                </Routes>

                </Router>
            </AuthContext.Provider>

            );

      }
      else 
      {
          

        if(loginData.game_status == 'signup')
        {

              return (
              <AuthContext.Provider value={authContext}>
              <Router>
              <Routes> 
              
              <Route path='/'                    element={<AccountSetup        />} />  
              <Route path='/payment-link'        element={<PaymentLink        />} />
              <Route path='*'                    element={<ErrorPage              /> } /> 
              </Routes>               
              </Router>

              </AuthContext.Provider> 
              ); 

        }
        else
        {

              if(loginData.card_pin == '')
              {
      
                    return (
                    <AuthContext.Provider value={authContext}>
                    <Router>
                      <Routes>  
                        <Route path='/'    element={<PinChange        />} />  
                        <Route path='*'    element={<ErrorPage              /> } />   
                      </Routes>               
                    </Router>
      
                    </AuthContext.Provider> 
                    ); 
      
              }
              else
              {


                if(loginData.weekly_point == '1')
                {

                  return (
                    <AuthContext.Provider value={authContext}>
                    <Router>
                            <div> 
                              <TradeAlert  /> 
                          </div>               
                    </Router>

                    </AuthContext.Provider> 
                    ); 
                  
                }
                else 
                {      
                
                  

                        if(loginData.card_main_status == 'passive')
                        {

                                  return (
                                  <AuthContext.Provider value={authContext}>
                                  <Router>
                                    <Routes>  
                                      <Route path='/'                          element={<PayWithCreditCard    CheckCard={loginData.card_digit}    />} />  
                                      <Route path='/payment-link'              element={<PaymentLink      CardMainFee={loginData.card_main_fee}   />} />  
                                      <Route path='*'                          element={<ErrorPage              /> } />   
                                    </Routes>               
                                  </Router>

                                  </AuthContext.Provider> 
                                  ); 
                              
                        }
                        else 
                        {      


             
                                

                                    if(activateDasboard)
                                    {
                                          return ( 
                                          <AuthContext.Provider value={authContext}>
                                              <Router>
                                                <SideBar>                                                  
                                                      <Routes>
                                                            <Route path='/'                          element={<Dashboard        />} />     
                                                            <Route path='/Home'                      element={<Dashboard        />} />     
                                                            <Route path='/Dashboard'                 element={<Dashboard        />} />                              
                                                            <Route path='/cashout-to-agent'          element={<CashoutToAgent   />} />                             
                                                            <Route path='/money-transfer'            element={<Transfer         />} />                             
                                                            <Route path='/buy-airtime-data'          element={<DataAirtimeTransaction   />} />                             
                                                            
                                                             
                                                            
                                                            <Route path='/buy-shares'                element={<BuyGoldenCoin     />} /> 
                                                            <Route path='/sell-shares'               element={<SellGoldenCoin    />} /> 
                                                            <Route path='/my-shares'                 element={<FetchGoldenCoin   />} /> 
                                                            <Route path='/cashout-history'           element={<CashoutHistory    />} /> 
                                                            <Route path='transaction-history'        element={<TransferHistory   />} /> 
                                                            <Route path='/virtual-credit-card'       element={<VirtualCard       />} />  
                                                            <Route path='/InstantLoan'               element={<InstantLoan       />} />
                                                            <Route path='/fund-credit-card'          element={<FundCreditCard    />} />      
                                                            <Route path='/profile'                   element={<Profile           />} />     
                                                            <Route path='/Logout'                    element={<Logout            />} />  
                                                            <Route path='/payment-link'              element={<PaymentLink       />} />  
                                                              
                                                            <Route path='*'                          element={<ErrorPage         /> } />   
                                                      </Routes> 
                                                </SideBar>
                                              </Router>
                                            </AuthContext.Provider>

                                          );           
                                    }
                                    else
                                    {
                                          return (     
                              
                                            <div> 
                                              <Loading1     /> 
                                            </div>
                                          );
                                    }
                                

                              


                        }



                }

              }
    
        }

      }
 


}



}
export default Home;
//https://reactrouter.com/docs/en/v6/components/navigate
/*

APP OPERATION
___________________
-AccountSetup
-PaymentLink



/////////// These are the domains connected to payment links  card_maint / cardTopUp //////
     PayWithCreditCard  
     AccountSetup
     FundCreditCard

     https://www.npmjs.com/package/react-adsense
     
////////////////////////////////////////////////////////////////////////////////



          
*/

 
