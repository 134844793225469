import React, { useState, memo,useEffect,useLayoutEffect } from "react";
import  { Navigate ,NavLink,Link,useNavigate } from 'react-router-dom' 
import { AiFillStar,AiFillBell,AiFillCloseCircle } from "react-icons/ai";  
import { BsCheck2Circle,BsFillCheckCircleFill  } from "react-icons/bs";   
import { FaRegTrashAlt } from "react-icons/fa";       
import {Naira, BaseAction, MainColor,  } from '../../BaseUrl';
import {Card, Modal,Form} from 'react-bootstrap';
import { AuthContext } from '../../components/context';
import Loading1          from "../Loading";
import Loading2          from "../Loading2";
import md5            from 'md5-hash'
import './FetchGoldenCoin.css'; 
// import '../../App.css'; 

const FetchGoldenCoin = (props) => {
    const { reloadUserData_2 ,signOut, reloadUserData} = React.useContext(AuthContext);
    const [activateLoader, setActivateLoader] = useState(false); 
    const [isLoading2, setIsLoading2] = useState(false); 
    const [messageSwitch, setMessageSwitch] = useState(true);
    const [trashGoldenCoin, setTrashGoldenCoin] = useState('');
    const [messageSwitch2, setMessageSwitch2] = useState(false);
    const [message, setMessage] = useState(''); 
    const [goldenCoinData, setGoldenCoinData] = useState([]); 
    const [loginData, setLoginData] = useState([]); 
    const[tradeCoinModal, setTradeCoinModal] = useState(false); 
    const[filterGetAllCountry, setFilterGetAllCountry] = useState([]);
    const[coinModalDetails, setCoinModalDetails] = useState(false); 
    const[data, setData] = useState({
      message: '',
      password: '',
      coinUnit: '',
      coinValue: '',  
      coinAmt: '',  
      receiverCode: '',  
      receiverName: '',  
      check_textInputChange: false,
      secureTextEntry: true,
      coinID :'',
      goldenCoinNo :'',
   })
  
   const onSearchLoan = (text) => {

    
      if (text == '') {
        
          setFilterGetAllCountry('');
    
      } 
      else
      {
    
        let tempList = goldenCoinData.filter(item => {
          return item.coin_no.toLowerCase().indexOf(text.toLowerCase()) > -1;
        });
        setFilterGetAllCountry(tempList);
  
        setCoinModalDetails(true)
      }
    
    
    
    }

    const navigate = useNavigate();    

    const LoginOptionUpdate =() => 
    {
    
    const value =  localStorage.getItem('loginSession');
    const data = JSON.parse(value);  
    setLoginData(data);
      
    }
    
    useEffect(() => {
          
        setTimeout(async() => {
        
            LoginOptionUpdate();  
        
        }, 5000);
                        
    });
    
    useLayoutEffect(() => {
    
          LoginOptionUpdate();
          
    },[]); 
  
  
    useEffect(() => {
    
        if(loginData.public_msg == ''){
              setMessageSwitch(false)
        }
          
          
    },[]); 
  
   
    const NumberFomat_2 = (val) =>{

     
      var value =  val * 1; 
      var result =  value.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return  result;  
   
  }
      
  
  useEffect(() => {
    let runNow = true
   
 
    setActivateLoader(true);  
    setIsLoading2(true);  
  
  
  
            var headers = {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                    const authUserToken =  localStorage.getItem('authUserToken');
                     
           fetch(BaseAction+"FetchGoldenCoinData&token="+authUserToken,
           {
           method:'GET',
           header:headers,
   
           })
           .then((res) => res.json())
           .then((response) => {
 
  
                 if(runNow){


                    if(response[0].success == "0" )
                    {
                                                
                         setMessage(response[0].feedback);
                          setActivateLoader(false);    
                          setIsLoading2(false);   
                         // reloadUserData() 
                    }
                    else 
                    {
                      
                      
                      setGoldenCoinData(response); 
                      setMessage('Shares(SH) loaded');
                      setActivateLoader(false);  
                      setIsLoading2(false);  

                         
                    }
                 }
               
                })  
                .catch(error => {
                
                    setActivateLoader(true);  
                    setIsLoading2(false); 
                });
        
  
      
 
    
  
  
  
  
  
   return () => {
    runNow = false;
  }; 
  
      
  },[]);
  
  const DeleteAask = (val1, val2) => {

    setMessageSwitch2(true)
    setTrashGoldenCoin(val1)

          setMessage('Note you won\'t be able to retrieve this Shares(SH) if deleted. Are you sure you still want to delete Shares(SH) no [RSBSH'+val2+'] ?')
          setMessageSwitch2(true);
    
}



  //FETCH GAME DATA HISTORY
  const DeleteDeadGoldenCoin = () => {

    setMessageSwitch2(false)
    setActivateLoader(true);  
    setIsLoading2(true);  
  

    var newUse = Number(trashGoldenCoin) + Number(191);
    var delGC = newUse.toString();
    var delGoldenCoin = md5(delGC);

          var headers = {
          'Accept': 'application/json',
          'Content-type': 'application/json'
          }
          var authUserToken =  localStorage.getItem('authUserToken');
          fetch(BaseAction+"DeleteDeadGoldenCoin&token="+authUserToken+"&deleteCoin="+delGoldenCoin,
          {
          method:'GET',
          header:headers, 
          })
          .then((response) => response.json())
          .then((response) => { 
              

            setMessage(response[0].feedback);
            setActivateLoader(false);    
            setIsLoading2(false); 
            setMessageSwitch(true)
          }) 
          .catch(error => {
                setActivateLoader(true);  
                setIsLoading2(false);    
          });
   
            


    
}

const coinTradeRequesToReceiver = () =>{
   
      setTradeCoinModal(false);  
      setActivateLoader(true);  
      setIsLoading2(true); 
    
      if(data.receiverCode == loginData.level_1)
      {
            setActivateLoader(false);  
            setIsLoading2(false); 
    
            setMessage('You have entered your trading code, Please enter receiver trading code')
            setMessageSwitch(true);
    
      }
      else
      {
    
         
    
            var headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json'
            }
            var authUserToken =  localStorage.getItem('authUserToken');
            fetch(BaseAction+"CoinTradeRequesToReceiver2&token="+authUserToken+"&receiverCode="+data.receiverCode+"&coinID="+data.coinID,
            {
            method:'GET',
            header:headers, 
            })
            .then((response) => response.json())
            .then((response) => { 
                  
    
                  setData({ 
                        ...data,  
                        coinUnit: '', 
                        coinValue: '',  
                        coinAmt: '',  
      
                        })  
                          
                        //reloadUserData_2();
                        setMessage(response[0].feedback);   
                        setActivateLoader(false);  
                        setIsLoading2(false); 
                        setTradeCoinModal(false) 
                        setMessageSwitch(true);
                  
                  
            }) 
            .catch(error => {
                  setActivateLoader(true);  
                  setIsLoading2(false);    
            });
      }
    
    
    }
    

    const NumberFomat = (val) =>{
  
       
      var value =  val* 1; 
      var result =  value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return   Naira+result;  
                                           
  
  
  }
 
 
   
     const sellGCShowHandler =(a,b)=>{

      setData({
        ...data,  

        coinID:b,
        goldenCoinNo:a,
      })


      setTradeCoinModal(true)
        
     
     }
  



     const showHandler =()=>{
        setMessage('');
        setActivateLoader(false);
        setIsLoading2(false);
        
     
     }
  
   
   
  
     if(activateLoader){
  
      if(isLoading2)
      {
  
  
        return (     
      
              <div> 
                <Loading1  Show={showHandler}   /> 
              </div>
            );
  
  
      }
      else
      {
          return (         
              <div> 
                  <Loading2   Show={showHandler} >
                      <small style={{color: 'black',fontSize:13}}>{loginData.private_msg} </small>
                  </Loading2> 
              </div>
          );
      }
  
  
  
  
}
else
{ 

   
  
    return (
  
          <div  style={{textAlign:"left",padding: 20,height:'100vh'}} >
  
   
           
  
                  <div  className="taskContainer ">
  
                    <h5 style={{marginBottom: '14px'}}>   <br />  </h5>
  
                  
                      
                            <div   >
                              
                                <Card.Title  style={{marginBottom: 20,textAlign: 'start',textDecoration: 'underline'}}>
                                
                                <div    > MY SHARES(SH)     </div>
                                </Card.Title> 
                                
  
                                  <div  >
                                      
                                      
                                  {
                                    goldenCoinData.map((item) => (
                                        <div    className="borderBottom "  style={{textAlign:'center',backgroundColor:'#fff', paddingBottom:5,borderBottomWidth:2,borderColor: MainColor,}}>
                        


                                        

                                        <div style={{flexDirection:'row',width: '100%',alignItems:'center',}}>
                                        
                                        
                                        
                                        
                                        <div  style={{borderColor: MainColor,paddingRight:20}}>
                            
                                              <div    style={{flexDirection:'column',width: '100%'}}>

                                              <div style={{flexDirection:'column',alignItems:'center'}}> 
                                              <img    style={{width: 50, height: 50,alignItems:'center'}}   src='images/1gcoin.png' />  
                                              <div style={{color:'black',fontSize:14, alignItems:'center'}}>Shares(SH) </div>
                                              </div>  

                                              <div style={{color:'black',fontSize:14, alignItems:'center'}}>SH No:
                                                  <span style={{color:MainColor, fontSize:14, }}>{item.coin_ref}  </span> 
                                              </div>  
                            
                                              <div style={{color:'black',fontSize:14, alignItems:'center'}}>Purchased date:
                                                 <span style={{color:MainColor, fontSize:14, }}> [ {item.mining_date} ] </span>
                                              </div>   

                                              </div>                            
                                        </div>
                            
                            
                                        </div> 
                            
                                           <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}>                                              
                                                <div className="button" style={{ height: 30,fontSize:14,marginBottom:5}}  onClick={()=> sellGCShowHandler(item.coin_ref,item.coin_id)} >
                                                Sell SH
                                                </div>
                        
                                                <div className="button text" style={{fontSize:14,backgroundColor:'green',marginLeft:2,height: 30,paddingTop:-15}}  onClick={()=> onSearchLoan(item.coin_no)}   > 
                                                View SH Details
                                                </div>
                                          </div>

                                     </div> 
  
                                      ))
                                    }                
                                    
                                  </div>
                                
                              
                              <br />
                            </div>
                             
  
                  </div>
  
                       {///  (MESSAGE NOTIFICATIONS)
                        messageSwitch?
                  
                        
                        <Modal  show={true}    > 
                        <div style={{flex:1,backgroundColor: MainColor}} onClick={() => setMessageSwitch(false)}   >
                        <div>
                  
                              <AiFillCloseCircle    size={25}  color={'white'} />                         
            
                              <div   style={{marginTop: 20,padding:20}} >
                               
                                    <div  style={{fontSize: 14,color:'white'}} >{message}</div>
                              </div>

                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null

                        }


                      {///SINGLR BUTTON MODAL (PERSONAL MESSAGE)
                        messageSwitch2?


                        <Modal  show={true}  >
                        <div  onClick={() => setMessageSwitch2(false)}   >
    
            
  
            
                                <div  >
  
                        
                        <AiFillBell  size={30}  color={MainColor} /> 
                              <div style={{fontWeight:'bold',fontSize:14,color:MainColor}}>SHARES(SH) DELETE ?</div>  
                        
                              
            
            
                              <div   style={{marginTop: 20}} >
                              <div style={{flexDirection:'column',alignItems:'center'}}> 
                              <img    style={{width: 100, height: 100,alignItems:'center'}}   src='images/1gcoin.png' />  
                              <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>1 Shares(SH) </div>
                              </div>  


                                    <div  style={{fontSize: 14,color:'red',}} >{message}</div>
                              </div>
  
  
                              <div   style={{paddingBottom: 20,marginTop: 20,flexDirection:'row',display:'flex',justifyContent:'space-around'}} >
                              
                              <div  class="buttonBusy" style={{padding:10,fontSize:14,width:'40%',backgroundColor:'green',}}  onClick={() => setMessageSwitch2(false)}  >
                              <div style={{color:'white'}}>Cancel</div>
                              </div>  
  
  
                              <div  class="buttonBusy"  style={{padding:10,fontSize:14, backgroundColor:'red', width:'40%',}} onClick={() => DeleteDeadGoldenCoin()}  > 
                              <div style={{color:'white'}}>Delete </div>
                              </div>  
  
  
                              </div>
                              
                              </div>
                        </div>
                  
                        </Modal>
                        :  
                        null
            
                        } 





                        
                        {/// SHARES TRADING MODAL 252525
                              tradeCoinModal?


                              <Modal show={true}>
                              
                            
      
                              <div onClick={()=> setTradeCoinModal(false)}> 
                              <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> 
                              </div>
      
                              <div style={{color:'black'}}>
      
      
      
                                    <div>
                                    <div style={{alignItems:'center' }}>
                                    <img src='images/gcoin_img.png'  style={{width: 80, height: 80, }} />
                                    </div>
                                    
      
                              
                                    <div style={{textAlign:'center',fontSize:20,fontWeight:'bold'}}>SHARES(SH) TRADING</div> 
      
                                    <div style={{fontSize:15,color:MainColor,textAlign:'center'}}>{NumberFomat_2(1)}SH  = 
                                    <span style={{fontSize:15,color:MainColor,textAlign:'center'}}> {NumberFomat(1 *loginData.golden_coin_value)} </span>
                                    </div>
                  
                                    
                                    <div style={{marginTop:16,textAlign:'left',paddingLeft:15,paddingRight:15}}>
    
                                    <div> 
                                     
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <div className="text">Sell Shares(SH)</div>
                                      <Form.Control   value={data.goldenCoinNo}      readOnly/>
                                    </Form.Group>
                                    
                                          

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <div className="text">Shares(SH) Value</div>
                                      <Form.Control   value={NumberFomat(1 *loginData.golden_coin_value)}      readOnly/>
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <div className="text">Shares(SH) Receiver Trading Code</div>
                                      <Form.Control 
                                      type='number'  
                                      onChange={e=> setData({ ...data,  receiverCode:e.target.value})}
                                      />
                                    </Form.Group>

                                    
                                    </div>
                                   
                         
                  
                                    <div  onClick={() => coinTradeRequesToReceiver()} >
                                    <div className="button">
                                    <div style={{textAlign:'center',color:'white',fontSize:16,}}> Send Trade Request to Receiver</div>
                                    </div>
                                    </div><br/>
       
                                    
                                    </div>
                                    </div>
                              </div>
                  
                              
                              </Modal>
                              :  
                              null
                  
                              }

                  {/// SHARES TRADING MODAL 252525
                  coinModalDetails?


                  <Modal show={true}>



                  <div onClick={()=> setCoinModalDetails(false)}> 
                  <AiFillCloseCircle size={25}  style={{color: MainColor, }}   /> 
                  </div>


                  {
                        filterGetAllCountry.map((item) => (
                            <div    className="borderBottom "  style={{textAlign:'center',backgroundColor:'#fff',paddingRight:10,paddingBottom:5,borderBottomWidth:2,borderColor: MainColor,}}>
            


                            

                            <div style={{flexDirection:'row',width: '100%',alignItems:'center',}}>
                            
                            
                            
                            
                            <div  style={{borderColor: MainColor,paddingRight:20}}>
                
                                  <div    style={{flexDirection:'column',width: '100%'}}>
                
                                  <div style={{alignItems:'center'}}>
                                        <div style={{color:'black',fontSize:14,fontWeight:'bold',textAlign:'center'}}>Shares(SH) worth </div>
                                        <div style={{color:MainColor, fontSize:18,fontWeight:'bold',}}> [ {NumberFomat(loginData.golden_coin_value)} ] </div> 
                                  </div>
                                  
                
                                  <div style={{flexDirection:'column',alignItems:'center'}}> 
                                  <img    style={{width: 100, height: 100,alignItems:'center'}}   src='images/1gcoin.png' />  
                                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>1 Shares(SH) </div>
                                  </div>  
                
                
                
                
                                  
                                        
                
                                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) no
                                  <div style={{color:MainColor, fontSize:14,fontWeight:'bold',}}> [ {item.coin_ref} ] </div> 
                                  </div>  
                
                                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) purchased date
                                     <div style={{color:MainColor, fontSize:14,fontWeight:'bold',}}> [ {item.mining_date} ] </div>
                                  </div>  
                
                
                                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) status 
                                        <div style={{color:MainColor, fontSize:14,fontWeight:'bold',paddingLeft:30}}>
                                              {
                                              item.coin_status == 'good'? 
                                                    <div style={{color:'green'}}>
                                                      [ Active ]   
                                                      <BsCheck2Circle color={'green'}    size={20}       />
                                                    </div> 
                                              : item.coin_status == 'bad'?
                                                    <div  onClick={() => DeleteAask(item.coin_id,item.coin_ref)}>
                                                    <div style={{color:'red'}}>
                                                    [ Inactive ]   
                                                    <FaRegTrashAlt color={'red'}    size={20}       /> Trash Shares 
                                                    </div>
                                                    </div>
                                              : item.coin_status == 'scam'?
                                                    <div  onClick={() => DeleteAask(item.coin_id,item.coin_ref)}>
                                                    <div style={{color:'red'}}>
                                                    [ Bad ]   
                                                    <FaRegTrashAlt color={'red'}    size={20}       /> Trash Shares 
                                                    </div>
                                                    </div>
                                              :null
                                              }
                                        </div> 
                                  </div>  
                
                                  <div style={{color:'black',fontSize:14,fontWeight:'bold',alignItems:'center'}}>Shares(SH) strength 
                                        <div style={{color:MainColor,fontSize:14,fontWeight:'bold',}}>
                                              {
                                              item.coin_status == 'good'?
                                                    <div>
                                                    <AiFillStar color={'gold'}    size={15}       /><AiFillStar  color={'gold'}   size={20}        /> <AiFillStar  color={'gold'}   size={20}        /> 
                                                    </div>
                                              : item.coin_status == 'bad'?
                                                    <div>
                                                    <AiFillStar color={'gold'}    size={15}       /> <AiFillStar  color={'gold'}   size={20}        /> 
                                                    </div>
                                              : item.coin_status == 'bad'?
                                                    <div>
                                                    <AiFillStar color={'gold'}    size={15}      /> 
                                                    </div>
                                              :null
                                              }
                                        </div> 
                                  </div>  
                                  
                
                                  </div>                            
                            </div>
                
                
                            </div>
                
                            <div style={{ width: '100%',alignItems:'center',marginTop:10}}> 
                                  {item.mining_status == 'good'?
                                  <div style={{color:'green',fontSize:14,fontWeight:'bold',textAlin:'center', }}>
                                  COLDEN COIN SIGNED <BsCheck2Circle size={25}  style={{color: 'green',textAlin: 'center', }}/> Approved 
                                  </div>
                                  :
                                  <div style={{color:'red',fontSize:14,fontWeight:'bold',textAlin:'center', }}>
                                  COLDEN COIN SIGNED  <BsFillCheckCircleFill size={25}  style={{color: 'red',textAlin: 'center', }}      />  In-Progress
                                  </div>
                                  }
                
                            </div>
                   
                         </div> 

                          ))
                        } 


                  </Modal>
                  :  
                  null

                  }

            </div>
      )
  
 }
     
  };
  


 
export default memo(FetchGoldenCoin);  





          